import { useMemo, useRef, useState } from "react";

import {
  UploadResult,
  getStorage,
  ref as storageRef,
  uploadString
} from "firebase/storage";

import { Formik, FormikProps } from "formik";

import "react-image-crop/dist/ReactCrop.css";
import FormikEffect from "../helpers/FormikEffect";

import { Facebook, Instagram, Language, Person } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { isEqual, omit, pickBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { ArtistDocument } from "shared/models/artist-models";
import { CreateProfileData } from "shared/models/store-models";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import AssetsResolver from "../../AssetsResolver";
import Config from "../../config";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { withZodSchema } from "../../helpers/ZodFormikAdapter";
import { TGGArtistUser } from "../../redux/slices/authSlice";
import { dialog } from "../../zustand/imperative-dialog";
import FormikField from "../formik/FormikField";
import FormikImageField, {
  CropProp,
  ImageValueSchema,
  superRefineImageValue
} from "../formik/FormikImageField";
import FormikScrollToError from "../helpers/FormikScrollToError";
import LoadingButton from "../helpers/LoadingButton";
import TikTok from "../icons/TikTok";
import StripeConnectSetup from "./StripeConnectSetup";
import ManageStoreScaffold from "./MyStoreScaffold";

type CreateOrUpdateProfileProps =
  | {
      artistUser: TGGArtistUser;
      creating: false;
      artistDoc: ArtistDocument;
    }
  | {
      artistUser: TGGArtistUser;
      creating: true;
      artistDoc: undefined;
    };

// constraints for cropping images
const profilePictureCrop: CropProp = { circular: false, aspect: 1 / 1 };
const coverImageCrop: CropProp = {
  circular: false,
  aspect: 4 / 1
  // minWidth is broken,
  // see https://github.com/DominicTobias/react-image-crop/issues/510
  // minWidth: 1600
};

export default function CreateOrUpdateProfile({
  artistUser,
  creating,
  artistDoc
}: CreateOrUpdateProfileProps) {
  const formRef = useRef<FormikProps<FormValues>>(null);

  const user = artistUser;

  const navigate = useNavigate();

  const [uploadState, setUploadState] = useState<
    "uploading" | "processing" | undefined
  >(undefined);

  async function update(values: FormValues) {
    const storage = getStorage();

    let coverImageUploadId: string | undefined = undefined;
    let profilePictureUploadId: string | undefined = undefined;

    const uploadPromises: Promise<UploadResult>[] = [];

    if (values.profilePicture?.type === "new") {
      setUploadState("uploading");
      profilePictureUploadId = uuidv4();

      // TODO: find a way to resize that doesn't lose a lot of quality
      const resizedProfilePicture = values.profilePicture.cropDataUrl!; //resizeImage.resize(values.profilePicture.cropImageObject, 300, 300, "png")

      const profilePicRef = storageRef(
        storage,
        `/uploads/${user.uid}/${profilePictureUploadId}`
      );

      uploadPromises.push(
        uploadString(profilePicRef, resizedProfilePicture, "data_url")
      );
    } else {
      console.log("Skipping upload of profile picture, no change");
    }

    if (values.coverImage?.type === "new") {
      setUploadState("uploading");
      coverImageUploadId = uuidv4();

      const coverImageRef = storageRef(
        storage,
        `/uploads/${user.uid}/${coverImageUploadId}`
      );
      uploadPromises.push(
        uploadString(coverImageRef, values.coverImage.cropDataUrl!, "data_url")
      );
    } else {
      console.log("Skipping upload of cover image, no change");
    }

    await Promise.all(uploadPromises);
    setUploadState("processing");

    if (creating) {
      if (!profilePictureUploadId) {
        // due to the super refine requiring this field,
        // this should not happen
        // TODO: surface error better
        alert("Profile picture upload must be present");
        return;
      }

      await callCloudFunction("createOrUpdateProfile", {
        ...omit(values, "profilePicture", "coverImage"),
        updating: false,
        profilePictureUploadId: profilePictureUploadId,
        coverImageUploadId: coverImageUploadId
      });
    } else {
      // update allows for partial information

      const updatedValues = pickBy(
        values,
        (value, key) => !isEqual(initialValues[key as keyof FormValues], value)
      ) as Partial<FormValues>;

      if (Object.keys(updatedValues).length > 0) {
        await callCloudFunction("createOrUpdateProfile", {
          ...omit(updatedValues, "profilePicture", "coverImage"),
          instagram:
            values.instagram === undefined &&
            initialValues.instagram !== undefined
              ? null
              : updatedValues.instagram,
          facebook:
            values.facebook === undefined &&
            initialValues.facebook !== undefined
              ? null
              : updatedValues.facebook,
          tiktok:
            values.tiktok === undefined && initialValues.tiktok !== undefined
              ? null
              : updatedValues.tiktok,
          personalWebsite:
            values.personalWebsite === undefined &&
            initialValues.personalWebsite !== undefined
              ? null
              : updatedValues.personalWebsite,
          notificationFrequency:
            initialValues.notificationFrequency === undefined &&
            values.notificationFrequency === undefined
              ? "Daily"
              : updatedValues.notificationFrequency,
          commissionTarget:
            initialValues.commissionTarget === undefined &&
            values.commissionTarget === undefined
              ? "self"
              : updatedValues.commissionTarget,

          coverImageUploadId,
          profilePictureUploadId,
          updating: true
        });
      } else {
        // no changes, so we just no-op and succeed
      }
    }

    setUploadState(undefined);

    navigate("/mystore");

    // reload the page to pick up new document since it was
    // still showing insufficient privileges and not updating
    // in the parent router
    // TODO: see if we can get rid of this
    creating && window.location.reload();
  }

  const FormValues = CreateProfileData.omit({
    coverImageUploadId: true,
    profilePictureUploadId: true
  }).extend({
    profilePicture: ImageValueSchema.optional().superRefine(
      superRefineImageValue({
        enforceDefined: true,
        type: "profilePicture"
      })
    ),
    coverImage: ImageValueSchema.optional().superRefine(
      superRefineImageValue({ enforceDefined: false, type: "coverImage" })
    )
  });

  type FormValues = z.infer<typeof FormValues>;

  const initialValues: FormValues = useMemo(
    () => ({
      bio: artistDoc?.bio || "",
      name: user.userDocument.name,
      coverImage: artistDoc
        ? {
            type: "existing",
            url: AssetsResolver.imageUrl(
              artistDoc.coverImage?.path || Config.defaultCoverImagePath,
              "coverimage"
            )
          }
        : {
            type: "existing",
            url: AssetsResolver.imageUrl(
              Config.defaultCoverImagePath,
              "coverimage"
            )
          },
      profilePicture: artistDoc
        ? {
            type: "existing",
            url: AssetsResolver.imageUrl(
              artistDoc.profilePicture.path,
              "profilepicturesmall"
            )
          }
        : undefined,
      nameVisibility:
        (user.userDocument.isArtist && user.userDocument.nameVisibility) ||
        "private",
      instagram: artistDoc?.instagram || undefined,
      facebook: artistDoc?.facebook || undefined,
      tiktok: artistDoc?.tiktok || undefined,
      personalWebsite: artistDoc?.personalWebsite || undefined,
      // legacy data: not all artists have quotes TODO: remove
      quote: artistDoc?.quote || "",
      notificationFrequency: user.userDocument.notificationFrequency || "Daily",
      commissionTarget: user.userDocument.commissionTarget || "self"
    }),
    [artistDoc, user]
  );

  // TODO: consider allowing selfie for profile picture
  // const [{isMobile}] = useDeviceSelectors(window.navigator.userAgent);
  // const cameraInput = useRef<HTMLInputElement | null>(null);

  const [stripeSetupOpen, setStripeSetupOpen] = useState(false);

  const commissionTargetSectionRef = useRef<HTMLElement>(null);

  return (
    <ManageStoreScaffold
      artist={artistDoc}
      artistUser={artistUser}
      backButton={!creating}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& .MuiInputBase-root": {
            backgroundColor: "#fff"
          },
          p: {
            xs: 1,
            sm: 3
          },
          pt: {
            xs: 5,
            sm: 3
          }
        }}
      >
        {!creating ? (
          <Typography variant="h4" mb={3}>
            {"Update Profile"}
          </Typography>
        ) : (
          <Box
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1
            }}
          >
            <Typography variant="h4" mb={1}>
              Welcome!
            </Typography>
            <Typography paragraph>
              Welcome to The Giving Gallery! We are excited to have you be a
              part of it. The first step is to fill out your profile below,
              after which you can upload artwork and set up payments.
            </Typography>
            <Typography paragraph>
              If you encounter any bugs or have any questions, don't hesitate to
              reach out to{" "}
              <a href="mailto:support@thegivinggallery.com">
                support@thegivinggallery.com
              </a>
              , and we'll be happy to help out.
            </Typography>
          </Box>
        )}
        <Formik
          enableReinitialize
          validate={withZodSchema(FormValues)}
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              await update(values);
            } catch (e) {
              dialog.error("failed to update profile", e);
            }
          }}
          innerRef={formRef}
        >
          {({
            handleSubmit,
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue
          }) => (
            <>
              {/* We want this here so the success callback can mutate formik state and set
              the commission target */}
              <StripeConnectSetup
                artistUser={artistUser}
                open={stripeSetupOpen}
                onCancel={() => setStripeSetupOpen(false)}
                onSuccess={() => {
                  setFieldValue("commissionTarget", "self");
                  commissionTargetSectionRef.current?.scrollIntoView({
                    behavior: "smooth"
                  });
                }}
                successMessage="Payments have been enabled! You may now select 'keep my commission'."
              />

              <FormikScrollToError />

              <fieldset disabled={isSubmitting}>
                <FormikEffect onDirtyChange={() => null} />

                <Grid container spacing={3} mb={2}>
                  <Grid item md={6} xs={12}>
                    <Box mb={3}>
                      <FormikField
                        multiline
                        rows={4}
                        name="bio"
                        label="Bio"
                        placeholder="Share your story"
                      />
                    </Box>

                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={6}>
                        <FormikField name="name.first" label="First name" />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikField name="name.last" label="Last Name" />
                      </Grid>
                    </Grid>

                    <RadioGroup
                      sx={{ justifyContent: "space-around", mb: 2 }}
                      row
                      value={values.nameVisibility}
                      name="nameVisibility"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <FormControlLabel
                        value="private"
                        control={<Radio />}
                        label="Keep name private"
                      />
                      <FormControlLabel
                        value="public"
                        control={<Radio />}
                        label="Show name in profile"
                      />
                    </RadioGroup>

                    <FormikField
                      multiline
                      rows={2}
                      name="quote"
                      label="Mental Health Quote"
                      placeholder="A short quote about why supporting mental health matters to you. e.g., 'Supporting mental health matters because...' "
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormikImageField
                      name="profilePicture"
                      label="Profile Picture"
                      style={{ maxWidth: "80%" }}
                      placeholderText={
                        <Typography sx={{ color: "text.secondary" }}>
                          Drag and drop your profile picture here, or click here
                          to select it.{" "}
                          <em>
                            This picture should be a headshot of you, or a
                            headshot of a human-like avatar.
                          </em>
                        </Typography>
                      }
                      crop={profilePictureCrop}
                    />

                    <Box>
                      <FormikImageField
                        name="coverImage"
                        label="Cover Image"
                        placeholderText={
                          <Typography sx={{ color: "text.secondary" }}>
                            Drag and drop your cover image here, or click here
                            to select it. The cover image must have a width of
                            at least 1600, and an aspect ratio of 4/1. For
                            example 1600x300
                          </Typography>
                        }
                        crop={coverImageCrop}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel>External Links</FormLabel>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={6}>
                        <FormikField
                          allowUndefined
                          label="Instagram"
                          name="instagram"
                          placeholder="optional link"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Instagram />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikField
                          allowUndefined
                          label="Facebook"
                          name="facebook"
                          placeholder="optional link"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Facebook />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikField
                          allowUndefined
                          label="TikTok"
                          name="tiktok"
                          placeholder="optional link"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TikTok />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormikField
                          allowUndefined
                          label="Personal Website"
                          name="personalWebsite"
                          placeholder="optional link"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Language />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Notification Frequency</FormLabel>

                    <Typography variant="body2" color="text.secondary">
                      We will send email digests of orders/payouts at the
                      frequency you choose
                    </Typography>

                    <RadioGroup
                      sx={{ justifyContent: "space-around", mb: 2 }}
                      row
                      name="notificationFrequency"
                      value={values.notificationFrequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily Notifications"
                      />

                      <FormControlLabel
                        value="Weekly"
                        control={<Radio />}
                        label="Weekly Notifications"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Commission</FormLabel>

                    <Typography variant="body2" color="text.secondary">
                      The default split is 50% commission (to you), 30% to the
                      nonprofit the buyer selects, and 20% to The Giving Gallery
                      (TGG). If you wish to waive your commission towards the
                      nonprofit or TGG you can select that here, but we
                      encourage you to keep your commission to yourself unless
                      you have a personal reason not to.
                    </Typography>

                    <RadioGroup
                      sx={{ justifyContent: "space-around", mb: 2 }}
                      row
                      name="commissionTarget"
                      value={values.commissionTarget}
                      onChange={(e) => {
                        /**
                         * User can only target themselves for commission if
                         * A) they haven't completed setup yet
                         * OR B) payouts already enabled
                         *
                         * Otherwise, we need to get them to onboard with Stripe.
                         */

                        const setupComplete = Boolean(
                          artistUser.userDocument.setupCompletedOn
                        );

                        const stillInInitialSetup = !setupComplete;

                        const payoutsEnabled = Boolean(
                          artistUser.userDocument.stripeConnect?.payoutsEnabled
                        );

                        if (e.target.value !== "self") {
                          // other options can be selected whenever
                          handleChange(e);
                        } else if (stillInInitialSetup || payoutsEnabled) {
                          // we allow them to select "self"
                          handleChange(e);
                        } else {
                          // we show them the connect setup prompt, which will select it on completion
                          setStripeSetupOpen(true);
                        }
                      }}
                      onBlur={handleBlur}
                    >
                      <FormControlLabel
                        ref={commissionTargetSectionRef}
                        value="self"
                        control={<Radio />}
                        label="keep my commission"
                      />
                      <FormControlLabel
                        value="nonprofit"
                        control={<Radio />}
                        label="waive to nonprofit"
                      />

                      <FormControlLabel
                        value="TGG"
                        control={<Radio />}
                        label="waive to TGG"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </fieldset>
              <LoadingButton
                sx={{ alignSelf: "center", width: "100%" }}
                fullWidth
                size="large"
                variant="contained"
                endIcon={<Person />}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
                loadingMessage={uploadState || "Uploading"}
              >
                {creating ? "Create Profile" : "Update"}
              </LoadingButton>
            </>
          )}
        </Formik>
      </Box>
    </ManageStoreScaffold>
  );
}
