import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * This is a little hacky, but works. It depends on
 * passing "scrollY" as part of state when navigating to
 * another route.
 */
export default function RestoreScroll() {
  const location = useLocation();
  const { scrollY } = location.state || { scrollY: undefined };

  useEffect(() => {
    if (scrollY) {
      window.scrollTo(0, scrollY);
    }
  }, []);

  return null;
}
