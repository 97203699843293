import { FormikContext } from "formik";
import * as React from "react";

interface FormikEffectProps {
  onDirtyChange: (dirty: boolean) => void;
}

export default function FormikEffect({ onDirtyChange }: FormikEffectProps) {
  const formikContext = React.useContext(FormikContext);

  const [dirty, setDirty] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    if (dirty === undefined) {
      setDirty(formikContext.dirty);
      onDirtyChange(formikContext.dirty);
    }

    if (!dirty && formikContext.dirty) {
      setDirty(true);
      onDirtyChange(true);
    }

    if (dirty && !formikContext.dirty) {
      setDirty(false);
      onDirtyChange(false);
    }
  }, [formikContext, dirty, onDirtyChange]);

  return null;
}
