export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr.length > 0;
}

/**
 * Filtering out undefined values is helpful, but the filter doesn't
 * naturally act as a type guard
 */
export function filterIsDefined<T>(arr: (T | undefined)[]): T[] {
  return arr.filter((i) => i !== undefined) as T[];
}
