import { CloudFunctionReturn } from "shared/cloud-functions";
import { create } from "zustand";

type Address = {
  name: string;
  firstName?: string;
  lastName?: string;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  phone?: string;
};

type PaymentsState = {
  paymentIntent?: CloudFunctionReturn<"createPaymentIntent">;
  amount: number;
  address?: Address;
  email?: string;
  // isGift?: boolean;
  // giftDetails?: {
  //   giftSubject?: string;
  //   giftMessage?: string;
  // };
  setEmail(email?: string): void;
  setPaymentIntent(
    paymentIntent?: CloudFunctionReturn<"createPaymentIntent">
  ): void;
  setAddress(address?: Address): void;
};

export const usePaymentsState = create<PaymentsState>((set) => ({
  clientSecret: undefined,
  amount: 5000,
  email: undefined,
  setEmail(email) {
    set({ email });
  },
  setAddress(address) {
    set({ address });
  },
  setPaymentIntent(paymentIntent) {
    set({ paymentIntent, amount: paymentIntent?.costs.total || 5000 });
  }
}));
