import { Box, Breadcrumbs, GridProps, Link, Typography } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTaggedProducts } from "../../helpers/queries";
import { KnownCategories } from "./Categories";
import FlexibleProductList from "../productViews/FlexibleProductList";
import Config from "../../config";
import { NavigateNext } from "@mui/icons-material";
import Schmear from "../about/Schmear";

export default function CategoryPage() {
  const { categoryName } = useParams();

  const category = KnownCategories.find((c) => c.name === categoryName);

  const [artwork, artworkLoading, artworkError] = useTaggedProducts(
    category?.tags || null,
    20
  );

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          backgroundColor: "#374744",
          color: "#DBDFCC",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 2
        }}
      >
        <Breadcrumbs
          sx={{ m: "auto" }}
          separator={<NavigateNext fontSize="small" htmlColor="#DBDFCC" />}
          aria-label="breadcrumbs"
        >
          <Link
            color="#DBDFCC"
            variant="h6"
            underline="hover"
            component={RouterLink}
            to="/artwork"
          >
            gallery
          </Link>

          <Typography
            maxWidth="100%"
            variant="h6"
            color="#DBDFCC"
            sx={{ overflowWrap: "anywhere" }}
          >
            {category?.name}
          </Typography>
        </Breadcrumbs>

        <Typography variant="h5" color="inherit">
          {category?.description}
        </Typography>
      </Box>
      <Box sx={{ width: `min(100%, 1300px)`, p: 1 }}>
        <FlexibleProductList
          {...productListColumns}
          products={artwork || []}
          options={{
            loading: artworkLoading,
            expected: 8
          }}
        />
      </Box>

      <Schmear
        action={{
          type: "link",
          to: "/artwork",
          text: "return to gallery"
        }}
      />
    </Box>
  );
}
