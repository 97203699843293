import { Box, Button, Typography } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import SchmearBottom from "../../assets/schmear-bottom-gray-improved.png";

import {
  Email,
  Facebook,
  LinkedIn,
  Twitter,
  WhatsApp
} from "@mui/icons-material";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Config from "../../config";

interface SchmearProps {
  text?: string;
  textSize?: "smallest" | "small" | "normal";
  action?:
    | {
        text: string;
        type: "link";
        to: string;
      }
    | {
        text: string;
        type: "function";
        onClick: () => void;
      }
    | { type: "social"; url?: string };
}

export default function Schmear(props: SchmearProps) {
  const action = useMemo(() => {
    switch (props.action?.type) {
      case "function":
        return (
          <Button
            color="secondary"
            size="large"
            variant="contained"
            onClick={props.action.onClick}
          >
            {props.action.text}
          </Button>
        );

      case "link":
        return (
          <Link to={props.action.to}>
            <Button color="secondary" size="large" variant="contained">
              {props.action.text}
            </Button>
          </Link>
        );

      case "social": {
        const url = props.action.url || "https://thegivinggallery.com";

        return (
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <FacebookShareButton hashtag="#TheGivingGallery" url={url}>
              <Facebook color="secondary" fontSize="large" />
            </FacebookShareButton>

            <TwitterShareButton
              url={url}
              hashtags={["TheGivingGallery", "Artwork", "MentalHealth"]}
            >
              <Twitter color="secondary" fontSize="large" />
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
              <LinkedIn color="secondary" fontSize="large" />
            </LinkedinShareButton>

            <WhatsappShareButton url={url}>
              <WhatsApp color="secondary" fontSize="large" />
            </WhatsappShareButton>

            <EmailShareButton url={url}>
              <Email color="secondary" fontSize="large" />
            </EmailShareButton>
          </Box>
        );
      }

      case undefined:
        return null;
    }
  }, [props.action]);

  let textVariant: "h2" | "h3" | "h5";

  switch (props.textSize) {
    case undefined:
    case "normal":
      textVariant = "h2";
      break;
    case "small":
      textVariant = "h3";
      break;
    case "smallest":
      textVariant = "h5";
      break;
  }

  return (
    <Box width="100%" mt={{ xs: 3, sm: 5 }}>
      <Box
        sx={{
          maxHeight: "151px",
          transform: "scale(-1)",
          aspectRatio: "1920/146",
          background: `url(${SchmearBottom})`,
          backgroundSize: "contain",
          width: "100%"
        }}
      />

      <Box
        sx={{
          backgroundColor: "#374744",
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          mt: "-2px"
        }}
      >
        {props.text && (
          <Typography
            variant={textVariant}
            color="#DBDFCC"
            textAlign="center"
            sx={{ maxWidth: Config.ux.navBarWidth }}
          >
            {props.text}
          </Typography>
        )}

        {action}
      </Box>

      <Box
        sx={{
          maxHeight: "151px",
          aspectRatio: "1920/146",
          background: `url(${SchmearBottom})`,
          backgroundSize: "contain",
          width: "100%"
        }}
      />
    </Box>
  );
}
