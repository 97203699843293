import Config from "./config";
import { Range } from "./typings/UsefulTypes";

type Pixels = number;
type AspectRatio = string;
type CropFormat1 = [Pixels, Pixels];
type CropFormat2 = [Pixels, Pixels, Pixels, Pixels];
type Crop = CropFormat1 | CropFormat2;
type CropGravity =
  | "center"
  | "forget"
  | "east"
  | "north"
  | "south"
  | "west"
  | "northeast"
  | "northwest"
  | "southeast"
  | "southwest";

/**
 * Dynamic image transforms. ~$10/month, and very useful. When a transform is done once, it's cached.
 *
 * https://docs.bunny.net/docs/stream-image-processing
 */

//docs.bunny.net/docs/stream-image-processing
interface BunnyImageTransform {
  /** Resize the output image to the given width maintaining the current aspect ratio. (default: auto) */
  width: Pixels;

  /** Resize the output image to the given height maintaining the current aspect ratio. (default: auto) */
  height: Pixels;

  /** Crop the output image to match the given aspect ratio. (default: auto) */
  aspect_ratio: AspectRatio;

  /** Determines the compression level of the resulting image. Ignored if output image format is lossless. (default: 85) */
  quality: number; // Range<0, 100>;

  /** Sharpen the output image. (default: false) */
  sharpen: boolean;

  /** Blur the output image. (default: 0) */
  blur: number; // Range<0, 100>;

  /** Crop the output image to the given width and height. */
  crop: Crop;

  /** Set the gravity of the crop operation. (default: center) */
  crop_gravity: CropGravity;

  /** Flip the output image vertically. (default: false) */
  flip: boolean;

  /** Flip the output image horizontally. (default: false) */
  flop: boolean;

  /** Adjusts the brightness of the output image. (default: 0) */
  brightness: number; // Range<-100, 100>;

  /** Adjusts the saturation of the output image. (default: 0) */
  saturation: number; // Range<-100, 100>;

  /** Adjusts the hue of the output image. (default: 0) */
  hue: number; // Range<-180, 180>;

  /** Adjusts the contrast of the output image. (default: 0) */
  contrast: number; // Range<-100, 100>;

  /** Apply a color filter to the output image. */
  filter: string;

  /** Adjust the output image's color depth. */
  color_depth: Range<1, 32>;

  /** Enable image optimization. */
  optimize: boolean;

  /** Preserve the image's transparency. */
  preserve_transparency: boolean;
}

/**
 * All well-known static assets.
 */
type WellKnownStaticAsset =
  | "mha.jpg"
  | "woman-looking-at-colors.png"
  | "amanda-next-to-artwork.jpg"
  | "art-full-bg.png"
  | "artists-intro.png"
  | "blank.gif"
  | "buyers-intro.jpg"
  | "creating-art-from-your-hand-2.png"
  | "creating-art-from-your-hand.png"
  | "diana.jpg"
  | "elle.jpg"
  | "footer-background.png"
  | "gray-shape-bg.svg"
  | "green-shape-bg.svg"
  | "handing-art-to-another-3.png"
  | "handing-art-to-another-2.png"
  | "handing-art-to-another.png"
  | "home-banner-image.jpg"
  | "partners-intro.jpeg"
  | "powered_by_google_on_non_white_hdpi.png"
  | "powered_by_google_on_non_white.png"
  | "powered_by_google_on_white_hdpi.png"
  | "powered_by_google_on_white.png"
  | "reaching-hand-in-painting.png"
  | "red-shape-bg.svg"
  | "remy.jpg"
  | "schmear-bottom-gray-fixed.png"
  | "schmear-bottom-gray.png"
  | "schmear-top-gray.png"
  | "small-tgg-logo.png"
  | "tgg-logo-smaller.png"
  | "tgg-logo.png"
  | "hand-reaching-away.png";

export function staticAsset(asset: WellKnownStaticAsset) {
  return `${Config.bunnyCdn.staticAssetsBaseUrl}/static/images/${asset}`;
}

const AssetsResolver = {
  /**
   * For getting static a
   *
   * These will be fetched from our prod cdn, even if we are on dev or local.
   */
  staticAsset,

  imageUrl: (
    target: string,
    transform:
      | "artworkthumbnail"
      | "coverimage"
      | "coverimagesmall"
      | "profilepicture"
      | "profilepicturesmall"
      | "nonprofit"
      | "blurredmediumsquare"
      | Partial<BunnyImageTransform>
  ) => {
    let bunnyUrl = Config.bunnyCdn.baseUrl;

    if (target.includes("/products/")) {
      bunnyUrl = Config.bunnyCdn.productUrl;
    }

    // image classes are being moved to be done client-side

    if (typeof transform === "string") {
      switch (transform) {
        case "artworkthumbnail":
          transform = {
            width: 500,
            height: 500,
            aspect_ratio: "1:1"
          };
          break;
        case "coverimage":
          transform = {
            width: 1600,
            height: 400
          };
          break;
        case "coverimagesmall":
          transform = {
            width: 400,
            height: 100,
            blur: 40
          };
          break;
        case "profilepicture":
          transform = {
            width: 400
          };
          break;
        case "profilepicturesmall":
          transform = {
            width: 200
          };
          break;
        case "nonprofit":
          transform = {
            width: 800,
            height: 800
          };
          break;
        case "blurredmediumsquare":
          transform = {
            blur: 15,
            height: 400,
            width: 400,
            aspect_ratio: "1:1"
          };
          break;

        default: {
          const exhaustiveCheck: never = transform;
          throw new Error(`Unhandled color case: ${exhaustiveCheck}`);
        }
      }
    }

    // shim to support 1:1 aspect ratios with center crop,
    // due to a bunnycdn bug (we have a support ticket with them)
    if (typeof transform === "object" && transform.aspect_ratio === "1:1") {
      transform.aspect_ratio = "100:101";
    }

    const url = new URL(`${bunnyUrl}/${target}`);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(transform)) {
      if (Array.isArray(value)) {
        searchParams.append(key, value.join(","));
      } else {
        searchParams.append(key, value.toString());
      }
    }

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(transform)) {
      if (Array.isArray(value)) {
        params.append(key, value.join(","));
      } else if (
        typeof value === "boolean" ||
        typeof value === "number" ||
        typeof value === "string"
      ) {
        params.append(key, value.toString());
      }
    }

    url.search = searchParams.toString();

    return url.toString();
  }
};

export default AssetsResolver;
