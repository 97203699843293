import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  GridProps,
  IconButton,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { query, where } from "firebase/firestore";
import {
  NavLink,
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { productsCollection } from "../databaseModels/DatabaseModels";

import {
  Edit,
  Facebook,
  Instagram,
  Language,
  NavigateNext,
  Storefront,
  Visibility
} from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { ArtistDocument } from "shared/models/artist-models";
import AssetsResolver from "../AssetsResolver";
import Config from "../config";
import { useCollectionDataCustom, useSingleArtist } from "../helpers/queries";
import { TGGArtistUser } from "../redux/slices/authSlice";
import { useAppSelector } from "../redux/store";
import ReportContent from "./helpers/ReportContent";
import RestoreScroll from "./helpers/RestoreScroll";
import TikTok from "./icons/TikTok";
import SetupWizard from "./my-store/SetupWizard";
import SuborderTable from "./my-store/SuborderTable";
import ViewStripeDashboardButton from "./my-store/ViewStripeDashboardButton";
import FlexibleProductList from "./productViews/FlexibleProductList";
import { CustomCardAction } from "./productViews/ProductCard";

import { Constants } from "shared/constants";
import RichAlert from "./helpers/RichAlert";
import ShareButton from "./helpers/ShareButton";
import slugify from "shared/tools/slugify";

type SingleArtistPageProps =
  | {
      privateView: true;
      artistUser: TGGArtistUser;
      artistDocument: ArtistDocument;
    }
  | {
      privateView: false;
      artistUser: undefined;
      artistDocument: undefined;
    };

export default function SingleArtistPage({
  privateView,
  artistUser,
  artistDocument
}: SingleArtistPageProps) {
  const { storeName: routeStoreName } = useParams();

  const navigate = useNavigate();

  const auth = useAppSelector((state) => state.auth);

  const fullAccessUser =
    auth.user?.roles.includes("admin") || auth.user?.roles.includes("reporter");

  const editArtworkAction: CustomCardAction = {
    text: "Edit",
    onClick: (product) => {
      navigate(`/mystore/update/art/${product.slug}`, {
        state: { scrollY: window.scrollY }
      });
    }
  };

  const [loadedArtist, loadingArtist, artistError] = useSingleArtist(
    artistDocument ? undefined : routeStoreName
  );

  const artist = artistDocument || loadedArtist;

  useEffect(() => {
    if (routeStoreName && artist) {
      if (routeStoreName !== artist.storeSlug) {
        // we are on an artist, but using an old or non-canonical slug, and should redirect

        // TODO: when we do SSR, we should make sure there's a 30[?] redirect code issued in this instance

        // FIXME: this will result in another load, which isn't needed or desired here. Rather, we should change the route but
        // preserve the loaded component state somehow. Likely passing the full record via state, or a subscription to it

        console.log(
          `redirecting to canonical artist store slug: ${artist.storeSlug}`
        );

        navigate(`/artists/${artist.storeSlug}`, {
          replace: true
        });
      }
    }
  }, [artist, routeStoreName]);

  const artworkQuery = artist
    ? fullAccessUser || privateView
      ? query(productsCollection, where("artist", "==", artist.artistId))
      : query(
          productsCollection,
          where("live", "==", true),
          where("artist", "==", artist.artistId)
        )
    : null;

  const [artwork = [], loadingArtwork, error] =
    useCollectionDataCustom(artworkQuery);

  const waitingForArt = loadingArtist || loadingArtwork;

  const artworkNextToBio = useMediaQuery("(min-width: 1150px)");

  const isMyPublicStore =
    auth.user?.userDocument?.isArtist &&
    routeStoreName &&
    slugify(auth.user.userDocument.storeName) === slugify(routeStoreName);

  const pieceLimit =
    auth.user?.userDocument?.isArtist &&
    auth.user.userDocument.pieceLimit !== undefined
      ? auth.user.userDocument.pieceLimit
      : Constants.defaultPieceLimit;

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 4,
    xl: 3
  };

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%"
      }}
    >
      <RestoreScroll />
      <Box sx={{ maxWidth: "min(1600px, 100%)" }}>
        <Box
          sx={{
            minHeight: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* Cover photo section */}
          <Box
            style={{
              position: "relative",
              width: "1600px",
              aspectRatio: "1600/400",
              maxWidth: "100%",
              height: "auto"
            }}
          >
            <Skeleton
              component="div"
              style={{
                position: "absolute",
                height: "auto",
                top: 0,
                left: 0,
                display: "block",
                gridArea: "1/1",
                maxWidth: "100%",
                minWidth: 0,
                aspectRatio: "1600/400"
              }}
              variant="rectangular"
              width={1600}
            />
            {artist && (
              <img
                alt={`Store Cover Image`}
                src={AssetsResolver.imageUrl(
                  artist.coverImage?.path || Config.defaultCoverImagePath,
                  "coverimage"
                )}
                width="1600"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "auto",
                  display: "block",
                  gridArea: "1/1",
                  zIndex: 1,
                  maxWidth: "100%"
                }}
              />
            )}

            {isMyPublicStore && (
              <Link
                to="/mystore"
                component={NavLink}
                sx={{
                  position: "absolute",
                  right: 3,
                  bottom: 3,
                  zIndex: 2
                }}
              >
                <Button variant="contained" endIcon={<Edit />}>
                  Manage Store
                </Button>
              </Link>
            )}
            {privateView && artist && (
              <Link
                to={`/artists/${artist.storeSlug}`}
                component={NavLink}
                sx={{
                  position: "absolute",
                  right: 3,
                  bottom: 3,
                  zIndex: 2
                }}
              >
                <Button variant="contained" endIcon={<Visibility />}>
                  See Public View
                </Button>
              </Link>
            )}
          </Box>

          {artist?.live == false && (
            <RichAlert severity="warning">
              Profile is not live yet. It will be visible to others after
              completing store setup.
            </RichAlert>
          )}

          {/* Artist info + artwork section */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              flexGrow: 1
            }}
          >
            {/* Artist info section */}
            <Box
              sx={{
                flexGrow: 1,
                backgroundColor: "#E5F6FD",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexBasis: "600px"
              }}
            >
              <Alert
                severity="info"
                icon={false}
                style={{
                  transition: "all 1s ease",
                  whiteSpace: "pre-wrap",
                  maxWidth: "100%",
                  alignItems: "center"
                }}
              >
                {artist && (
                  <>
                    <AlertTitle
                      sx={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          mb: 1,
                          alignItems: "center",
                          justifyContent: "flex-end",
                          maxWidth: "100%"
                        }}
                      >
                        <Breadcrumbs
                          sx={{ m: "auto" }}
                          separator={<NavigateNext fontSize="small" />}
                          aria-label="breadcrumbs"
                        >
                          <Link
                            color="inherit"
                            variant="h6"
                            underline="hover"
                            component={RouterLink}
                            to="/artists"
                          >
                            Artists
                          </Link>

                          <Typography
                            maxWidth="100%"
                            variant="h6"
                            sx={{ overflowWrap: "anywhere" }}
                            color="inherit"
                          >
                            {artist.name
                              ? `${artist.name.first} ${artist.name.last}`
                              : artist.storeName}
                          </Typography>
                        </Breadcrumbs>

                        {privateView ? (
                          <Box>
                            <Tooltip title="Update Profile">
                              <IconButton
                                onClick={() =>
                                  navigate("/mystore/update/profile", {
                                    state: { scrollY: window.scrollY }
                                  })
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <ReportContent
                            resourceType="artist"
                            artist={artist}
                          />
                        )}
                      </Box>
                    </AlertTitle>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <Box
                          display="grid"
                          sx={{
                            float: "left",
                            mr: 2,
                            mb: 2,
                            overflow: "hidden",
                            borderRadius: 1,
                            width: {
                              xs: "150px",
                              sm: "175px",
                              md: "200px"
                            },
                            height: {
                              xs: "150px",
                              sm: "175px",
                              md: "200px"
                            }
                          }}
                        >
                          <Skeleton
                            style={{
                              gridArea: "1/1",
                              display: "block",
                              borderRadius: 1
                            }}
                            sx={{ boxShadow: 3 }}
                            variant="circular"
                            width="100%"
                            height="100%"
                          />
                          {artist && (
                            <Box
                              component="img"
                              sx={{
                                boxShadow: 3,
                                gridArea: "1/1",
                                width: "100%",
                                height: "100%",
                                borderRadius: 1,
                                zIndex: 1,
                                display: "block"
                              }}
                              src={AssetsResolver.imageUrl(
                                artist.profilePicture?.path || "",
                                "profilepicturesmall"
                              )}
                            />
                          )}
                        </Box>

                        {artist ? (
                          <Typography
                            sx={{
                              wordWrap: "anywhere"
                            }}
                          >
                            {artist.bio ||
                              "This artist prefers to keep private"}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1,
                          rowGap: 3
                        }}
                      >
                        {/* Social media links */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly"
                          }}
                        >
                          {artist?.instagram && (
                            <a
                              href={
                                artist.instagram.includes("instagram.com/")
                                  ? artist.instagram.includes("https://")
                                    ? artist.instagram
                                    : "https://" + artist.instagram
                                  : "https://instagram.com/" +
                                    artist.instagram.replace("@", "")
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Tooltip arrow title="Artist's Instagram">
                                <IconButton
                                  size="large"
                                  sx={{
                                    transition: "all .3s",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      opacity: 0.9,
                                      color: "#70212C"
                                    }
                                  }}
                                >
                                  <Instagram fontSize="large" />
                                </IconButton>
                              </Tooltip>
                            </a>
                          )}

                          {artist?.facebook && (
                            <a
                              href={
                                artist.facebook.includes("facebook.com/")
                                  ? artist.facebook.includes("https://")
                                    ? artist.facebook
                                    : "https://" + artist.facebook
                                  : "https://facebook.com/" +
                                    artist.facebook.replace("@", "")
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Tooltip arrow title="Artist's Facebook">
                                <IconButton
                                  sx={{
                                    transition: "all .3s",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      opacity: 0.9,
                                      color: "#70212C"
                                    }
                                  }}
                                >
                                  <Facebook fontSize="large" />
                                </IconButton>
                              </Tooltip>
                            </a>
                          )}

                          {artist?.tiktok && (
                            <a
                              href={
                                artist.tiktok.includes("tiktok.com/")
                                  ? artist.tiktok.includes("https://")
                                    ? artist.facebook
                                    : "https://" + artist.tiktok
                                  : "https://tiktok.com/@" +
                                    artist.tiktok.replace("@", "")
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Tooltip arrow title="Artist's TikTok">
                                <IconButton
                                  sx={{
                                    transition: "all .3s",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      opacity: 0.9,
                                      color: "#70212C"
                                    }
                                  }}
                                >
                                  <TikTok />
                                </IconButton>
                              </Tooltip>
                            </a>
                          )}

                          {artist?.personalWebsite && (
                            <a
                              href={
                                artist.personalWebsite.includes("https://") ||
                                artist.personalWebsite.includes("http://")
                                  ? artist.personalWebsite
                                  : "http://" + artist.personalWebsite
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Tooltip arrow title="Artist's Website">
                                <IconButton
                                  sx={{
                                    transition: "all .3s",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      opacity: 0.9,
                                      color: "#70212C"
                                    }
                                  }}
                                >
                                  <Language fontSize="large" />
                                </IconButton>
                              </Tooltip>
                            </a>
                          )}
                        </Box>

                        {privateView && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 3
                            }}
                          >
                            <Divider />
                            {!artistUser.userDocument.setupCompletedOn ? (
                              <SetupWizard
                                artistDoc={artist}
                                artistUser={artistUser}
                                artworkLength={artwork.length}
                              />
                            ) : (
                              <Typography textAlign="center">
                                Setup has been completed and your store is now
                                live!
                              </Typography>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-evenly"
                              }}
                            >
                              {artistUser.userDocument.stripeConnect
                                ?.payoutsEnabled && (
                                <ViewStripeDashboardButton />
                              )}
                              <Button
                                onClick={() =>
                                  tableWrapperRef.current?.scrollIntoView({
                                    behavior: "smooth"
                                  })
                                }
                              >
                                Go to Orders
                              </Button>
                            </Box>
                          </Box>
                        )}
                        <Box
                          component="blockquote"
                          sx={{
                            display: {
                              xs: "none",
                              md: "unset"
                            }
                          }}
                        >
                          <Typography
                            color="text.secondary"
                            sx={{
                              borderLeft: 1,
                              borderColor: "primary.main",
                              pl: 2
                            }}
                          >
                            {artist.quote.includes('"') ||
                            artist.quote.includes("“")
                              ? artist.quote
                              : `“${artist.quote}”`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Alert>
            </Box>

            {/* Artwork section */}
            <Box
              maxWidth="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={1}
              flexGrow={999}
              flexBasis="550px"
              bgcolor={
                artworkNextToBio ? "rgba(229, 246, 253, 0.5)" : "transparent"
              }
            >
              {artist && (
                <>
                  {privateView && (
                    <Typography variant="body2" mt={2}>
                      {artwork.length >= pieceLimit
                        ? "At your current "
                        : "Current "}
                      limit of {pieceLimit} pieces, you can request an increase.
                    </Typography>
                  )}

                  {privateView && (
                    <Typography variant="body2">
                      {(artistUser.userDocument.commissionTarget || "self") ===
                      "self"
                        ? "You receive 50% commission from sales."
                        : artistUser.userDocument.commissionTarget === "TGG"
                          ? "You have waived your 50% commission towards TGG"
                          : "You have waived your 50% commission towards nonprofit"}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      // position: "absolute",
                      // left: 0,
                      // bottom: 0,
                      // zIndex: 2,
                      p: 0.5,
                      gap: 1,
                      m: 1,
                      borderTopRightRadius: 3,
                      color: "text.secondary",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <Storefront color="inherit" />
                    <Typography
                      color="text.secondary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {artist.storeName}
                    </Typography>
                    {artist.live && (
                      <ShareButton
                        // TODO: construct this in a way that matches future store name slugs
                        path={`/artists/${artist.storeSlug}`}
                        sx={{ position: "absolute", right: 4 }}
                        prompt={
                          <>
                            Share{" "}
                            {artist.name
                              ? `${artist.name.first} ${artist.name.last}`
                              : artist.storeName}
                            's store, to help spread their artwork and awareness
                            of The Giving Gallery.
                            <blockquote>
                              <Typography
                                color="text.secondary"
                                sx={{
                                  borderLeft: 1,
                                  borderColor: "primary.main",
                                  pl: 2
                                }}
                              >
                                {artist.quote.includes('"') ||
                                artist.quote.includes("“")
                                  ? artist.quote
                                  : `“${artist.quote}”`}
                              </Typography>
                            </blockquote>
                          </>
                        }
                      />
                    )}
                  </Box>

                  <Divider flexItem variant="middle" />
                </>
              )}
              {artist && !waitingForArt && (
                <Box
                  sx={{
                    width: "100%",
                    mb: 2
                  }}
                >
                  <FlexibleProductList
                    {...productListColumns}
                    products={artwork}
                    customCardAction={
                      privateView ? editArtworkAction : undefined
                    }
                    uploadCard={
                      privateView && artwork.length < pieceLimit
                        ? { include: true }
                        : { include: false }
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {artistUser && privateView && (
          <Box ref={tableWrapperRef}>
            <SuborderTable user={artistUser} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
