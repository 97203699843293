import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { orderBy, query } from "firebase/firestore";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { Fragment, useEffect, useState } from "react";
import { ordersCollection } from "../../databaseModels/DatabaseModels";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

import {
  BackHandOutlined,
  InfoOutlined,
  WarningAmber
} from "@mui/icons-material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { Order, USAddress } from "shared/models/order-models";
import { currency, sumCurrencyArray } from "shared/tools/currency-tools";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { useCollectionDataCustom } from "../../helpers/queries";
import { dialog } from "../../zustand/imperative-dialog";
import LoadingButton from "../helpers/LoadingButton";
import MarginProduct from "../productViews/ProductImage";
import { Link } from "react-router-dom";
import slugify from "shared/tools/slugify";

const currencyFromCents = (amount: number) =>
  currency(amount, { fromCents: true });

const orderTableColumns: MRT_ColumnDef<Order>[] = [
  {
    accessorKey: "orderId",
    header: "Order Id"
  },
  {
    accessorFn: (order) => order.dates.createdOn,
    header: "Placed",
    id: "dates.createdOn",
    Cell({ cell, column, row, table }) {
      const created = cell.getValue() as Date;
      return DateTime.fromJSDate(created).toRelative();
    },
    size: 130
  },
  {
    accessorFn: (order) =>
      `${order.customer.name.first} ${order.customer.name.last}`,
    header: "Customer Name"
  },
  {
    accessorFn: (order) => order.state.status,
    header: "Status",
    id: "status"
  },
  {
    accessorFn: (order) => order,
    header: "Flags",
    id: "flags",
    Cell(args) {
      const order = args.cell.getValue() as Order;

      let flag: string | undefined = undefined;

      let icon = <WarningAmber />;

      if (order.state.onHold) {
        flag = "Currently on hold";
        icon = <BackHandOutlined />;
      } else if (!order.events.printing.confirmedOn) {
        flag = "Needs confirming in printful";
      } else if (
        order.events.shipment.fullyShippedOn &&
        !order.events.fulfillment.fullyDeliveredOn &&
        DateTime.fromJSDate(order.events.shipment.fullyShippedOn).diffNow(
          "days"
        ).days <= -4
      ) {
        flag = "May be delivered, check tracking and mark";
      }

      if (flag) {
        return <Tooltip title={flag}>{icon}</Tooltip>;
      } else {
        return null;
      }
    }
  },
  {
    accessorFn: (order) => order,
    header: "Earnings",
    Cell(args) {
      const order = args.cell.getValue() as Order;

      const split = order.financial.split;

      return (
        <Box sx={{ display: "flex" }}>
          <Typography>{currencyFromCents(split.tgg).format()}</Typography>

          <Tooltip
            arrow
            title={
              <Box
                sx={{
                  display: "flex",
                  columnGap: 3,
                  "& .MuiBox-root": {
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 0.5
                  }
                }}
              >
                <Box>
                  <Typography>Total</Typography>
                  <Typography>Artists' Earnings</Typography>
                  <Typography>Nonprofit's Earnings</Typography>
                  <Typography>TGG's Earnings</Typography>
                </Box>

                <Box>
                  <Typography>
                    {currencyFromCents(order.financial.costs.base).format()}
                  </Typography>
                  <Typography>
                    {sumCurrencyArray(
                      Object.values(split.artists).map(currencyFromCents)
                    ).format()}{" "}
                  </Typography>
                  <Typography>
                    {currencyFromCents(split.nonprofit).format()}
                  </Typography>
                  <Typography>
                    {currencyFromCents(split.tgg).format()}{" "}
                  </Typography>
                </Box>
              </Box>
            }
          >
            <InfoOutlined
              color="action"
              sx={{ fontSize: 13, cursor: "pointer" }}
            />
          </Tooltip>
        </Box>
      );
    }
  },
  {
    accessorFn: (order) =>
      order.selectedNonprofit.shortName || order.selectedNonprofit.name,
    id: "nonProfitDisplayName",
    header: "NonProfit"
  }
];

interface OrdersTableProps {
  readOnly: boolean;
}

export function OrdersTable({ readOnly }: OrdersTableProps) {
  // TODO: leverage loading and error states here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orders = [], loading, error] = useCollectionDataCustom(
    query(ordersCollection, orderBy("dates.createdOn", "desc"))
  );

  function prettyPrintAddress(address: USAddress): string {
    return `${address.line1}${address.line2 ? ` ${address.line2}` : ""}, ${
      address.city
    } ${address.state}, ${address.zipcode}`;
  }

  const [markingAsDelivered, setMarkingAsDelivered] = useState<
    undefined | string
  >(undefined);

  const [deliveryDate, setDeliveryDate] = useState<DateTime | undefined>(
    undefined
  );

  const [loadingMarkAsDelivered, setLoadingMarkAsDelivered] = useState(false);

  useEffect(() => {
    if (!markingAsDelivered) {
      setDeliveryDate(undefined);
    }
  }, [markingAsDelivered]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="span" variant="h4" textAlign="center">
        All Orders
      </Typography>
      <MaterialReactTable
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        // muiTablePaginationProps={{
        //     rowsPerPageOptions: [3, 5, 10, 20],
        // }}

        columns={orderTableColumns}
        data={orders}
        enableColumnResizing
        enablePinning
        positionToolbarAlertBanner="bottom"
        muiTablePaperProps={{
          elevation: 0
        }}
        renderDetailPanel={({ row }) => {
          const order = row.original;

          return (
            <Box m={3}>
              <Dialog
                open={Boolean(markingAsDelivered)}
                onClose={() => setMarkingAsDelivered(undefined)}
              >
                <DialogTitleWithClose
                  onClose={() => setMarkingAsDelivered(undefined)}
                >
                  Mark as Delivered
                </DialogTitleWithClose>
                {markingAsDelivered && (
                  <>
                    <DialogContent>
                      <Typography paragraph>
                        Mark Order ID = {markingAsDelivered} as delivered.
                      </Typography>

                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                          sx={{ width: "100%" }}
                          onAccept={(v) => setDeliveryDate(v as DateTime)}
                        />
                      </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        disabled={!deliveryDate}
                        loading={loadingMarkAsDelivered}
                        onClick={async () => {
                          setLoadingMarkAsDelivered(true);

                          try {
                            await callCloudFunction("markOrderAsDelivered", {
                              orderId: markingAsDelivered,
                              dateUnixTimestamp: deliveryDate!.toUnixInteger()
                            });
                            setMarkingAsDelivered(undefined);
                          } catch (e) {
                            dialog.error("Can't mark as delivered", e);
                          } finally {
                            setLoadingMarkAsDelivered(false);
                          }
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </DialogActions>
                  </>
                )}
              </Dialog>
              <Typography variant="h5">Customer Information</Typography>
              <Box m={2} sx={{ maxWidth: "100%" }}>
                <Typography>
                  Shipping Address:{" "}
                  {prettyPrintAddress(order.customer.shippingAddress)}
                </Typography>
                <Typography>
                  Name:{" "}
                  {order.customer.name.first + " " + order.customer.name.last}
                </Typography>
                <Typography>Email: {order.customer.email}</Typography>
                {order.customer.phone ? (
                  <Typography>Phone: {order.customer.phone}</Typography>
                ) : (
                  <></>
                )}
              </Box>
              <Typography variant="h5">Order Status</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}
                    >
                      <TableCell>Placed</TableCell>
                      <TableCell>Fully shipped</TableCell>
                      <TableCell>Fully delivered</TableCell>
                      <TableCell>Paid</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}
                    >
                      <TableCell>
                        {order.dates?.createdOn
                          ? DateTime.fromJSDate(
                              order.dates?.createdOn
                            ).toRelative()
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {order.events?.shipment.fullyShippedOn
                          ? DateTime.fromJSDate(
                              order.events?.shipment.fullyShippedOn
                            ).toRelative()
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {order.events?.fulfillment.fullyDeliveredOn ? (
                          DateTime.fromJSDate(
                            order.events?.fulfillment.fullyDeliveredOn
                          ).toRelative()
                        ) : (
                          <Button
                            size="small"
                            disabled={readOnly}
                            variant="outlined"
                            onClick={() => setMarkingAsDelivered(order.orderId)}
                          >
                            Mark as delivered
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        {order.events?.payout.fullyCompletedOn
                          ? DateTime.fromJSDate(
                              order.events?.payout.fullyCompletedOn
                            ).toRelative()
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="h5" mt={3} mb={2}>
                Actions
              </Typography>

              <Typography paragraph maxWidth="600px">
                Orders with a "hold" placed on them are prevented from paying
                out during the normal daily order processor. Useful for orders
                where the customer has an issue we are dealing with. Currently{" "}
                {order.state.onHold ? (
                  <em>on hold</em>
                ) : (
                  <>
                    <em>not</em> on hold
                  </>
                )}
                .
              </Typography>

              {order.state.onHold ? (
                <Button
                  size="small"
                  disabled={readOnly}
                  variant="outlined"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to remove the hold on order id=${order.orderId}?`
                      )
                    ) {
                      callCloudFunction("modifyOrderHold", {
                        orderId: order.orderId,
                        action: "remove hold"
                      });
                    }
                  }}
                >
                  Remove Hold
                </Button>
              ) : (
                <Button
                  size="small"
                  disabled={readOnly}
                  variant="outlined"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to place a hold on order id=${order.orderId}?`
                      )
                    ) {
                      callCloudFunction("modifyOrderHold", {
                        orderId: order.orderId,
                        action: "place on hold"
                      });
                    }
                  }}
                >
                  Place Hold
                </Button>
              )}
              <Typography variant="h5" mt={3}>
                Purchased Artwork
              </Typography>
              <Grid
                container
                spacing={2}
                display={"flex"}
                sx={{ maxWidth: "100%" }}
              >
                {Object.values(order.cart || {}).map((p, index) => (
                  <Fragment key={index}>
                    {order?.cart && order.snapshots.products ? (
                      <>
                        <Grid
                          item
                          xs={2}
                          key={index}
                          display="flex"
                          flexDirection="row"
                        >
                          <MarginProduct
                            product={order.snapshots.products[p.product.id]!}
                            variant={
                              order.snapshots.printful.variants[
                                p.printfulVariant.id
                              ]!
                            }
                            width={75}
                            outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                            fit={p.fit}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Tooltip title={`Line item: ${p.lineItem}`}>
                            <Typography variant="body1" fontWeight="bold">
                              {order.snapshots.products[p.product.id]!.title},
                              <br />
                              by{" "}
                              <Link
                                to={`/artists/${slugify(
                                  order.snapshots.products[p.product.id]!
                                    .artistName
                                )}`}
                                target="_blank"
                                rel="noopener"
                              >
                                {
                                  order.snapshots.products[p.product.id]!
                                    .artistName
                                }
                              </Link>
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography variant="body1">
                            {
                              order.snapshots.printful.variants[
                                p.printfulVariant.id
                              ]!.name
                            }
                            ,{" "}
                            {p.fit.fitStyle === "center_crop"
                              ? "cropped"
                              : "with margins"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography variant="body1">
                            Quantity:{p.count || "?"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography variant="body1">
                            <a
                              href={
                                order.events.shipment.itemsShipped.find(
                                  (i) => i.lineItem === p.lineItem
                                )
                                  ? order.events.shipment.trackingUrls[
                                      order.events.shipment.itemsShipped.find(
                                        (i) => i.lineItem === p.lineItem
                                      )!.shipmentId
                                    ]
                                  : "-"
                              }
                            >
                              {order.events.shipment.itemsShipped.find(
                                (i) => i.lineItem === p.lineItem
                              )
                                ? order.events.shipment.trackingUrls[
                                    order.events.shipment.itemsShipped.find(
                                      (i) => i.lineItem === p.lineItem
                                    )!.shipmentId
                                  ]
                                : ""}
                            </a>
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ))}
              </Grid>
              <Typography variant="h5" mt={3}>
                Misc
              </Typography>
              <Box sx={{ ml: 2 }}>
                <Typography>
                  Printful Status:{" "}
                  <em>{order.events.printing.printfulStatus || "N/A"}</em>
                </Typography>

                {/* <Typography>Financial: {prettyPrintCosts(order)}</Typography> */}
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}
                    >
                      <TableCell>Base</TableCell>
                      <TableCell>Artist's Earnings</TableCell>
                      <TableCell>Nonprofit's Earnings</TableCell>
                      <TableCell>TGG's Earnings</TableCell>
                      <TableCell>Printing{"(base)"}</TableCell>
                      <TableCell>Shipping</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {currencyFromCents(order.financial.costs.base).format()}
                      </TableCell>
                      <TableCell>
                        {sumCurrencyArray(
                          Object.values(order.financial.split.artists).map(
                            (element) => currencyFromCents(element)
                          )
                        ).format()}
                      </TableCell>
                      <TableCell>
                        {currencyFromCents(
                          order.financial.split.nonprofit
                        ).format()}
                      </TableCell>
                      <TableCell>
                        {currencyFromCents(order.financial.split.tgg).format()}{" "}
                      </TableCell>
                      <TableCell>
                        {currencyFromCents(
                          order.financial.costs.printing.subtotal
                        ).format()}
                      </TableCell>
                      <TableCell>
                        {currencyFromCents(
                          order.financial.costs.printing.shipping
                        ).format()}
                      </TableCell>
                      <TableCell>
                        {currencyFromCents(order.financial.costs.tax).format()}
                      </TableCell>
                      <TableCell>
                        $
                        {currency(order.financial.costs.total, {
                          fromCents: true
                        }).toString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
}

function prettyPrintCosts(order: Order) {
  const convertToCurrencyFormat = (data: unknown): unknown => {
    if (typeof data === "number") {
      return currency(data, { fromCents: true }).format();
    } else if (Array.isArray(data)) {
      return data.map(convertToCurrencyFormat);
    } else if (typeof data === "object" && data !== null) {
      return Object.keys(data).reduce(
        (newObj, key) => {
          return {
            ...newObj,
            [key]: convertToCurrencyFormat(data[key as keyof typeof data])
          };
        },
        {} as { [key: string]: unknown }
      );
    } else {
      return data;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function prettyPrintObject(obj: any, indent = 0) {
    let str = "";
    const padding = " ".repeat(indent);

    if (Array.isArray(obj)) {
      str += "[\n";
      for (const item of obj) {
        str += padding + "  " + prettyPrintObject(item, indent + 2) + ",\n";
      }
      str += padding + "]";
    } else if (typeof obj === "object" && obj !== null) {
      str += "{\n";
      for (const key in obj) {
        str +=
          padding +
          "  " +
          key +
          ": " +
          prettyPrintObject(obj[key], indent + 2) +
          ",\n";
      }
      str += padding + "}";
    } else if (typeof obj === "string") {
      str += `${obj}`;
    } else {
      str += obj;
    }

    return str;
  }

  return (
    <Typography>
      <pre>
        {prettyPrintObject(convertToCurrencyFormat(order.financial.costs))}
      </pre>
    </Typography>
  );
}
