import { AddPhotoAlternate } from "@mui/icons-material";
import { Box, InputLabel } from "@mui/material";
import { useField } from "formik";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface StyledDropzoneProps extends DropzoneOptions {
  label: string;
  name: string;
  backgroundColor?: string;
  placeholderImage?: React.ReactNode;
  placeholderText?: React.ReactNode;
}

export default function StyledDropzone(props: StyledDropzoneProps) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: { "image/*": [] }, ...props });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(props.name);

  const hasError = meta.touched && meta.error !== undefined;

  return (
    <Box
      {...getRootProps({})}
      sx={{
        p: 1,

        border: 1,
        borderRadius: 1,
        borderColor: hasError
          ? "error.main"
          : isFocused
          ? "primary.main"
          : "grey.400",
        position: "relative",
        backgroundColor: "#fff",
        "&:hover": {
          borderColor: "text.primary",
          cursor: "pointer"
        }
      }}
    >
      <input {...getInputProps()} />

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
          justifyContent: "center"
        }}
      >
        {props.placeholderImage ? (
          props.placeholderImage
        ) : (
          <AddPhotoAlternate
            sx={{ color: "primary.main", fontSize: 35, mr: 2 }}
          />
        )}

        <Box sx={{ flexBasis: "250px", flexGrow: 1 }}>
          {props.placeholderText}
        </Box>
      </Box>
      <InputLabel
        sx={{
          // position the label so it occludes the border, just like TextField labels
          position: "absolute",
          // top: "-13px",
          // left: "15px",
          top: 0,
          left: 0,
          transform: "translate(14px, -9px) scale(0.75)",
          backgroundColor: props.backgroundColor || "white"
        }}
      >
        {props.label}
      </InputLabel>
    </Box>
  );
}
