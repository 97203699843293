import { useFormikContext } from "formik";
import { useEffect } from "react";

export interface FormikObserverProps<V> {
  /**Cannot be defined inline on props */
  onChange: (values: V) => void;
}

export function FormikObserver<V>({ onChange }: FormikObserverProps<V>): null {
  const { values } = useFormikContext<V>();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
}
