import { cloneDeep } from "lodash";
import { FitStyle } from "shared/models/order-models";
import { PrintfulApiVariant } from "shared/models/printful-models";
import { ProductDocument } from "shared/models/product-models";
import { create } from "zustand";
import trackEvent from "../helpers/plausible";

export interface ShoppingCartItem {
  product: ProductDocument;
  count: number;
  printfulVariant: PrintfulApiVariant;
  fit: FitStyle;
}

export type CartState = {
  loading: boolean;
  loadWith(items: ShoppingCartItem[]): void;
  addItem(add: {
    product: ProductDocument;
    count: number;
    printfulVariant: PrintfulApiVariant;
    fit: FitStyle;
  }): void;
  items: ShoppingCartItem[];
  removeItem(remove: {
    product: ProductDocument;
    removeCount: "all" | number;
    printfulVariant: PrintfulApiVariant;
    fit: FitStyle;
  }): void;
  clearCart(options?: { resetLoading: boolean }): void;
};

/**
 * Adds to the state passed into it
 */
function mutativeAddItem(
  items: ShoppingCartItem[],
  add: {
    product: ProductDocument;
    count: number;
    printfulVariant: PrintfulApiVariant;
    fit: FitStyle;
  },
  options: { track: boolean }
) {
  const existing = items.findIndex(
    (item) =>
      item.product.productId === add.product.productId &&
      item.printfulVariant.id === add.printfulVariant.id &&
      item.fit.fitStyle === add.fit.fitStyle
  );

  if (existing === -1) {
    // adding the first item(s) to the cart
    items.push({
      count: add.count,
      product: add.product,
      printfulVariant: add.printfulVariant,
      fit: add.fit
    });
  } else {
    // item already exists, so we increase the count and update products to the latest
    items[existing]!.count += add.count;
    (items[existing]!.product = add.product),
      (items[existing]!.printfulVariant = add.printfulVariant);
    items[existing]!.fit = add.fit;
  }

  if (options.track) {
    trackEvent({
      event: "Added to Cart",
      properties: {
        artwork_title: add.product.title,
        artist_store: add.product.artistName,

        //note: cart no longer contains title or artist name
        artwork_id: add.product.productId,
        // TODO: meaningful name for variant
        variant: `${add.printfulVariant.id}, ${add.printfulVariant.name}`,
        fit: add.fit.fitStyle
      }
    });
  }
}

export const useCart = create<CartState>((set) => ({
  loading: true,
  items: [],
  addItem(add) {
    set((state) => {
      const itemsClone = cloneDeep(state.items);
      mutativeAddItem(itemsClone, add, { track: true });
      return { items: itemsClone };
    });
  },
  removeItem(remove) {
    set((state) => {
      const itemsClone = cloneDeep(state.items);

      const existing = itemsClone.findIndex(
        (item) =>
          item.product.productId === remove.product.productId &&
          item.printfulVariant.id === remove.printfulVariant.id &&
          item.fit.fitStyle === remove.fit.fitStyle
      );

      if (existing === -1) {
        // can't find, so nothing to remove.
        // TODO: return/log error? for now noop
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const found = itemsClone[existing]!;

        if (remove.removeCount === "all" || remove.removeCount >= found.count) {
          // entirely remove
          itemsClone.splice(existing, 1);
        } else {
          // decrease count
          found.count -= remove.removeCount;
        }
      }

      return { items: itemsClone };
    });
  },
  clearCart(options) {
    set((state) => ({
      items: [],
      loading: options?.resetLoading ? true : state.loading
    }));
  },
  loadWith(items) {
    set((state) => {
      const itemsClone = cloneDeep(state.items);
      items.forEach((item) =>
        // we don't want to track adding to cart this way, since it's preexisting
        mutativeAddItem(itemsClone, item, { track: false })
      );
      return {
        items: itemsClone,
        // we are now loaded
        loading: false
      };
    });
  }
}));

export function cartTotalCount(cart: CartState): number {
  return cart.items.reduce((accumulator, item) => accumulator + item.count, 0);
}

export function countForProductVariant(
  cart: CartState,
  product: ProductDocument,
  printfulVariant: PrintfulApiVariant,
  fit: FitStyle
): number {
  return (
    cart.items.find(
      (element) =>
        element.product.productId === product.productId &&
        element.printfulVariant.id === printfulVariant.id &&
        element.fit.fitStyle === fit.fitStyle
    )?.count || 0
  );
}
