import { z } from "zod";

export const PrintfulApiProduct = z.object({
  avg_fulfillment_time: z.number().nullable(),
  description: z.string(),
  files: z.any(), // TODO
  id: z.number(),
  type: z.string(),
  type_name: z.string(),
  title: z.string(),
  brand: z.string().nullable(),
  currency: z.string(),
  dimensions: z.any().nullable(), // TODO
  is_discontinued: z.boolean(),
  main_category_id: z.number(),
  model: z.string(),
  options: z.array(z.any()), // TODO
  variant_count: z.number(),
  techniques: z.array(z.any()), // TODO
  origin_county: z.string().nullable().optional(),
  image: z.string()
});

export type PrintfulApiProduct = z.infer<typeof PrintfulApiProduct>;

export const PrintfulCategoriesDocument = z.object({
  updatedAt: z.date(),
  products: z.array(PrintfulApiProduct),
  categories: z.array(z.number()),
  conceptualCategory: z.string(),
  excluded: z.array(
    z.object({ productId: z.number(), title: z.string(), reason: z.string() })
  )
});

export type PrintfulCategoriesDocument = z.infer<
  typeof PrintfulCategoriesDocument
>;

export const PrintfulApiVariant = z.object({
  /**
   * The printful variant ID. This is the primary key for buying something.
   */
  id: z.number(),
  product_id: z.number(),
  name: z.string(),
  availability_regions: z.record(z.string()),
  availability_status: z.array(z.any()), // FIXME: important
  color: z.string().nullable(),
  color_code: z.any().nullable(), // TODO
  color_code2: z.any().nullable(), // TODO
  image: z.string().optional(),
  in_stock: z.boolean(),
  material: z.array(z.any()), // TODO
  /**
   * Price is in dollars.cents, e.g., 8.50
   */
  price: z.string(),
  size: z.string()
});

export type PrintfulApiVariant = z.infer<typeof PrintfulApiVariant>;

export const PrintfulProductDocument = z.object({
  product: PrintfulApiProduct,
  variants: z.array(PrintfulApiVariant),
  updatedAt: z.date()
});

export type PrintfulProductDocument = z.infer<typeof PrintfulProductDocument>;

export const PrintfulVariantDocument = z.object({
  updatedAt: z.date(),
  printfulProductId: z.number(),
  printfulVariant: PrintfulApiVariant
});

export const PrintfulCostsDetails = z.object({
  currency: z.literal("USD"),
  subtotal: z.number(),
  discount: z.number(),
  shipping: z.number(),
  digitization: z.number(),
  additional_fee: z.number(),
  fulfillment_fee: z.number(),
  retail_delivery_fee: z.number().optional(),
  tax: z.number(),
  vat: z.number(),
  total: z.number()
});

export type PrintfulCostsDetails = z.infer<typeof PrintfulCostsDetails>;

export const PrintfulShippingDetails = z.object({
  id: z.string(),
  name: z.string()
});

export const PrintfulStatus = z.union([
  z.literal("draft"),
  z.literal("pending"),
  z.literal("failed"),
  z.literal("canceled"),
  z.literal("inprocess"),
  z.literal("onhold"),
  z.literal("partial"),
  z.literal("fulfilled"),
  z.literal("archived")
]);

export type PrintfulShippingDetails = z.infer<typeof PrintfulShippingDetails>;

export const PrintfulOrderData = z
  .object({
    external_id: z.string(),
    status: PrintfulStatus,
    items: z.array(
      z
        .object({
          /**
           * Line item ID internal to printful
           */
          id: z.number(),
          /**
           * Our line item ID
           */
          external_id: z.string()
        })
        .passthrough()
    )
  })
  .passthrough(); // allow unspecified properties

const PrintfulShipment = z.object({
  id: z.number(),
  carrier: z.string(),
  service: z.string(),
  tracking_number: z.string(),
  tracking_url: z.string(),
  created: z.number(),
  ship_date: z.string(),
  /**
   * Ship time in unix timestamp
   *
   * spec says string, but simulator is giving a number
   */
  shipped_at: z.string().or(z.number()),
  reshipment: z.boolean(),
  items: z.array(
    z.object({
      /**
       * Printful's line item ID (not ours)
       *
       * spec says string, but simulator and past data is giving a number.
       */
      item_id: z.number(),
      quantity: z.number(),
      picked: z.number(),
      printed: z.number()
    })
  )
});

// TODO: support all types
export const PrintfulEvent = z
  .object({
    created: z.preprocess(
      // timestamp in seconds since unix epoch
      (arg, _ctx) => new Date((arg as number) * 1000),
      z.date()
    ),
    retries: z.number(),
    store: z.number()
  })
  .and(
    z
      .object({
        type: z
          .literal("order_updated")
          .or(z.literal("order_created"))
          .or(z.literal("order_refunded")),

        data: z.object({
          order: PrintfulOrderData
        })
      })
      .or(
        z.object({
          type: z.literal("stock_updated"),

          data: z.object({
            product_id: z.number(),
            variant_stock: z.any()
          })
        })
      )
      .or(
        z.object({
          type: z.literal("package_shipped"),
          data: z.object({
            shipment: PrintfulShipment,
            order: PrintfulOrderData
          })
        })
      )
  );

export type PrintfulEvent = z.infer<typeof PrintfulEvent>;
