import { OpenInNew, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Icon,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AssetsResolver from "../../AssetsResolver";

import { ProductDocument } from "shared/models/product-models";
import "./ProductCard.css";

export interface CustomCardAction {
  onClick: (product: ProductDocument) => void;
  text: string;
}

export default function ProductCard({
  product,
  customCardAction
}: {
  product: ProductDocument | null;
  customCardAction?: CustomCardAction;
}) {
  const navigate = useNavigate();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      elevation={16}
      className={`product-card`}
      onClick={() =>
        product &&
        (product.slug
          ? navigate(`/artwork/${product.slug}`)
          : navigate(`/artwork/${product.productId}`))
      }
      sx={{
        position: "relative",
        // backgroundColor: "aliceblue",
        display: "flex",
        flexDirection: "column",
        "&:hover .display-when-product-card-hovered": {
          display: "block"
        },
        "&:hover .hide-when-product-card-hovered": {
          display: "none"
        },
        ...(!smallScreen && {
          "&:hover": {
            transform: "scale(1.05)", //1.35 alternatively
            zIndex: 5,
            boxShadow:
              "0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)"
          }
        }),
        height: "100%"
      }}
    >
      {product && !product.live && (
        <Tooltip title="artwork is not live yet, complete store setup">
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              background: "rgba(0,0,0,0.7)",
              p: 1,
              pb: 0,
              br: 10,
              zIndex: 20
            }}
          >
            <VisibilityOff color="error" />
          </Box>
        </Tooltip>
      )}

      <Box
        sx={{
          display: "grid",
          maxWidth: "100%"
        }}
      >
        <Skeleton
          sx={{
            width: "100%",
            height: "auto",

            maxHeight: "200px",
            aspectRatio: "1/1",
            transform: "none",
            borderRadius: 0,
            gridArea: "1/1",
            zIndex: 5
          }}
        />

        {product && (
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "200px",
              aspectRatio: "1/1",
              gridArea: "1/1",
              zIndex: 6
            }}
            image={AssetsResolver.imageUrl(product.image.path, {
              width: 500,
              height: 500,
              aspect_ratio: "1:1"
            })}
            alt={product.altText || ""}
          />
        )}

        {product && (
          <Box
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "200px",
              aspectRatio: "1/1",
              gridArea: "1/1",
              zIndex: 7,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <Paper sx={{ m: 0.5, pl: 0.5, pr: 0.5, borderRadius: 2 }}>
              <Typography sx={{ fontFamily: "Droid Seriff" }} variant="body2">
                ${product.price}
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: 1,
          position: "relative"
        }}
      >
        <Box sx={{ position: "fixed", top: 2, right: 2 }}>
          {!smallScreen && (
            <>
              <OpenInNew
                className="display-when-product-card-hovered"
                sx={{
                  ml: "auto",
                  display: "none",
                  zIndex: 20
                }}
              />

              <Icon
                className="hide-when-product-card-hovered"
                sx={{
                  ml: "auto"
                }}
              />
            </>
          )}
        </Box>

        <Box mt="auto" />

        <Typography
          textAlign="center"
          justifyContent={"center"}
          sx={{
            fontFamily: '"EB Garamond", serif',
            fontWeight: "bold",
            fontSize: { xs: 14, sm: 17 }
          }}
        >
          {product ? product.title : <Skeleton variant="text" />}
        </Typography>

        <Box mt="auto" />

        <Divider sx={{ mt: 0.5, mb: 0.5, backgroundColor: "white" }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%"
          }}
        >
          {!customCardAction && (
            <Typography
              textAlign="center"
              variant="body2"
              color="text.secondary"
            >
              {product ? (
                <i>by {product.artistName}</i>
              ) : (
                <Skeleton variant="text" sx={{ width: "3em" }} />
              )}
            </Typography>
          )}

          {customCardAction && product && (
            <Button
              onClick={(event) => {
                customCardAction.onClick(product);

                event.stopPropagation();
              }}
              size="small"
            >
              {customCardAction.text}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
}
