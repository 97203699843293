import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material";
import { useDialogState } from "../../zustand/imperative-dialog";
import DialogTitleWithClose from "./DialogTitleWithClose";
import SupportEmail from "./SupportEmail";
import { useEffect, useState } from "react";

export default function ImperativeDialog() {
  const { current, closeCurrent } = useDialogState();

  const open = current !== null;
  const onClose = () => {
    closeCurrent();
  };

  const title = current?.type === "error" ? "Error" : current?.title;

  let content;

  switch (current?.type) {
    case "info":
      content = current.content;
      break;
    case "error":
      content = (
        <>
          <Typography paragraph>
            Error encountered: {current.message}.
          </Typography>

          <Typography>
            Please contact support at <SupportEmail />
          </Typography>
        </>
      );
  }

  const [errorExpanded, setErrorExpanded] = useState(false);

  function unknownToString(u: unknown): string {
    if (typeof u === "object" && u !== null) {
      return JSON.stringify(u);
    } else if (typeof u === "string") {
      return u;
    } else {
      return "unknown";
    }
  }

  useEffect(() => {
    if (current?.type === "error") {
      console.error(current.message, current.error);
    }
  }, [current]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitleWithClose onClose={onClose}>{title}</DialogTitleWithClose>
      <DialogContent>
        {content}

        {current?.type === "error" && current.error ? (
          <Button
            sx={{ mt: 3 }}
            onClick={() => setErrorExpanded((curr) => !curr)}
          >
            Error Details ...
          </Button>
        ) : null}

        {current?.type === "error" && current.error ? (
          <Collapse
            in={errorExpanded}
            sx={{ backgroundColor: "secondary.main" }}
          >
            <Box sx={{ p: 3, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              <Typography paragraph>
                {current?.type === "error" && current.error
                  ? unknownToString(current.error)
                  : null}
              </Typography>

              {current?.type === "error" &&
                typeof current.error === "object" &&
                current.error !== null &&
                "message" in current.error &&
                typeof current.error.message === "string" && (
                  <Typography>message: {current.error.message}</Typography>
                )}
            </Box>
          </Collapse>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}
