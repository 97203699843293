import { z } from "zod";

const TaxTypeSchema = z.union([
  z.literal("gst"),
  z.literal("hst"),
  z.literal("igst"),
  z.literal("jct"),
  z.literal("lease_tax"),
  z.literal("pst"),
  z.literal("qst"),
  z.literal("rst"),
  z.literal("sales_tax"),
  z.literal("vat"),
  z.literal("amusement_tax"),
  z.literal("communications_tax")
]);

const TaxRateDetailsSchema = z.object({
  /**
   * Two-letter country code ([ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)).
   */
  country: z.union([z.string(), z.null()]),

  /**
   * The tax rate percentage as a string. For example, 8.5% is represented as "8.5".
   */
  percentage_decimal: z.string(),

  /**
   * State, county, province, or region.
   */
  state: z.union([z.string(), z.null()]),

  /**
   * The tax type, such as vat or sales_tax.
   */
  tax_type: z.union([TaxTypeSchema, z.null()])
});

const TaxabilityReasonSchema = z.union([
  z.literal("customer_exempt"),
  z.literal("not_collecting"),
  z.literal("not_subject_to_tax"),
  z.literal("not_supported"),
  z.literal("portion_product_exempt"),
  z.literal("portion_reduced_rated"),
  z.literal("portion_standard_rated"),
  z.literal("product_exempt"),
  z.literal("product_exempt_holiday"),
  z.literal("proportionally_rated"),
  z.literal("reduced_rated"),
  z.literal("reverse_charge"),
  z.literal("standard_rated"),
  z.literal("taxable_basis_reduced"),
  z.literal("zero_rated")
]);

export const TaxBreakdown = z.object({
  /**
   * The amount of tax, in integer cents.
   */
  amount: z.number(),

  /**
   * Specifies whether the tax amount is included in the line item amount.
   */
  inclusive: z.boolean(),

  tax_rate_details: TaxRateDetailsSchema,

  /**
   * The reasoning behind this tax, for example, if the product is tax exempt. We might extend the possible values for this field to support new tax rules.
   */
  taxability_reason: TaxabilityReasonSchema,

  /**
   * The amount on which tax is calculated, in integer cents.
   */
  taxable_amount: z.number()
});

export type TaxBreakdown = z.infer<typeof TaxBreakdown>;
