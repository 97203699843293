import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

function callFunctionUntyped(functionName: string, data?: unknown) {
  const f = httpsCallable(functions, functionName);
  return f(data).then((response) => response.data);
}

/**
 * This function generates a one-time link to a user's express dashboard
 *
 * @see https://stripe.com/docs/connect/integrate-express-dashboard
 */
export async function createStripeConnectLoginLink(): Promise<string> {
  interface ExpectedReturn {
    link: string;
  }

  const data = (await callFunctionUntyped(
    "createStripeConnectLoginLink"
  )) as ExpectedReturn;

  return data.link;
}

interface SendEmailData {
  to: string[];
  from: { email: string; name: string };
  subject: string;
  content: { text: string } | { text: string; html: string };
}

export async function sendEmail(data: SendEmailData): Promise<void> {
  await callFunctionUntyped("sendEmail", data);
}
