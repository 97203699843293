import { Alert, Box, Button, FormHelperText, InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { useMemo, useRef } from "react";
import { useDeviceSelectors } from "react-device-detect";

import {
  validateCoverImageDPI,
  validateProfilePictureDPI
} from "shared/tools/dpi";

import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { RefinementCtx, z } from "zod";
import StyledDropzone from "../helpers/StyledDropzone";

export function superRefineImageValue({
  enforceDefined,
  type
}: {
  enforceDefined: boolean;
  type: "coverImage" | "profilePicture" | "product";
}): (img: ImageValue | undefined, ctx: RefinementCtx) => void {
  return (img, ctx) => {
    if (img?.type === "new") {
      switch (type) {
        case "coverImage": {
          const validationResult = validateCoverImageDPI({
            dimensions: img.imageObject,
            cropDimensions: img.cropImageObject
          });

          validationResult.status === "error" &&
            ctx.addIssue({
              code: "custom",
              message: validationResult.error
            });

          return;
        }

        case "profilePicture": {
          const validationResult = validateProfilePictureDPI({
            dimensions: img.imageObject,
            cropDimensions: img.cropImageObject
          });

          validationResult.status === "error" &&
            ctx.addIssue({
              code: "custom",
              message: validationResult.error
            });

          return;
        }

        case "product": {
          const width = img.imageObject.naturalWidth;
          const height = img.imageObject.naturalHeight;

          const longerDimension = Math.max(width, height);
          const shorterDimension = Math.min(width, height);

          const shorterBound = 2000;
          const longerBound = 2500;

          const minAspectRatio = 0.6;

          if (shorterDimension === 0 || longerDimension === 0) {
            ctx.addIssue({
              code: "custom",
              message: `One of your dimensions is 0px, cannot process image.`
            });
          } else if (shorterDimension / longerDimension < minAspectRatio) {
            ctx.addIssue({
              code: "custom",
              message: `Image aspect ratio (${(
                shorterDimension / longerDimension
              ).toFixed(
                2
              )}) is too sharp, we need images close to print size. Minimum aspect ratio is 0.6`
            });
          } else if (
            longerDimension < longerBound &&
            shorterDimension < shorterBound
          ) {
            ctx.addIssue({
              code: "custom",
              message: `Image size (of ${width}px/${height}px) is too low, must be at least ${shorterBound}px/${longerBound}px`
            });
          } else if (longerDimension < longerBound) {
            ctx.addIssue({
              code: "custom",
              message: `Image's longer length (of ${longerDimension}px) is too low, must be at least ${longerBound}px`
            });
          } else if (shorterDimension < shorterBound) {
            ctx.addIssue({
              code: "custom",
              message: `Image's shorter length (of ${shorterDimension}px) is too low, must be at least ${shorterBound}px`
            });
          }
        }
      }
    } else if (enforceDefined && !img) {
      ctx.addIssue({ code: "custom", message: "You must upload an image" });
    }
  };
}

export const ImageValueSchema = z.union([
  z.object({
    type: z.literal("existing"),
    url: z.string()
  }),
  z.object({
    type: z.literal("new"),
    dataUrl: z.string(),
    crop: z.custom<Crop>().optional(),
    imageObject: z.custom<HTMLImageElement>(
      (v) => v instanceof HTMLImageElement
    ),
    cropDataUrl: z.string().optional(),
    cropImageObject: z
      .custom<HTMLImageElement>((v) => v instanceof HTMLImageElement)
      .optional()
  })
]);

export type ImageValue = z.infer<typeof ImageValueSchema>;

export async function generateImageValue(
  imageData: string,
  cropProp?: CropProp
): Promise<ImageValue> {
  const image = new window.Image();
  image.src = imageData;

  await image.decode();

  let crop = undefined;

  let cropImage = undefined;
  let cropDataUrl = undefined;

  if (cropProp) {
    crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 100
        },
        cropProp.aspect,
        image.width,
        image.height
      ),
      image.width,
      image.height
    );

    cropDataUrl = await getCroppedImg(imageData, crop);

    cropImage = new window.Image();
    cropImage.src = cropDataUrl;

    await cropImage.decode();
  }

  return {
    type: "new",
    dataUrl: imageData,
    crop: crop,
    imageObject: image,
    cropDataUrl: cropDataUrl,
    cropImageObject: cropImage
  };
}

export interface CropProp {
  circular: boolean;
  aspect: number;
  minWidth?: number;
}

interface FormikImageFieldProps {
  name: string;
  label: string;
  placeholderText: React.ReactNode;
  crop?: CropProp;
  hideMessage?: boolean;
  style?: React.CSSProperties;
}

async function getCroppedImg(dataUrl: string, crop: Crop) {
  const image = new window.Image();
  image.src = dataUrl;

  await image.decode();

  const canvas = document.createElement("canvas");
  //const pixelRatio = window.devicePixelRatio;
  // const scaleX = image.naturalWidth / image.width;
  // const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");

  canvas.width = (crop.width / 100) * image.width; //crop.width * pixelRatio * image.width;
  canvas.height = (crop.height / 100) * image.height; //crop.height * pixelRatio * image.height;

  //ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

  if (ctx) {
    ctx.imageSmoothingQuality = "high";
  }

  ctx?.drawImage(
    image, // image to draw
    (crop.x / 100) * image.width, // source x to start at
    (crop.y / 100) * image.height, // source y to start at
    (crop.width / 100) * image.width, // source width to take
    (crop.height / 100) * image.height, // source height to take
    0, // destination x
    0, // destination y
    (crop.width / 100) * image.width, // destination width
    (crop.height / 100) * image.height // destination height
  );

  return canvas.toDataURL("image/jpeg");
}

/**
 * This is an image field, that displays the image after upload
 */
export default function FormikImageField(props: FormikImageFieldProps) {
  const formikContext =
    useFormikContext<Record<string, ImageValue | undefined>>();

  const onDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        const data = reader.result as string;

        const newValue = await generateImageValue(data, props.crop);
        formikContext.setFieldValue(props.name, newValue);
      };
      reader.readAsDataURL(file);
    });
  };

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const cameraInput = useRef<HTMLInputElement | null>(null);

  // Intended to be called after crop change is complete
  async function processCrop(crop: PercentCrop) {
    if (imageValue && imageValue.type === "new" && imageValue.crop) {
      const cropDataUrl = await getCroppedImg(imageValue.dataUrl, crop);

      const cropImage = new window.Image();
      cropImage.src = cropDataUrl;

      await cropImage.decode();

      const newValue: ImageValue = {
        ...imageValue,
        crop: crop,
        cropDataUrl: cropDataUrl,
        cropImageObject: cropImage
      };

      formikContext.setFieldValue(props.name, newValue);
    }
  }

  const imageValue = formikContext.values[props.name];

  const initialImageValue = useMemo(() => imageValue, []);

  const hasError = formikContext.errors[props.name];
  const touched = formikContext.touched[props.name];
  // todo: see if we can make this capture focus
  const isFocused = false;

  return (
    <>
      {imageValue && imageValue.type === "new" && (
        <Box
          sx={{
            mb: 3,
            p: 1,
            border: 1,
            borderRadius: 1,
            borderColor: hasError
              ? "error.main"
              : isFocused
              ? "primary.main"
              : "grey.400",
            position: "relative",
            backgroundColor: "#fff",
            "&:hover": {
              borderColor: "text.primary",
              cursor: "pointer"
            },
            maxWidth: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
              maxWidth: "100%"
            }}
          >
            {props.crop ? (
              <ReactCrop
                onComplete={(crop, percentCrop) => processCrop(percentCrop)}
                circularCrop={props.crop?.circular}
                minWidth={props.crop.minWidth}
                keepSelection
                style={{ maxWidth: "100%", maxHeight: "500px", ...props.style }}
                aspect={props.crop.aspect}
                crop={imageValue.crop}
                onChange={(crop, percentCrop) => {
                  formikContext.setFieldValue(props.name, {
                    ...imageValue,
                    crop: percentCrop
                  });
                }}
              >
                <img style={{ width: "100%" }} src={imageValue.dataUrl} />
              </ReactCrop>
            ) : (
              <img
                style={{ maxHeight: "500px", maxWidth: "100%" }}
                src={imageValue.dataUrl}
              />
            )}
          </div>
          {hasError && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    formikContext.setFieldValue(props.name, initialImageValue);
                    setTimeout(() => formikContext.setFieldTouched(props.name));
                  }}
                >
                  change image
                </Button>
              }
              icon={false}
            >
              {hasError}
            </Alert>
          )}

          {!hasError && (
            <Alert
              icon={false}
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    formikContext.setFieldValue(props.name, initialImageValue);
                    setTimeout(() => formikContext.setFieldTouched(props.name));
                  }}
                >
                  Change Image
                </Button>
              }
            >
              {props.crop
                ? `Move the ${
                    props.crop.circular ? "circle" : "box"
                  } above to change the ${props.label.toLowerCase()} cropping`
                : `Great image!`}
            </Alert>
          )}
          <InputLabel
            sx={{
              // position the label so it occludes the border, just like TextField labels
              position: "absolute",
              // top: "-13px",
              // left: "15px",
              top: 0,
              left: 0,
              transform: "translate(14px, -9px) scale(0.75)",
              // backgroundColor: props.backgroundColor || "white"
              backgroundColor: "white"
            }}
          >
            {props.label}
          </InputLabel>
        </Box>
      )}

      {(imageValue === undefined || imageValue.type === "existing") && (
        <>
          <Box mb={3}>
            <StyledDropzone
              label={props.label}
              name="image"
              placeholderImage={
                imageValue && (
                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "min(500px, 100%)",
                      maxHeight: "200px"
                    }}
                    component="img"
                    src={imageValue.url}
                  />
                )
              }
              placeholderText={props.placeholderText}
              onDrop={(files) => onDrop(files)}
              multiple={false}
            />
            {}
            {touched && (
              <FormHelperText error={Boolean(hasError)}>
                {hasError}
              </FormHelperText>
            )}
          </Box>

          {/* TODO: reconsider enabling mobile capture */}
          {/* {isMobile && ( // allow selecting with the camera on mobile
            <>
              <Box mb={3}>
                <div className="alert alert-info">
                  <span>
                    Or capture the {props.label.toLowerCase()} using your camera
                    by clicking{" "}
                    <a
                      href="#"
                      onClick={() => {
                        cameraInput.current?.click();
                      }}
                    >
                      here
                    </a>
                  </span>
                </div>
              </Box>
              <input
                style={{ display: "none" }}
                ref={cameraInput}
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    const img = event.target.files[0];

                    onDrop([img]);
                  }
                }}
              />
            </>
          )} */}
        </>
      )}
    </>
  );
}
