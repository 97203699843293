import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import trackEvent from "../helpers/plausible";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  useEffect(() => {
    trackEvent({
      event: "404",
      properties: { path: document.location.pathname }
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        m: 3,
        mt: 10,
        mb: 10,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 3
      }}
    >
      <Typography variant="h4">Page not found (try logging in)</Typography>

      <Link to="/artwork">
        <Button variant="contained">Return to Gallery</Button>
      </Link>
    </Box>
  );
}
