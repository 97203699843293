import { useParams } from "react-router-dom";
import DefaultNonprofitPage from "./DefaultNonprofitPage";
import MentalHealthAmerica from "./MentalHealthAmerica";

const customPages: Record<string, React.ReactNode> = {
  "mental-health-america": <MentalHealthAmerica />
};

export default function NonprofitRouter() {
  const { slug } = useParams();

  if (slug && customPages[slug]) {
    const node = customPages[slug];
    return <>{node}</>;
  } else {
    // TODO: add a case for no slug?
    return <DefaultNonprofitPage slug={slug!} />;
  }
}
