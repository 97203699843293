import { useEffect, useMemo } from "react";
import { ProductDocument } from "shared/models/product-models";
import { z } from "zod";
import { CartState, useCart } from "../../zustand/cart-state";
import { useDocuments, useFetchProducts } from "../../zustand/documents";
import { PrintfulApiVariant } from "shared/models/printful-models";
import { FitStyle } from "shared/models/order-models";

const LocalStorageKey = "shoppingCart";

const ThinShoppingCartItem = z.object({
  product: z.object({
    id: z.string(),
    version: z.number(),
    price: z.string(),
    imagePath: z.string()
  }),
  count: z.number(),
  printfulVariant: z.object({
    id: z.number(),
    productId: z.number(),
    price: z.string()
  }),
  fit: z.object({
    fitStyle:z.union([z.literal("center_crop"),z.literal("margin")])
    })
});

type ThinShoppingCartItem = z.infer<typeof ThinShoppingCartItem>;

// TODO: store date when cart was added
const StoredCart = z.object({
  thinItems: z.array(ThinShoppingCartItem)
});

type StoredCart = z.infer<typeof StoredCart>;

function storeShoppingCart(state: CartState) {
  const toSet: StoredCart = {
    thinItems: state.items.map(
      (fatItem) =>
        ({
          count: fatItem.count,
          product: {
            id: fatItem.product.productId,
            imagePath: fatItem.product.image.path,
            price: fatItem.product.price,
            version: fatItem.product.version
          },
          printfulVariant: {
            id: fatItem.printfulVariant.id,
            price: fatItem.printfulVariant.price,
            productId: fatItem.printfulVariant.product_id
          },
          fit: fatItem.fit
        } satisfies ThinShoppingCartItem)
    )
  };

  localStorage.setItem(LocalStorageKey, JSON.stringify(toSet));
}

/**
 * Fetches the state from local storage (for calculating inital state)
 *
 * Note: if this gets more complex, look into https://github.com/rt2zz/redux-persist
 */
function loadShoppingCart(): StoredCart | null {
  try {
    const stored = localStorage.getItem(LocalStorageKey);
    if (stored) {
      const rawObject = JSON.parse(stored);

      const parseResult = StoredCart.safeParse(rawObject);

      if (!parseResult.success) {
        console.warn(
          "Old format shopping cart in local storage, ignoring",
          parseResult.error
        );
        return null;
      } else {
        return parseResult.data;
      }
    } else {
      return null;
    }
  } catch (e) {
    console.error("Failed to load cart state", e);
    return null;
  }
}
/**
 * This component manages the cart by providing long term persistence
 * and dealing with any cart changes due to version etc.
 */
export default function CartManager() {
  const products = useDocuments((state) => state.products);

  const shoppingCart = useCart();

  const storedCart = useMemo(() => loadShoppingCart(), []);

  const printfulProducts = useDocuments(
    (state) => state.collections.printfulProducts
  );

  const fetchDocument = useDocuments((state) => state.fetchDocument);

  // fetch all the products we care about
  useFetchProducts(storedCart?.thinItems.map((item) => item.product.id) || []);

  useEffect(() => {
    // fetch all printful products we have represented in the cart,
    // so we can get their variants
    storedCart?.thinItems.forEach((item) =>
      fetchDocument(
        item.printfulVariant.productId.toString(),
        "printfulProducts"
      )
    );
  });

  useEffect(() => {
    if (!shoppingCart.loading) {
      // important to only save after loading
      storeShoppingCart(shoppingCart);
    }
  }, [shoppingCart]);

  useEffect(() => {
    const cartState = useCart.getState();

    if (storedCart && cartState.loading) {
      // we're still loading
      const stillLoading = storedCart.thinItems.some((item) => {
        const product = products[item.product.id];
        const printfulProduct =
          printfulProducts[item.printfulVariant.productId];

        return (
          product?.state === "loading" ||
          product === undefined ||
          printfulProduct?.state === "loading" ||
          printfulProduct === undefined
        );
      });

      if (stillLoading) {
        // TODO: have timeout
        null;
      } else {
        console.debug("Needed info loaded for populating cart");

        // we're all loaded!
        const productsToAdd: {
          product: ProductDocument;
          count: number;
          printfulVariant: PrintfulApiVariant;
          fit: FitStyle
        }[] = [];

        for (const thinItem of storedCart.thinItems) {
          const productState = products[thinItem.product.id];

          const printfulProductState =
            printfulProducts[thinItem.printfulVariant.productId];

          if (!productState || productState.state == "loading") {
            // unexpected state due to our loading check
            console.error(
              "Product in unexpected state when loading cart",
              productState
            );
          } else if (productState.state === "error") {
            // failed to load product. Silently drop from cart with only console error
            // TODO: surface info to use that cart has changed
            console.error(
              `Product (id=${thinItem.product.id}) in stored cart could not be loaded`,
              productState
            );
          } else if (
            !printfulProductState ||
            printfulProductState.state == "loading"
          ) {
            // unexpected state due to our loading check
            console.error(
              `Printful Product (id=${thinItem.printfulVariant.productId}) in unexpected state when loading cart`,
              printfulProductState
            );
          } else if (printfulProductState.state === "error") {
            // failed to load product. Silently drop from cart with only console error
            // TODO: surface info to use that cart has changed
            console.error(
              `Printful Product (id=${thinItem.printfulVariant.productId}) in stored cart could not be loaded`,
              printfulProductState
            );
          } else {
            // product is loaded!
            const productToAdd = productState.document;

            const variant = printfulProductState.document.variants.find(
              (v) => v.id === thinItem.printfulVariant.id
            );

            if (!variant) {
              console.error(
                `"Product (id=${thinItem.product.id}) in stored cart with variant that cannot be found (variant id =${thinItem.printfulVariant.id})"`
              );
            } else {
              productsToAdd.push({
                count: thinItem.count,
                product: productToAdd,
                printfulVariant: variant,
                fit: thinItem.fit
              });
            }
          }
        }

        // FIXME: warn on version mismatch for product or variant
        // likely by adding failues to a list during above loop that is
        // surfaced to user now
        cartState.loadWith(productsToAdd);
      }
    } else if (storedCart === null && cartState.loading) {
      console.log("No cart to load, finalizing cart.");
      cartState.loadWith([]);
    }
  }, [products, storedCart, printfulProducts]);

  return null;
}
