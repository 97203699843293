import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@mui/material";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { Form, Formik, FormikErrors } from "formik";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { sendEmail } from "../../functions/cloudFunctions";
import FormikField from "../formik/FormikField";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

import { DoneAll, Edit, Pending } from "@mui/icons-material";
import { AllCollections } from "shared/models/collections";
import { InviteDocument } from "shared/models/registration-models";
import { ArtistUser, UserDocument } from "shared/models/user-models";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { converters } from "../../helpers/firebase-helpers";
import { useArtists, useCollectionDataCustom } from "../../helpers/queries";
import { dialog } from "../../zustand/imperative-dialog";
import InviteUserDialog from "./InviteUserDialog";
import Loading from "react-loading";
import { ArtistDocument } from "shared/models/artist-models";
import ChangeStoreNameDialog from "./ChangeStoreNameDialog";
import slugify from "shared/tools/slugify";
import LoadingButton from "../helpers/LoadingButton";

interface SendEmailForm {
  subject: string;
  text: string;
}

function isUser(row: UserDocument | InviteDocument): row is UserDocument {
  return "userId" in row;
}

function validateSendEmailForm(values: SendEmailForm) {
  const errors: FormikErrors<SendEmailForm> = {};

  if (!values.subject) {
    errors.subject = "Required";
  }

  if (!values.text) {
    errors.text = "Required";
  }

  return errors;
}

type UserStatus = "invited" | "completing setup" | "complete";

interface UserTableProps {
  readOnly: boolean;
}

export function UsersTable({ readOnly }: UserTableProps) {
  type DecoratedArtistUser =
    | (UserDocument & (ArtistDocument & { artistLoaded: true }))
    | (UserDocument & { artistLoaded: false });

  type UserTableRow = DecoratedArtistUser | InviteDocument;

  const [artistDocuments] = useArtists();

  const userTableColumns: MRT_ColumnDef<UserTableRow>[] = useMemo(
    () => [
      {
        accessorFn: (user) => {
          if ("accepted" in user) {
            // user is an unaccepted invite document
            return "invited";
          } else if (user.isArtist && !user.setupCompletedOn) {
            // user has accepted invite, but has not completed setup
            return "completing setup";
          } else {
            // user is fully onboarded
            return "complete";
          }
        },
        filterVariant: "multi-select",
        filterSelectOptions: [
          "invited",
          "completing setup",
          "complete"
        ] as UserStatus[],
        header: "Status",
        id: "status",
        Cell(args) {
          const status = args.cell.getValue() as UserStatus;

          switch (status) {
            case "invited":
              return (
                <Tooltip title={status}>
                  <Pending />
                </Tooltip>
              );
            case "completing setup":
              return (
                <Tooltip title={status}>
                  <Edit />
                </Tooltip>
              );
            case "complete":
              return (
                <Tooltip title={status}>
                  <DoneAll />
                </Tooltip>
              );
          }
        }
      },
      {
        accessorKey: "storeName",
        header: "Store Name",
        Cell(args) {
          const storeName = args.cell.getValue() as string;

          if (storeName) {
            return (
              <Link
                target="_blank"
                rel="noopener"
                href={`/artists/${slugify(storeName)}`}
              >
                {storeName}
              </Link>
            );
          } else {
            return "";
          }
        }
      },
      {
        accessorFn: (row) => {
          if (!isUser(row) || !row.isArtist) {
            return "";
          } else {
            if (row.artistLoaded && row.live) {
              return "live";
            } else if (row.artistLoaded && !row.live) {
              return "not live";
            } else {
              return "loading";
            }
          }
        },
        id: "visibility",
        header: "Visibility"
      },
      {
        accessorFn: (user) =>
          user.name ? `${user.name.first} ${user.name.last}` : "unknown",
        header: "Name",
        id: "name"
      },
      {
        accessorFn: (user) => user.email || "unknown",
        header: "Email"
      },
      {
        accessorKey: "roles",
        header: "Roles",
        filterVariant: "multi-select",
        filterSelectOptions: ["artist", "admin"],
        Cell(args) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const roles = args.cell.getValue() as any;
          const sortedRoles = [...roles].sort();
          return (
            <Box display="flex" columnGap={1}>
              {sortedRoles.map((role) => (
                <Chip key={role} variant="filled" label={role} />
              ))}
            </Box>
          );
        }
      }
    ],
    [artistDocuments]
  );

  // index => true for all selected rows. Index is stringified though
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const [changingStoreNameFor, setChangingStoreNameFor] = useState<
    ArtistUser | undefined
  >();

  const [moderateDialogOpen, setModerateDialogOpen] = useState(false);

  const [moderateRadioValue, setModerateRadioValue] = useState("Live");

  const [moderationActionLoading, setModerationActionLoading] = useState(false);

  // TODO: leverage error state here
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users = [], usersLoading, error] = useCollectionDataCustom(
    query(
      collection(getFirestore(), AllCollections.users.name).withConverter(
        converters.usersConverter
      )
      //where("artist", "==", user?.uid),
      //orderBy("publishedAt", "desc")
    )
  );

  const [invites = [], invitesLoading, invitesError] = useCollectionDataCustom(
    query(
      collection(getFirestore(), AllCollections.invites.name).withConverter(
        converters.invitesConverter
      ),
      where("outstanding", "==", true)
    )
  );

  //console.log(invitesError);

  // TODO: useMemo
  const rowMap: Record<string, UserTableRow> = {};

  const data = useMemo(() => {
    const decoratedUsers: DecoratedArtistUser[] = users.map((u) => {
      const associatedArtistDocument = artistDocuments?.find(
        (a) => a.artistId === u.userId
      );

      if (associatedArtistDocument) {
        return { ...u, artistLoaded: true, ...associatedArtistDocument };
      } else {
        return { ...u, artistLoaded: false };
      }
    });

    return [...invites, ...decoratedUsers];
  }, [invites, users, artistDocuments]);

  data.forEach((row) => {
    if (isUser(row)) {
      rowMap[row.userId] = row;
    } else {
      rowMap[row.inviteCode] = row;
    }
  });

  const selectedRowIds = Object.keys(rowSelection);
  const selectedRows = selectedRowIds.map((id) => rowMap[id]);
  const selectedRow = selectedRows[0];

  function rowRepresentsLiveArtist(
    row: UserTableRow
  ): row is DecoratedArtistUser & { isArtist: true } {
    if (!isUser(row)) {
      return false;
    } else if (!row.isArtist) {
      return false;
    } else {
      const relevantArtist = artistDocuments?.find(
        (artist) => artist.artistId === row.userId
      );

      if (!relevantArtist) {
        console.warn(`Can't find relevant artist for userId=${row.userId}`);
        return false;
      } else {
        return relevantArtist.live;
      }
    }
  }
  function rowRepresentsArtist(
    row: UserTableRow
  ): row is DecoratedArtistUser & { isArtist: true } {
    if (!isUser(row)) {
      return false;
    } else {
      return row.isArtist;
    }
  }

  const [deletingUser, setDeletingUser] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="span" variant="h4" textAlign="center">
        All Users
      </Typography>
      <MaterialReactTable
        initialState={{ pagination: { pageSize: 3, pageIndex: 0 } }}
        state={{ isLoading: usersLoading, rowSelection }}
        muiPaginationProps={{
          rowsPerPageOptions: [3, 5, 10, 20]
        }}
        enableSelectAll={false}
        columns={userTableColumns}
        enableColumnResizing
        data={data}
        //enableColumnFilterModes
        //enableColumnOrdering
        //enableGrouping
        enablePinning
        //enableRowActions
        enableRowSelection
        //initialState={{ showColumnFilters: true }}
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={() => (
          <Box display="flex" sx={{ gap: 1.2 }}>
            <Button
              disabled={Object.values(rowSelection).length === 0 || readOnly}
              variant="contained"
              size="small"
              onClick={() => setEmailDialogOpen(true)}
            >
              Send Email
            </Button>

            <Button
              disabled={
                readOnly ||
                selectedRows.length !== 1 ||
                !selectedRow ||
                !(
                  rowRepresentsArtist(selectedRow) &&
                  selectedRow.setupCompletedOn
                )
              }
              variant="contained"
              size="small"
              onClick={async () => {
                if (readOnly || selectedRows.length !== 1 || !selectedRow) {
                  // button is disabled, but somehow this was clicked or race condition
                  dialog.error("Unexpected state, try again");
                } else {
                  setModerateDialogOpen(true);
                  setModerateRadioValue(
                    rowRepresentsLiveArtist(selectedRow) ? "Live" : "Not Live"
                  );
                }
              }}
            >
              Moderate user
            </Button>

            <Button
              disabled={
                readOnly ||
                selectedRows.length !== 1 ||
                !selectedRow ||
                !(
                  rowRepresentsArtist(selectedRow) &&
                  selectedRow.setupCompletedOn
                )
              }
              variant="contained"
              onClick={() => {
                if (
                  selectedRows.length !== 1 ||
                  !selectedRow ||
                  !(
                    rowRepresentsArtist(selectedRow) &&
                    selectedRow.setupCompletedOn
                  )
                ) {
                  alert("can't get needed info for this user");
                } else {
                  setChangingStoreNameFor(selectedRow);
                }
              }}
            >
              Change Store Name
            </Button>

            <LoadingButton
              loading={deletingUser}
              sx={{ height: "100%" }}
              disabled={
                readOnly ||
                selectedRows.length !== 1 ||
                !selectedRow ||
                !rowRepresentsArtist(selectedRow)
              }
              variant="contained"
              onClick={async () => {
                if (
                  selectedRows.length !== 1 ||
                  !selectedRow ||
                  !rowRepresentsArtist(selectedRow)
                ) {
                  alert("can't get needed info for this user");
                } else {
                  setDeletingUser(true);

                  const id = selectedRow.userId;

                  const challenge = `DELETE ${selectedRow.name.first} ${selectedRow.name.last}`;

                  const entered = window.prompt(
                    `Are you sure you want to delete this user? This is permanent, and serious. If you wish to proceed, enter "${challenge}" with correct casing`
                  );

                  if (entered !== challenge) {
                    window.alert("canceling user deletion");
                  } else {
                    try {
                      const result = await callCloudFunction("deleteArtist", {
                        userIdToDelete: id,
                        reason: "deletion by admin"
                      });

                      dialog.alert({
                        title: "Deletion Result",
                        content: JSON.stringify(result)
                      });
                    } catch (e) {
                      dialog.error("failed to delete user", e);
                    } finally {
                      setDeletingUser(false);
                    }
                  }
                }
              }}
            >
              Delete
            </LoadingButton>

            <Button
              disabled={readOnly}
              variant="contained"
              onClick={() => setInviteDialogOpen(true)}
            >
              Invite User
            </Button>
          </Box>
        )}
        muiTablePaperProps={{
          elevation: 0
        }}
        onRowSelectionChange={setRowSelection}
        getRowId={(originalRow) =>
          isUser(originalRow) ? originalRow.userId : originalRow.inviteCode
        }
        muiSelectCheckboxProps={({ row }) => ({
          disabled: row.original.email === undefined
        })}
      />
      <Dialog
        fullWidth
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setEmailDialogOpen(false)}>
          Send email to user{selectedRowIds.length > 1 ? "s" : ""}
        </DialogTitleWithClose>

        <Formik<SendEmailForm>
          initialValues={{ subject: "", text: "" }}
          validate={validateSendEmailForm}
          onSubmit={async (values) => {
            const toRaw = selectedRowIds.map((userId) => rowMap[userId]?.email);
            const to = toRaw.filter((email) => email !== undefined) as string[];

            if (to.length < toRaw.length) {
              const errorMessage =
                "Failed to get (all or some of) the recipient list, unexpected state, email not sent";
              console.error(errorMessage);
              window.alert(errorMessage);
              return;
            }

            await sendEmail({
              content: { text: values.text },
              subject: values.subject,
              to: to,
              from: {
                email: "admin@thegivinggallery.com",
                name: "The Giving Gallery"
              }
            });
            setEmailDialogOpen(false);
            alert("Email sent!");
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <FormLabel>Recipients</FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: 0.5,
                    columnGap: 0.5,
                    mt: 1
                  }}
                >
                  {selectedRows.map((row) => (
                    <Chip
                      key={row?.email}
                      label={row?.email || "loading email..."}
                      variant="filled"
                      size="small"
                    />
                  ))}
                </Box>
                <Box mt={1} />

                <FormLabel>Email Subject</FormLabel>

                <FormikField
                  name="subject"
                  sx={{ mt: 1 }}
                  size="small"
                  placeholder="Enter an informative subject"
                  fullWidth
                />

                <Box mt={1} />
                <FormLabel>Email Content</FormLabel>

                <FormikField
                  name="text"
                  sx={{ mt: 1 }}
                  placeholder="Enter plaintext email content here"
                  multiline
                  minRows={4}
                  fullWidth
                />
              </DialogContent>

              <DialogActions>
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Sending..." : "Send Email"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <ChangeStoreNameDialog
        onClose={() => setChangingStoreNameFor(undefined)}
        targetUser={changingStoreNameFor}
      />
      <InviteUserDialog
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
      />
      <Dialog
        fullWidth
        open={moderateDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setModerateDialogOpen(false)}>
          Moderate user
        </DialogTitleWithClose>
        <DialogContent>
          <FormControl disabled={moderationActionLoading}>
            <FormLabel id="radio-buttons-group-label">
              Edit user live status for user{" "}
              {selectedRow && rowRepresentsArtist(selectedRow)
                ? selectedRow.storeName
                : "Error"}
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="Live"
              name="radio-buttons-group"
              value={moderateRadioValue}
            >
              <FormControlLabel
                value="Live"
                control={<Radio />}
                label="Live"
                onClick={async () => {
                  if (moderateRadioValue === "Not Live") {
                    if (
                      readOnly ||
                      selectedRows.length !== 1 ||
                      !selectedRow ||
                      !rowRepresentsArtist(selectedRow)
                    ) {
                      // button is disabled, but somehow this was clicked or race condition

                      dialog.error("Unexpected state, try again");
                    } else {
                      if (
                        window.confirm(
                          "Are you sure you want to set artist " +
                            selectedRow.storeName +
                            " as live?"
                        )
                      ) {
                        try {
                          setModerationActionLoading(true);
                          await callCloudFunction("artistVisibility", {
                            artistId: selectedRow.userId,
                            action: "set as live"
                          });

                          dialog.alert({
                            content: `Successfully set ${selectedRow.storeName} as live`,
                            title: "Success"
                          });
                          setModerateRadioValue("Live");
                        } catch (e) {
                          dialog.error(`Error setting artist as live`, e);
                        } finally {
                          setModerationActionLoading(false);
                        }
                      }
                    }
                  }
                }}
              />
              <FormControlLabel
                value="Not Live"
                control={<Radio />}
                label="Not Live"
                onClick={async () => {
                  if (moderateRadioValue === "Live") {
                    if (
                      readOnly ||
                      selectedRows.length !== 1 ||
                      !selectedRow ||
                      !rowRepresentsArtist(selectedRow)
                    ) {
                      // button is disabled, but somehow this was clicked or race condition

                      dialog.error("Unexpected state, try again");
                    } else {
                      if (
                        window.confirm(
                          "Are you sure you want to set artist " +
                            selectedRow.storeName +
                            " as not live?"
                        )
                      ) {
                        try {
                          setModerationActionLoading(true);
                          await callCloudFunction("artistVisibility", {
                            artistId: selectedRow.userId,
                            action: "set as not live"
                          });

                          dialog.alert({
                            content: `Successfully set ${selectedRow.storeName} as not live`,
                            title: "Success"
                          });
                          setModerateRadioValue("Not Live");
                        } catch (e) {
                          dialog.error(`Error setting artist as not live`, e);
                        } finally {
                          setModerationActionLoading(false);
                        }
                      }
                    }
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          {moderationActionLoading ? (
            <Loading type="bubbles" color="#812215" />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
