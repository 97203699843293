// categories have sync products, and sync products have variants

import {
  PrintfulApiProduct,
  PrintfulApiVariant
} from "./models/printful-models";

export function getVariantSize(printfulVariant: PrintfulApiVariant) {
  function extractDimensions(input: string): { width: number; height: number } {
    const regex = /(\d+(?:\.\d+)?)″×(\d+(?:\.\d+)?)″/;
    const match = input.match(regex);

    if (!match) {
      console.error(
        "Failed to extract variant size from variant",
        printfulVariant
      );
      throw new Error("Invalid format");
    } else {
      const dimensions = {
        width: parseFloat(match[1]!),
        height: parseFloat(match[2]!)
      };

      return dimensions;
    }
  }

  const { width, height } = extractDimensions(printfulVariant.size);

  return {
    width,
    height,
    aspectRatio: width / height,
    unit: "inches" // FIXME: cm vs inches
  };
}

function calculateMarginRatio(
  canvasAspect: number,
  imageAspect: number
): number {
  //answers how much of the canvass is taken by margins ex: 0.2 will mean 1/5th of the canvass is taken by margins
  if (canvasAspect - imageAspect > 0) {
    //canvas is wider than image
    //canvas aspect minus image aspect gives the left and right margins over the height,
    //so we multiply by height over width to get margin over width
    return (canvasAspect - imageAspect) * (1 / canvasAspect);
  } else {
    //canvas is taller or equal to image, we use height/width minus height/width to get the margins over width,
    //then we multiply by width/height to get the margins over height
    return (1 / canvasAspect - 1 / imageAspect) * canvasAspect;
  }
}

export function calculatePossibleSizeOptions(
  yPixels: number,
  xPixels: number,
  printfulProduct: PrintfulApiProduct,
  printfulVariants: PrintfulApiVariant[]
): PrintfulApiVariant[] {
  const minDPI = 250;
  let maxMarginRatio = 0.2;

  const options = () =>
    printfulVariants.filter((element) => {
      const { width, height } = getVariantSize(element);
      const { fixedWidth, fixedHeight } =
        printOrientation({ width: xPixels, height: yPixels }) === "horizontal"
          ? { fixedWidth: height, fixedHeight: width }
          : { fixedWidth: width, fixedHeight: height };
      const marginRatio = calculateMarginRatio(
        xPixels / yPixels,
        fixedWidth / fixedHeight
      );
      return (
        Math.min(yPixels / fixedHeight, xPixels / fixedWidth) >= minDPI &&
        marginRatio <= maxMarginRatio
      );
    });

  let possibleSizeOptions = options();

  if (printfulProduct.id === 1 && possibleSizeOptions.length === 0) {
    // we always want there to be at least 1 matte option, even if the
    // margins are too wide
    maxMarginRatio = 1;
    possibleSizeOptions = options().slice(0, 1);
  }

  return possibleSizeOptions;
}

export function printOrientation(dimensions: {
  width: number;
  height: number;
}): "vertical" | "horizontal" {
  if (dimensions.width > dimensions.height) {
    return "horizontal";
  } else {
    return "vertical";
    //square images will be printed vertically
  }
  //eventually, when the artist chooses their crop, that will decide the orientation instead
}
