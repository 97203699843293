import { create } from "zustand";

type JoinState = {
  inviteCode?: string;
  setInviteCode: (code?: string) => void;
};

export const useJoinState = create<JoinState>((set) => ({
  inviteCode: undefined,
  setInviteCode(code) {
    set(() => ({ inviteCode: code }));
  }
}));
