import {
  Lock,
  Login as LoginIcon,
  MailOutline,
  NavigateBefore
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  Typography
} from "@mui/material";
import {
  User,
  fetchSignInMethodsForEmail,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from "firebase/auth";
import { Form, Formik, FormikErrors, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginWithGoogle } from "../helpers/firebase-helpers";
import { constructTGGUser } from "../redux/slices/authSlice";
import { dialog } from "../zustand/imperative-dialog";
import { useMultiModal } from "../zustand/multi-modal";
import "./LogInModal.css";
import FormikField from "./formik/FormikField";
import DialogTitleWithClose from "./helpers/DialogTitleWithClose";
import PageLoading from "./helpers/PageLoading";
import RichAlert from "./helpers/RichAlert";
import SupportEmail from "./helpers/SupportEmail";

interface LogInFormValues {
  email: string;
}

function validateLoginForm(values: LogInFormValues) {
  const errors: FormikErrors<LogInFormValues> = {};

  const emailRE =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (!values.email) {
    errors.email = "Required";
  } else if (!values.email?.match(emailRE)) {
    errors.email = "You must use a valid email address";
  }

  return errors;
}

function redirectUser(user: User, navigate: (to: string) => void) {
  const tggUser = constructTGGUser(user);

  if (tggUser.roles.includes("admin")) {
    navigate("/admin");
  } else if (tggUser.roles.includes("artist")) {
    navigate("/mystore");
  }
}

function Login({
  onHide,
  prefillEmail,
  redirectAfter
}: {
  onHide: () => void;
  prefillEmail?: string;
  redirectAfter: boolean;
}) {
  const navigate = useNavigate();

  const [authDetails, setAuthDetails] = useState<
    | undefined
    | "loading"
    | {
        email: string;
        authMethods: string[];
      }
  >();
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [resetEmailSending, setResetEmailSending] = useState(false);

  async function determineSignInMethods(email: string) {
    const methods = await fetchSignInMethodsForEmail(getAuth(), email);
    return methods;
  }

  type LoginWithEmailResult =
    | {
        type: "success";
        user: User;
      }
    | { type: "error"; error: string };

  async function loginWithEmail(values: {
    email: string;
    password: string;
  }): Promise<LoginWithEmailResult> {
    try {
      const user = await signInWithEmailAndPassword(
        getAuth(),
        values.email,
        values.password
      );
      return { type: "success", user: user.user };
    } catch (error: unknown) {
      let resultMsg: string;

      if (typeof error === "object" && error && "code" in error) {
        switch (error.code) {
          case "auth/wrong-password":
            resultMsg = "Wrong password";
            break;
          case "auth/user-not-found":
            resultMsg = "No account associated with this email";
            break;

          default:
            resultMsg = `Unexpected error (${error.code})`;
        }
      } else {
        resultMsg = "unknown error occurred";
        console.error("error during login", error);
      }

      return { type: "error", error: resultMsg };
    }
  }

  const emailForm = useFormik({
    initialValues: { email: prefillEmail || "" },
    validate: validateLoginForm,
    onSubmit: async (values, { setErrors }) => {
      const methods = await determineSignInMethods(values.email);

      if (methods.length === 0) {
        setErrors({
          email: "no account associated with this email",
          noAccount: true
        } as { email: string; noAccount: boolean });
      } else if (methods.includes("google.com")) {
        // TODO: error handling
        const user = await loginWithGoogle(values.email);

        if (
          user?.email?.trim().toLowerCase() !==
          values.email.trim().toLowerCase()
        ) {
          setErrors({ email: "attempted incorrect google account" });
        } else {
          // successfully logged in
          redirectAfter && redirectUser(user, navigate);
          onHide();
        }
      } else {
        // for email
        setAuthDetails({
          email: values.email,
          authMethods: methods
        });
      }
    }
  });

  useEffect(() => {
    if (prefillEmail) {
      emailForm.submitForm();
    }
  }, []);

  const width = "400px";

  if (!authDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <FormikProvider value={emailForm}>
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Box sx={{ m: 3, mt: 0 }}>
              <Typography paragraph>
                Enter your email to log in to an existing account
              </Typography>
              <Typography paragraph sx={{ mb: 3 }}></Typography>
              <FormikField
                disabled={emailForm.isSubmitting}
                autoFocus
                name="email"
                label="Email"
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <LoadingButton
              id="submitLogInButton"
              loading={emailForm.isSubmitting}
              type="submit"
              loadingPosition="end"
              endIcon={<LoginIcon style={{ marginBottom: "1px" }} />}
            >
              Continue to login
            </LoadingButton>
            {(emailForm.errors as any)["noAccount"] && (
              <RichAlert severity="info">
                <Typography>
                  Looking to accept an invitation? Go to the{" "}
                  <Link to="/join" onClick={() => onHide()}>
                    join
                  </Link>{" "}
                  page
                </Typography>
              </RichAlert>
            )}
            <Typography sx={{ p: 2 }}>
              {" "}
              No account yet? Apply as an artist{" "}
              <Link onClick={() => onHide()} to="/apply">
                here
              </Link>
              .
            </Typography>
          </Form>
        </FormikProvider>
      </Box>
    );
  } else if (authDetails === "loading") {
    return (
      <Box sx={{ width }}>
        <PageLoading hint="Looking up authentication methods" />;
      </Box>
    );
  } else if (authDetails.authMethods.includes("password")) {
    return (
      <Box sx={{ width }}>
        <Formik
          validate={(values: { password: string }) => {
            if (!values.password) {
              return { password: "required" };
            } else {
              return {};
            }
          }}
          initialValues={{ email: authDetails.email, password: "" }}
          onSubmit={async (values, { setErrors }) => {
            const result = await loginWithEmail({
              email: authDetails.email,
              password: values.password
            });

            if (result.type === "success") {
              redirectAfter && redirectUser(result.user, navigate);
              onHide();
            } else {
              setErrors({ password: result.error });
            }
          }}
        >
          {({ isSubmitting }) => (
            <Box mt={3}>
              <Form>
                <div style={{ marginLeft: "30px", marginRight: "30px" }}>
                  <Box mb={3}>
                    <FormikField
                      disabled
                      name="email"
                      label="Email"
                      type="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutline />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>

                  <Box mb={3}>
                    <FormikField
                      autoFocus
                      type="password"
                      name="password"
                      label="Password"
                      ignoreBlurIf={(e) =>
                        e.relatedTarget !== null &&
                        e.relatedTarget.tagName === "BUTTON"
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    type="button"
                    value="button"
                    onClick={() => {
                      setAuthDetails(undefined);
                    }}
                    startIcon={<NavigateBefore />}
                  >
                    back
                  </Button>
                  <LoadingButton
                    type="button"
                    value="button"
                    sx={{
                      fontSize: "10px",
                      textTransform: "none",
                      padding: "4px 6px"
                    }}
                    onClick={async () => {
                      try {
                        setResetEmailSending(true);
                        await sendPasswordResetEmail(
                          getAuth(),
                          authDetails.email
                        );
                        setResetEmailSent(true);
                      } catch (error) {
                        console.error(
                          "Error sending password reset email:",
                          error
                        );
                        dialog.error(
                          "Error sending password reset email. Please try again.",
                          error
                        );
                      } finally {
                        setResetEmailSending(false);
                      }
                    }}
                    loading={resetEmailSending}
                    disabled={resetEmailSent}
                  >
                    {resetEmailSent
                      ? `Password reset email sent!`
                      : "Forgot Password?"}
                  </LoadingButton>

                  <LoadingButton
                    id="submitLogInButton"
                    loading={isSubmitting}
                    type="submit"
                    loadingPosition="start"
                    startIcon={<MailOutline style={{ marginBottom: "1px" }} />}
                  >
                    Log In
                  </LoadingButton>
                </div>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
    );
  } else {
    return (
      <Box sx={{ width }}>
        <Typography>
          Email is associated with unknown login method, please contact{" "}
          <SupportEmail />
        </Typography>
      </Box>
    );
  }
}
type SignInModalProps = {
  onHide: () => void;
};

export default function SignInModal({ onHide }: SignInModalProps) {
  useEffect(() => {
    const tabContainer = document.getElementById("tab-container");

    const tabList = tabContainer?.firstChild;

    if (tabList) {
      (tabList as HTMLElement).style.position = "relative";
    }

    const btn = document.createElement("button");
    btn.className = "btn-close";
    btn.ariaLabel = "Close";
    btn.type = "button";
    btn.id = "custom-close-btn";
    btn.style.position = "absolute";
    btn.style.right = "10px";
    btn.style.top = "8px";
    btn.onclick = onHide;

    const existingButton = document.getElementById("custom-close-btn");

    // don't create the element if it's already there
    if (!existingButton) {
      tabList?.appendChild(btn);
    }
  });

  const loginStage = useMultiModal((state) => state.stage);

  return (
    <>
      <DialogTitleWithClose onClose={() => onHide()}>
        Login
      </DialogTitleWithClose>
      <DialogContent style={{ padding: 0 }}>
        <Login
          onHide={onHide}
          prefillEmail={loginStage?.prefillEmail}
          redirectAfter={loginStage?.redirectAfter === false ? false : true}
        />
      </DialogContent>
    </>
  );
}
