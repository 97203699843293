import { z } from "zod";
import { ProductDocument } from "./product-models";
import {
  PrintfulApiProduct,
  PrintfulApiVariant,
  PrintfulCostsDetails,
  PrintfulOrderData,
  PrintfulStatus
} from "./printful-models";
import { TaxBreakdown } from "./stripe-models";

export const USAddress = z.object({
  country: z.literal("US"),
  city: z.string(),
  state: z.string(),
  zipcode: z.string(),
  line1: z.string(),
  line2: z.string().nullable()
});

export type USAddress = z.infer<typeof USAddress>;

export const Payout = z
  .object({
    payeeType: z.literal("artist"),
    amount: z.number(),
    payee: z.string(),
    paid: z.boolean(),
    paidOn: z.date().nullable(),
    transactionId: z.string().nullable(),
    // TODO: better named as "id"
    idempotencyKey: z.string(),
    processedWith: z.literal("stripe_connect")
  })
  .or(
    z.object({
      payeeType: z.literal("nonprofit"),
      amount: z.number(),
      payee: z.string(),
      artist: z.string(),
      paid: z.boolean(),
      paidOn: z.date().nullable().nullable(),
      transactionId: z.string().nullable(),
      // TODO: better named as "id"
      idempotencyKey: z.string(),
      processedWith: z.literal("internal")
    })
  );

export type Payout = z.infer<typeof Payout>;

/**
 * Events are things that happen in time, that determine the state of the order.
 *
 * They are things we generally attach to orders as time goes on, and act as the
 * source of truth for order state, though they can be "collapsed" into a
 * higher level status.
 */
export const OrderEvents = z.object({
  version: z.literal(1),
  lastUpdated: z.date(),
  flags: z.object({
    flagged: z.boolean(),
    flaggedOn: z.date().nullable(),
    flags: z.array(z.object({ description: z.string(), key: z.string() }))
  }),
  printing: z.object({
    placed: z.boolean(),
    placedOn: z.date().nullable(),
    confirmed: z.boolean(),
    confirmedOn: z.date().nullable(),

    printfulStatus: PrintfulStatus.nullable(),

    orderUpdatedOn: z.date().optional(),
    order: PrintfulOrderData.nullable()
  }),
  shipment: z.object({
    shipped: z.union([
      z.literal("full"),
      z.literal("partial"),
      z.literal(false)
    ]),
    fullyShippedOn: z.date().nullable(),
    trackingUrls: z.record(z.string()),
    itemsShipped: z.array(
      z.object({
        shipmentId: z.number(),
        lineItem: z.string(),
        shippedOn: z.date(),
        quantity: z.number()
      })
    )
  }),
  returns: z.object({
    // TODO: make this flow more robust
    requested: z.boolean(),
    requestedOn: z.date().nullable(),
    processed: z.boolean(),
    processedOn: z.date().nullable(),
    itemsRequested: z.array(
      z.object({
        lineItem: z.string(),
        quantity: z.number(),
        requestOn: z.date(),
        reason: z.string()
      })
    ),
    itemsReturned: z.array(
      z.object({
        lineItem: z.string(),
        returnedOn: z.date(),
        quantity: z.number()
      })
    )
  }),
  cancellation: z.object({
    canceled: z.boolean(),
    canceledOn: z.date().nullable(),
    cancelationReason: z.string().nullable()
  }),
  fulfillment: z.object({
    delivered: z.union([
      z.literal("full"),
      z.literal("partial"),
      z.literal(false)
    ]),
    estimated: z.string(),
    fullyDeliveredOn: z.date().nullable(),
    itemsDelivered: z.array(
      z.object({
        lineItem: z.string(),
        receivedOn: z.date(),
        quantity: z.number()
      })
    )
  }),
  payout: z.object({
    complete: z.union([
      z.literal("full"),
      z.literal("partial"),
      z.literal(false)
    ]),
    fullyCompletedOn: z.date().nullable(),
    payouts: z.array(Payout)
  })
});

export const FitStyle = z.object({
  fitStyle: z.union([z.literal("center_crop"), z.literal("margin")])
});
export type FitStyle = z.infer<typeof FitStyle>;

const Cart = z.array(
  z.object({
    lineItem: z.string(),
    product: z.object({
      id: z.string(),
      version: z.number()
    }),
    printfulVariant: z.object({
      id: z.number(),
      printfulProductId: z.number()
    }),
    fit: FitStyle,
    count: z.number()
  })
);

/**
 * All costs are in cents
 */
export const OrderCosts = z.object({
  /**
   * Base cost, i.e., the cost of all the underlying artwork before any taxes or
   * printing costs
   */
  base: z.number(),

  /**
   * Total estimated printing costs, INCLUDING shipping.
   */
  printing: PrintfulCostsDetails,

  tax: z.number(),

  /**
   * The total is the full amount the user is charged, i.e., what shows
   * up on their credit cards. It includes base cost, printing (+ shipping), tax,
   */
  total: z.number()
});

export type OrderCosts = z.infer<typeof OrderCosts>;

/**
 * Should contain all the information on what the user paid
 * (breakdown of taxes, shipping, etc).
 *
 * Should also contain any tax calculations and transactions
 */
const FinancialDetails = z.object({
  paymentIntentId: z.string(),

  tax: z.object({
    calculationId: z.string(),
    transactionId: z.string().nullable(),
    breakdown: z.array(TaxBreakdown)
  }),

  costs: OrderCosts
});

const EntitySnapshots = z.object({
  products: z.record(ProductDocument),
  printful: z.object({
    products: z.record(PrintfulApiProduct),
    variants: z.record(PrintfulApiVariant)
  })
});

const OrderDetails = z.object({
  customer: z.object({
    name: z.object({
      first: z.string(),
      last: z.string().optional()
    }),
    shippingAddress: USAddress,
    phone: z.string().nullable(),
    email: z.string()
  }),
  selectedNonprofit: z.object({
    id: z.string(),
    name: z.string(),
    shortName: z.string().optional()
  }),
  cart: Cart,
  snapshots: EntitySnapshots,
  financial: FinancialDetails,
  marketingEmailsAllowed: z.boolean().optional(),
  gift: z
    .object({
      // part of the printful API for orders
      subject: z.string().max(200),
      message: z.string().max(200)
    })
    .nullable()
});

export const CheckoutSession = OrderDetails.extend({
  checkoutSessionId: z.string(),
  checkoutSessionCreated: z.date(),

  // TODO: have actual dates here instead of a string description
  estimatedArrival: z.string(),
  plannedOrderId: z.string(),
  deleteOn: z.date()
});

export type CheckoutSession = z.infer<typeof CheckoutSession>;

const OrderState = z.object({
  inProgress: z.boolean(),
  onHold: z.boolean(),
  onHoldOn: z.date().optional(),
  status: z.union([
    z.literal("placed"),
    z.literal("printing"),
    z.literal("shipping"),
    z.literal("delivered"),
    z.literal("paid")
  ])
});

export const Order = OrderDetails.extend({
  financial: FinancialDetails.extend({
    /**
     * Printful should only charge us what they estimate (and what we charge the customer)
     * but it's possible there'll be a mismatch, which we should store and alert on
     * so we can decide how to deal with it.
     */
    actualPrintingCosts: PrintfulCostsDetails.nullable(),
    split: z.object({
      artists: z.record(z.number()),
      tgg: z.number(),
      nonprofit: z.number()
    })
  }),
  orderId: z.string(),
  dates: z.object({
    createdOn: z.date(),
    updatedOn: z.date(),
    completedOn: z.date().nullable()
  }),
  events: OrderEvents,
  state: OrderState,
  checkoutSession: z.object({
    id: z.string(),
    created: z.date()
  }),
  taxTransactionId: z.string()
});

export type Order = z.infer<typeof Order>;

export const SubOrder = z.object({
  subOrderId: z.string(),
  artist: z.string(),

  selectedNonprofit: z.object({
    id: z.string(),
    name: z.string(),
    shortName: z.string().optional()
  }),

  subCart: Cart,

  subEvents: OrderEvents.omit({ flags: true }),
  subState: OrderState,

  // TODO: consider having this be smaller for suborders
  snapshots: EntitySnapshots,

  orderId: z.string(),
  dates: z.object({
    createdOn: z.date(),
    updatedOn: z.date(),
    completedOn: z.date().nullable()
  }),

  /**
   * Used to determine when the order this is projected from
   * was written to, to prevent race conditions when projecting
   * the suborder.
   */
  fromOrderWrittenOn: z.date().optional()
});

export type SubOrder = z.infer<typeof SubOrder>;
