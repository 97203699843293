export function limitText(text: string, maxLength = 120, buffer = 10) {
  if (text.length > maxLength) {
    let shortText = text.substring(0, maxLength);
    const lastSpaceIndex = shortText.lastIndexOf(" ");

    // Check if there is a space within the buffer range
    if (lastSpaceIndex >= maxLength - buffer) {
      shortText = shortText.substring(0, lastSpaceIndex);
    }

    return shortText + " [ ... ]";
  } else {
    return text;
  }
}
