import { Box, Tooltip, Typography } from "@mui/material";
import { DateTime, Duration, DurationLike } from "luxon";
import { useEffect, useMemo, useState } from "react";
import Loading from "react-loading";
import SupportEmail from "./SupportEmail";

interface PageLoadingProps {
  timeout?: DurationLike;
  hint?: string;
}
/**
 * Generic loading component. By default, if it loads for > 8 seconds
 * it will show a message suggesting contacting support.
 */
export default function PageLoading({
  timeout = Duration.fromMillis(8000),
  hint
}: PageLoadingProps) {
  const startTime = useMemo(() => DateTime.now(), []);
  const [timeoutReached, setTimeoutReached] = useState(false);

  useEffect(() => {
    // If we have a timeout that hasn't been reached, poll for it being reached
    if (timeout && !timeoutReached) {
      const intervalId = setInterval(() => {
        const currentTime = DateTime.now();
        const elapsedTime = currentTime.diff(startTime);

        if (elapsedTime > timeout) {
          setTimeoutReached(true);
        }
      }, 50);

      // cleanup will happen when the timeout is reached and at component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeout, timeoutReached]);

  useEffect(() => {
    if (timeoutReached) {
      console.error(`timeout (of ${timeout}) has been reached while loading`, {
        hint: hint
      });
    }
  }, [timeoutReached]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <Tooltip title={hint || "loading"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Loading type="bubbles" color="#812215" />
          {timeoutReached && (
            <Typography sx={{ maxWidth: "500px", textAlign: "center", p: 3 }}>
              Something is taking longer than expected. If it doesn't resolve,
              please contact <SupportEmail />
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}
