import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { collection, getFirestore, query } from "firebase/firestore";
import { AllCollections } from "shared/models/collections";
import { currency } from "shared/tools/currency-tools";
import { converters } from "../../helpers/firebase-helpers";
import { useCollectionDataCustom, useNonprofits } from "../../helpers/queries";

export default function FinancialOverview() {
  const [nonprofitPrivate] = useCollectionDataCustom(
    query(
      collection(
        getFirestore(),
        AllCollections.nonprofitPrivate.name
      ).withConverter(converters.nonprofitPrivateConverter)
    )
  );
  const nonprofits = useNonprofits();

  const fullNonprofits =
    nonprofitPrivate && nonprofits[0]
      ? nonprofits[0].map((nonprofit, i) => ({
          ...nonprofit,
          ...nonprofitPrivate.find((element) => element.id === nonprofit.id)!
        }))
      : undefined;

  return (
    <Box>
      {fullNonprofits ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
                <TableCell>Nonprofit</TableCell>
                <TableCell>Amount Owed</TableCell>
                <TableCell>Total Raised</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fullNonprofits.map((nonprofit, i) => (
                <TableRow key={i}>
                  <TableCell>{nonprofit.name}</TableCell>
                  <TableCell>
                    ${currency(nonprofit.owed, { fromCents: true }).toString()}
                  </TableCell>
                  <TableCell>
                    $
                    {currency(nonprofit.totalRaised, {
                      fromCents: true
                    }).toString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Can't find data</Typography>
      )}
    </Box>
  );
}
