import { collection, getFirestore } from "firebase/firestore";
import { zodConverter } from "./firestoreHelpers";
import { AllCollections } from "shared/models/collections";

// converters to automatically read and write out models to Firestore
export const nonProfitsConverter = zodConverter("nonprofits");

export const productsConverter = zodConverter("products");
export const artistsConverter = zodConverter("artists");

export const tggCheckoutSessionConverter = zodConverter("checkoutSessions");

export const orderConverter = zodConverter("orders");
export const subOrderConverter = zodConverter("subOrders");

// prebuilt collections to query
const firestore = getFirestore();

export const productsCollection = collection(
  firestore,
  "products"
).withConverter(productsConverter);

export const artistsCollection = collection(firestore, "artists").withConverter(
  artistsConverter
);

export const deletionsCollection = collection(
  firestore,
  AllCollections.deletions.name
).withConverter(zodConverter("deletions"));

export const nonProfitsCollection = collection(
  firestore,
  "nonProfits"
).withConverter(nonProfitsConverter);

export const ordersCollection = collection(
  firestore,
  AllCollections.orders.name
).withConverter(orderConverter);

export const subOrdersCollection = collection(
  firestore,
  "subOrders"
).withConverter(subOrderConverter);
