import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAuth, Unsubscribe, User } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { jwtDecode } from "jwt-decode";

import store from "../store";
import { ArtistUserData, Role, UserDocument } from "shared/models/user-models";
import { converters } from "../../helpers/firebase-helpers";
import { AllCollections } from "shared/models/collections";

export type TGGUser = User & { roles: Role[]; userDocument?: UserDocument };
export type TGGArtistUser = TGGUser & {
  roles: ["artist", ...Role[]];
  userDocument: UserDocument & ArtistUserData;
};

/**
 * HACK: This isn't hacky by itself, but relies on a hacky auth type hierarchy
 * that couples our concerns too much with Firebases
 *
 * This means we need to make sure the user document always exists and has the artist role
 * as soon as we want to make them an artist
 */
export function userIsLoadedArtistUser(user: TGGUser): user is TGGArtistUser {
  const rolesHasArtist = user.roles.includes("artist");
  let documentRepresentsArtist = false;

  if (user.userDocument?.isArtist) {
    documentRepresentsArtist = true;
  }

  if (user.userDocument && rolesHasArtist !== documentRepresentsArtist) {
    console.warn("Artist document roles haven't propagated to auth token yet.");
  }

  return rolesHasArtist && documentRepresentsArtist;
}

function roleArraysEqual(a: Role[], b: Role[]) {
  // checks if arrays have same items, regardless of sorting
  return a.length === b.length && a.every((aRole) => b.includes(aRole));
}

function extractRolesFromToken(accessToken: string): Role[] {
  const authTokenClaims = jwtDecode<{ roles?: string }>(accessToken);

  if (authTokenClaims.roles) {
    const roles = authTokenClaims.roles
      .split(",")
      .map((r) => r.trim()) as Role[];
    return roles;
  } else {
    // no roles
    return [];
  }
}

export function constructTGGUser(firebaseUser: User): TGGUser {
  interface UserWithToken extends User {
    accessToken: string;
  }

  const extractedRoles = extractRolesFromToken(
    (firebaseUser as UserWithToken).accessToken
  );

  if (extractedRoles.length > 0) {
    const tggUser: TGGUser = { ...firebaseUser, roles: extractedRoles };
    return tggUser;
  } else {
    console.error(
      "No roles found attached to user, defaulting to empty role array."
    );
    const tggUser: TGGUser = { ...firebaseUser, roles: [] };
    return tggUser;
  }
}

export interface AuthState {
  userStatus: "loading" | "loaded" | "error";
  userDocumentStatus: "loading" | "loaded" | "error";
  user?: TGGUser;
  awaitingCorrectRoles: boolean;
  tokenRefreshes: number;
  tokenRefreshesMax: number;
}

const auth = getAuth();
const existingUser = auth.currentUser;

const initalState: AuthState = {
  userStatus: existingUser ? "loaded" : "loading",
  userDocumentStatus: "loading",
  user: existingUser ? constructTGGUser(existingUser) : undefined,
  awaitingCorrectRoles: false,
  tokenRefreshes: 0,
  tokenRefreshesMax: 8
};

const authSlice = createSlice({
  name: "auth",
  initialState: initalState,
  reducers: {
    setFirebaseUser: (state: AuthState, action: PayloadAction<User | null>) => {
      if (action.payload) {
        const newUser = constructTGGUser(action.payload);
        state.user = newUser;
        state.userStatus = "loaded";

        // set up listener after this reducer is finished
        setTimeout(() => setUpUserDocumentListener(newUser.uid), 0);
      } else {
        state.user = undefined;
        state.userStatus = "loaded";
        // TODO: set user document loading state
        state.tokenRefreshes = 0;
        state.awaitingCorrectRoles = false;

        // cancel the firestore listener if we have one
        firestoreUnsubscribeHandler?.();
      }
    },

    setFirebaseError: (state: AuthState) => {
      // TODO: fill this out
    },

    handlePotentialRolesMismatch: (
      state: AuthState,
      action: PayloadAction<{ newExtractedRoles?: Role[] }>
    ) => {
      if (!state.user) {
        console.error(
          "state.user is not defined, so we cannot handlePotentialRolesMismatch"
        );
        // TODO: consider setting error
        return;
      }

      if (!state.user.userDocument) {
        console.log(
          "state.user.userDocument is not defined, so we cannot handlePotentialRolesMismatch"
        );
        // TODO: consider setting error
        return;
      }

      if (action.payload.newExtractedRoles) {
        // we have new roles, meaning our auth token has been updated, but our state doesn't reflect it yet. Update state.
        state.user.roles = action.payload.newExtractedRoles;
      }

      if (roleArraysEqual(state.user.userDocument.roles, state.user.roles)) {
        // roles are now equal! Either because they were to begin with, or because we just integrated roles from an updated token
        // we're done with the role handling logic now

        if (state.tokenRefreshes > 0) {
          // we're in a refresh cycle, so now we let the console reader know we have finished
          console.log(
            `Successfully refreshed the token and got the expected roles (${JSON.stringify(
              state.user.roles
            )})`
          );
        }

        // reset for future role changes
        state.tokenRefreshes = 0;
        state.awaitingCorrectRoles = false;
      } else {
        // if we get here, we have a roles mismatch, so we need to refresh the access token (assuming we haven't hit our chosen refresh limit), and react to the new token
        state.awaitingCorrectRoles = true;

        if (state.tokenRefreshes >= state.tokenRefreshesMax) {
          // TODO: consider forced logout here
          console.error(
            `User has roles ${JSON.stringify(
              state.user.userDocument.roles
            )}, but auth token contains ${JSON.stringify(
              state.user.roles
            )}. Maximum attempts (${
              state.tokenRefreshesMax
            }) at refreshing user roles reached. User should log in again.`
          );
        } else {
          console.log(
            `User has roles ${JSON.stringify(
              state.user.userDocument.roles
            )}, but auth token contains ${JSON.stringify(
              state.user.roles
            )}. Force refreshing token (attempt ${state.tokenRefreshes}/${
              state.tokenRefreshesMax
            })`
          );

          // increment the counter, indicating we've attempted another refresh
          state.tokenRefreshes++;

          setTimeout(async () => {
            if (!auth.currentUser) {
              console.error("Cannot refresh token, currentUser is null.");
            } else {
              const newToken = await auth.currentUser.getIdToken(true);
              const newRoles = extractRolesFromToken(newToken);

              store.dispatch(
                authSlice.actions.handlePotentialRolesMismatch({
                  newExtractedRoles: newRoles
                })
              );
            }
          }, (state.tokenRefreshes * 10) ** 2.1); // exponential backoff
        }
      }
    },

    setUserDocument: (
      state: AuthState,
      action: PayloadAction<UserDocument | undefined>
    ) => {
      if (action.payload) {
        if (state.user) {
          state.user.userDocument = action.payload;
          state.userDocumentStatus = "loaded";

          // dispatch handlePotentialRolesMismatch after this action, WITHOUT new extracted roles
          // TODO: verify that 0 timeout will always run after
          setTimeout(
            () =>
              store.dispatch(
                authSlice.actions.handlePotentialRolesMismatch({})
              ),
            0
          );
        } else {
          console.error(
            "Unexpected state, attempted to setUserDocument, but user is undefined"
          );
          state.userDocumentStatus = "error";
        }
      } else {
        if (state.user) {
          state.user.userDocument = undefined;
          state.userDocumentStatus = "error";
        }
      }
    },

    setUserDocumentError: (state: AuthState) => {
      // TODO: fill this out
    }
  }
});

const firestore = getFirestore();

let firestoreUnsubscribeHandler: Unsubscribe | undefined = undefined;

function setUpUserDocumentListener(userId: string) {
  firestoreUnsubscribeHandler?.();

  const unsub = onSnapshot(
    doc(firestore, AllCollections.users.name, userId).withConverter(
      converters.usersConverter
    ),
    (snapshot) => {
      store.dispatch(authSlice.actions.setUserDocument(snapshot.data()));
    }
  );

  firestoreUnsubscribeHandler = unsub;
}

export default authSlice;
