import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { InviteUserData } from "shared/models/registration-models";
import FormikField from "../formik/FormikField";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

import { z } from "zod";
import { callCloudFunction } from "../../functions/callCloudFunction";
import { withZodSchema } from "../../helpers/ZodFormikAdapter";
import { dialog } from "../../zustand/imperative-dialog";

interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function InviteUserDialog({
  open,
  onClose
}: InviteUserDialogProps) {
  async function submitForm(
    values: FormData,
    formikHelpers: FormikHelpers<FormData>
  ) {
    function setErrors(details: any) {
      if (details && details.path && details.error) {
        formikHelpers.setFieldError(details.path, details.error);
      } else {
        alert("error! " + JSON.stringify(details));
      }
    }

    try {
      await callCloudFunction("inviteUser", {
        ...values,
        origin: window.location.origin
      });
      onClose();
    } catch (e: any) {
      if (e.details && Array.isArray(e.details)) {
        e.details.forEach(setErrors);
      } else if (e.details && typeof e.details === "object") {
        setErrors(e.details);
      } else {
        dialog.error("error sending invite", e);
      }

      console.log(e);
    }
  }

  const FormData = z
    .intersection(
      InviteUserData,
      z.object({
        confirmAdmin: z.literal("I intend to make an admin").optional(),
        confirmReporter: z.literal("I intend to make a reporter").optional(),
        confirmEmail: z.string().email()
      })
    )
    .superRefine((arg, context) => {
      arg.roles.includes("admin") &&
        !arg.confirmAdmin &&
        context.addIssue({
          path: ["confirmAdmin"],
          code: "custom",
          message: "Must confirm if creating an admin"
        });

      arg.roles.includes("reporter") &&
        !arg.confirmReporter &&
        context.addIssue({
          path: ["confirmReporter"],
          code: "custom",
          message: "Must confirm if creating a reporter"
        });

      arg.email !== arg.confirmEmail &&
        context.addIssue({
          path: ["confirmEmail"],
          code: "custom",
          message: "emails don't match"
        });

      return;
    });

  type FormData = z.infer<typeof FormData>;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>Invite User</DialogTitleWithClose>

      <Formik<FormData>
        initialValues={{
          email: "",
          confirmAdmin: undefined,
          confirmReporter: undefined,
          confirmEmail: "",
          name: {
            first: "",
            last: ""
          },
          roles: ["artist"],
          storeName: ""
        }}
        validate={withZodSchema(FormData)}
        onSubmit={submitForm}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          handleBlur,
          touched
        }) => (
          <>
            <DialogContent>
              <Form id="invite-form">
                <Grid container spacing={3} p={3}>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="roles-select-label">Roles</InputLabel>
                      <Select
                        labelId="roles-select-label"
                        multiple
                        input={<OutlinedInput label="Roles" />}
                        value={values.roles}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="roles"
                        placeholder="User Roles"
                        error={touched.roles && errors.roles !== undefined}
                      >
                        {["artist", "admin", "reporter"].map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.roles}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormikField label="User Email" name="email" type="email" />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikField
                      label="Confirm Email"
                      name="confirmEmail"
                      type="confirmEmail"
                    />
                  </Grid>

                  {values.roles.includes("artist") && (
                    <Grid item xs={12}>
                      <FormikField label="Artist Store Name" name="storeName" />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <FormikField name="name.first" label="User First Name" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikField name="name.last" label="User Last Name" />
                  </Grid>

                  {values.roles.includes("admin") && (
                    <Grid item xs={12}>
                      <FormikField
                        label="Confirm Admin Creation"
                        name="confirmAdmin"
                        placeholder="I intend to make an admin"
                        allowUndefined
                      />
                    </Grid>
                  )}

                  {values.roles.includes("reporter") && (
                    <Grid item xs={12}>
                      <FormikField
                        label="Confirm Reporter Creation"
                        name="confirmReporter"
                        placeholder="I intend to make a reporter"
                        allowUndefined
                      />
                    </Grid>
                  )}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button form="invite-form" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : "Send Invite"}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
