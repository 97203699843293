import { NavigateBefore } from "@mui/icons-material";
import { Box, Button, Skeleton } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import AssetsResolver from "../../AssetsResolver";
import { TGGArtistUser } from "../../redux/slices/authSlice";
import RestoreScroll from "../helpers/RestoreScroll";
import Config from "../../config";
import { ArtistDocument } from "shared/models/artist-models";

export default function ManageStoreScaffold({
  children,
  artistUser,
  backButton = true,
  artist: artistDoc
}: {
  children: ReactNode;
  artistUser: TGGArtistUser;
  backButton?: boolean;
  artist?: ArtistDocument;
}) {
  return (
    <>
      <RestoreScroll />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100%"
        }}
      >
        <Box sx={{ maxWidth: "min(1600px, 100%)" }}>
          <Box
            style={{
              position: "relative",
              width: "1600px",
              aspectRatio: "1600/400",
              maxWidth: "100%",
              height: "auto"
            }}
          >
            <Skeleton
              component="div"
              style={{
                position: "absolute",
                height: "auto",
                top: 0,
                left: 0,
                display: "block",
                gridArea: "1/1",
                maxWidth: "100%",
                minWidth: 0,
                aspectRatio: "1600/400"
              }}
              variant="rectangular"
              width={1600}
            />
            {artistUser && (
              <img
                alt={`Store Cover Image`}
                src={AssetsResolver.imageUrl(
                  artistDoc?.coverImage?.path || Config.defaultCoverImagePath,
                  "coverimage"
                )}
                width="1600"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "auto",
                  display: "block",
                  gridArea: "1/1",
                  zIndex: 1,
                  maxWidth: "100%"
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              backgroundColor: "rgba(229, 246, 253, 0.5)",
              minHeight: "100vh",
              position: "relative"
            }}
          >
            {backButton && (
              <Link
                to="/mystore"
                style={{ position: "absolute", top: 0, left: 0 }}
                state={{ scrollY: window.scrollY }}
              >
                <Button size="large">
                  <NavigateBefore /> Go Back to My Store
                </Button>
              </Link>
            )}
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
}
