import {
  CopyAll,
  DoneAll,
  Email,
  Facebook,
  LinkedIn,
  Share,
  Twitter,
  WhatsApp
} from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { dialog } from "../../zustand/imperative-dialog";
import DialogTitleWithClose from "./DialogTitleWithClose";

type ShareButtonProps = Pick<BoxProps, "sx"> & {
  path: string;
  prompt?: React.ReactNode;
};

export default function ShareButton({
  prompt = "Share this page to help spread The Giving Gallery and our artist's creations and stories.",
  ...props
}: ShareButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [copied, setCopied] = useState(false);

  const encodedPath = encodeURI(props.path);

  // TODO: get this from React configs
  const url = new URL(encodedPath, "https://thegivinggallery.com").toString();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...(props.sx || {})
        }}
      >
        <Tooltip title={"share"}>
          <IconButton size="small" onClick={() => setDialogOpen(true)}>
            <Share />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleWithClose onClose={() => setDialogOpen(false)}>
          Share
        </DialogTitleWithClose>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3
          }}
        >
          <Typography sx={{ whiteSpace: "pre-wrap" }}>{prompt}</Typography>

          <TextField
            value={url}
            size="small"
            sx={{
              alignSelf: "stretch",
              "& .MuiInputBase-input": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                direction: "rtl"
              }
            }}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    endIcon={copied ? <DoneAll /> : <CopyAll />}
                    onClick={async () => {
                      // copy URL to clipboard and notify
                      try {
                        navigator.clipboard.writeText(url);
                        setCopied(true);

                        setTimeout(() => {
                          setCopied(false);
                        }, 1200);
                      } catch (e) {
                        dialog.error(
                          "Couldn't copy the URL for you, try copying it manually from the URL bar",
                          e
                        );
                      }
                    }}
                  >
                    Copy URL
                  </Button>
                </InputAdornment>
              )
            }}
          />

          <Divider flexItem variant="middle">
            or share on social media
          </Divider>

          <Box sx={{ display: "flex", gap: 1.5 }}>
            <FacebookShareButton hashtag="#TheGivingGallery" url={url}>
              <Facebook color="primary" fontSize="large" />
            </FacebookShareButton>

            <TwitterShareButton
              url={url}
              hashtags={["TheGivingGallery", "Artwork", "MentalHealth"]}
            >
              <Twitter color="primary" fontSize="large" />
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
              <LinkedIn color="primary" fontSize="large" />
            </LinkedinShareButton>

            <WhatsappShareButton url={url}>
              <WhatsApp color="primary" fontSize="large" />
            </WhatsappShareButton>

            <EmailShareButton url={url}>
              <Email color="primary" fontSize="large" />
            </EmailShareButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
