import { getFunctions, httpsCallable } from "firebase/functions";
import { pickBy } from "lodash";
import { CloudFunctionSchemas } from "shared/cloud-functions";
import { z } from "zod";

const functions = getFunctions();

/**
 * Call one of our well-known cloud functions
 *
 * @throws function.https.HttpsError
 */
export async function callCloudFunction<
  FunctionName extends keyof typeof CloudFunctionSchemas
>(
  name: FunctionName,
  data: z.infer<(typeof CloudFunctionSchemas)[FunctionName]["data"]>
): Promise<z.infer<(typeof CloudFunctionSchemas)[FunctionName]["return"]>> {
  const fn = httpsCallable(functions, name);

  // strip out undefined fields we're sending to cloud functions,
  // otherwise, they resolve as nulls
  const strippedData = pickBy(data, (v) => v !== undefined);
  const response = await fn(strippedData);

  const parseResult = CloudFunctionSchemas[name].return.safeParse(
    response.data
  );

  if (parseResult.success) {
    return parseResult.data;
  } else {
    console.error(
      `Failed to parse result from cloud function ${name}`,
      parseResult.error
    );

    return response.data as z.infer<
      (typeof CloudFunctionSchemas)[FunctionName]["return"]
    >;
  }
}
