import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { useMemo } from "react";

import IframeResizer from "iframe-resizer-react";
import { AllCollections } from "shared/models/collections";
import { useDocumentData } from "../../zustand/documents";
import { OrdersTable } from "./OrdersTable";
import { UsersTable } from "./UsersTable";
import { useAppSelector } from "../../redux/store";
import FinancialOverview from "./FinancialOverview";
import { DeletionsTable } from "./DeletionsTable";

export default function AdminPage() {
  const configDoc = useDocumentData({
    collection: "config",
    documentId: AllCollections.config.document
  });

  const plausibleLink = useMemo(() => {
    if (configDoc?.state === "loaded") {
      const links = configDoc.document.plausible;
      return links[window.location.hostname];
    } else {
      return undefined;
    }
  }, [configDoc]);

  const user = useAppSelector((state) => state.auth.user);

  const readOnly = !user?.roles.includes("admin");

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <>
        {readOnly && (
          <Alert severity="warning">
            Your account is read-only, contact an admin to make changes
          </Alert>
        )}

        <Box sx={{ m: 3, maxWidth: "100%" }}>
          <UsersTable readOnly={readOnly} />
          <Box mt={3} />
          <OrdersTable readOnly={readOnly} />
          <Box mt={3} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography component="span" variant="h4" textAlign="center">
              Financial Overview
            </Typography>
            <FinancialOverview />
          </Box>
          <Box mt={3} />
          <DeletionsTable readOnly={readOnly} />
        </Box>

        <Box
          sx={{
            p: 3,
            backgroundColor: "#DBDFCC",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          {plausibleLink ? (
            <>
              <Typography component="span" variant="h4" textAlign="center">
                Plausible Analytics
              </Typography>

              <IframeResizer
                plausible-embed
                src={`${plausibleLink}&embed=true&theme=light&background=transparent`}
                className="stats-iframe"
                frameBorder="0"
                loading="lazy"
                style={{
                  // see https://stackoverflow.com/questions/37492613/why-does-an-iframe-of-equal-size-cause-parent-div-to-scroll
                  display: "inherit",
                  width: "1px",
                  minWidth: "100%",
                  height: "0px"
                }}
              />
            </>
          ) : (
            <Typography component="span" variant="h4" textAlign="center">
              Can't get analytics embed
            </Typography>
          )}
        </Box>
      </>
    </Box>
  );
}
