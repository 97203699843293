/**
 * Used for validating the cover photo is high enough res
 *
 * @returns An error message on error state
 */
export function validateCoverImageDPI(data: {
  dimensions: {
    width: number;
    height: number;
  };
  cropDimensions?: { width: number; height: number };
}): { status: "error"; error: string } | { status: "success" } {
  return validateGenericImageDPI({ minWidth: 1600, minHeight: 400 }, data);
}

/**
 * Used for validating the profile picture is high enough res
 *
 * @returns An error message on error state
 */
export function validateProfilePictureDPI(data: {
  dimensions: {
    width: number;
    height: number;
  };
  cropDimensions?: { width: number; height: number };
}): { status: "error"; error: string } | { status: "success" } {
  return validateGenericImageDPI({ minWidth: 500, minHeight: 500 }, data);
}

function validateGenericImageDPI(
  constraints: { minWidth: number; minHeight: number },
  data: {
    dimensions: {
      width: number;
      height: number;
    };
    cropDimensions?: { width: number; height: number };
  }
): { status: "error"; error: string } | { status: "success" } {
  if (data.dimensions.width < constraints.minWidth) {
    return {
      status: "error",
      error: `width is too small, it needs to be at
      least ${constraints.minWidth}px (actual width is ${data.dimensions.width}px)`
    };
  } else if (data.dimensions.height < constraints.minHeight) {
    return {
      status: "error",
      error: `height is too small, it needs to be at
      least ${constraints.minHeight}px (actual height is ${data.dimensions.height}px)`
    };
  } else if (
    data.cropDimensions &&
    data.cropDimensions.width < constraints.minWidth
  ) {
    return {
      status: "error",
      error: `selection width is too small, it needs
      to be at least ${constraints.minWidth}px (actual selection width is 
      ${data.cropDimensions.width}px)`
    };
  } else if (
    data.cropDimensions &&
    data.cropDimensions.height < constraints.minHeight
  ) {
    return {
      status: "error",
      error: `selection height is too small, it needs
      to be at least ${constraints.minHeight}px (actual selection width is 
      ${data.cropDimensions.height}px)`
    };
  } else {
    return { status: "success" };
  }
}
