import { ShoppingCart } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  List,
  ListItem,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { calculateCartTotalCost } from "../tools/frontend-currency-tools";
import { cartTotalCount, useCart } from "../zustand/cart-state";
import "./CartIcon.scss";
import MarginProduct from "./productViews/ProductImage";

export default function CartIcon(): JSX.Element {
  const [iconHovered, setIconHovered] = useState(false);
  const [cartHovered, setCartHovered] = useState(false);

  const shoppingCart = useCart();

  const [hovered, setHovered] = useState(false);
  // uncomment to keep cart open for debugging style
  // hovered = true

  useEffect(() => {
    if (!hovered && (iconHovered || cartHovered)) {
      setHovered(true);
    }

    if (hovered && !iconHovered && !cartHovered) {
      setHovered(false);
    }
  }, [iconHovered, cartHovered, hovered]);

  const cartTotalCost = calculateCartTotalCost(shoppingCart);

  return (
    <div style={{ position: "relative", marginTop: "10px" }}>
      <Link to="/cart" aria-label="cart">
        {/* <Badge badgeContent={shoppingCart.totalCount} color="primary"> */}
        <div
          className="cart-icon-container"
          onMouseEnter={() => setIconHovered(true)}
          onMouseLeave={() => setIconHovered(false)}
        >
          <ShoppingCart style={{ color: "#812215" }} className="cart-icon" />
          <div className="cart-icon-badge">{cartTotalCount(shoppingCart)}</div>
        </div>
        {/* </Badge> */}
      </Link>

      <div
        className={`shopping-cart ${hovered ? "active" : ""}`}
        onMouseEnter={() => setCartHovered(true)}
        onMouseLeave={() => setCartHovered(false)}
      >
        <Box sx={{ display: "flex", m: 2 }}>
          <Badge badgeContent={cartTotalCount(shoppingCart)}>
            <i className="fa fa-shopping-cart cart-icon" />
          </Badge>

          <span
            style={{ marginLeft: "auto", marginRight: 3 }}
            className="lighter-text"
          >
            Total:
          </span>
          <Typography component="span" color="primary">
            {" "}
            {cartTotalCost.format()}
          </Typography>
        </Box>

        {shoppingCart.items.length === 0 && (
          <Box
            sx={{
              p: 5,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Typography variant="h5">No items in cart</Typography>
          </Box>
        )}

        <List>
          {shoppingCart.items.map((item, index) => {
            return (
              <ListItem sx={{ display: "flex" }} key={index}>
                <>
                  <MarginProduct
                    product={item.product}
                    variant={item.printfulVariant}
                    width={75}
                    outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                    fit={item.fit}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column"
                    }}
                  >
                    <Typography color="text.secondary" textAlign="center">
                      {item.product.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ p: 1 }}>
                      {item.printfulVariant.name}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                      }}
                    >
                      <Typography>${item.product.price}</Typography>
                      <Typography>Quantity</Typography>

                      <ButtonGroup
                        size="small"
                        sx={{
                          "& .MuiButton-root": {
                            p: 0,
                            minWidth: 0.8,
                            minHeight: 0.5,
                            height: 0.8
                          }
                        }}
                      >
                        <Button
                          onClick={() =>
                            shoppingCart.removeItem({
                              product: item.product,
                              printfulVariant: item.printfulVariant,
                              fit: item.fit,
                              removeCount: 1
                            })
                          }
                        >
                          -
                        </Button>
                        <Button>{item.count}</Button>
                        <Button
                          onClick={() =>
                            shoppingCart.addItem({
                              product: item.product,
                              printfulVariant: item.printfulVariant,
                              fit: item.fit,
                              count: 1
                            })
                          }
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </>
              </ListItem>
            );
          })}
        </List>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            p: 2,
            pt: 0,
            justifyContent: "center"
          }}
        >
          {shoppingCart.items.length === 0 ? (
            <Button sx={{ width: "100%" }} disabled variant="contained">
              Go to Checkout
            </Button>
          ) : (
            <Link
              to="/checkout"
              style={{ width: "100%" }}
              onClick={() => setCartHovered(false)}
            >
              <Button sx={{ width: "100%" }} variant="contained">
                Go to Checkout
              </Button>
            </Link>
          )}
        </Box>
      </div>
    </div>
  );
}
