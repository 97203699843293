import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue
} from "firebase/firestore";

import { AllCollections } from "shared/models/collections";
import { z } from "zod";

type CollectionName = keyof typeof AllCollections;

export function zodConverter<
  Collection extends CollectionName,
  SchemaType extends (typeof AllCollections)[Collection]["schema"],
  Document extends z.infer<SchemaType>
>(
  collection: Collection,
  options: { tolerant: boolean } = { tolerant: true }
): FirestoreDataConverter<Document> {
  const { schema, idField } = AllCollections[collection];

  const converterOptions = options;

  return {
    /**
     * When outgoing we:
     *  - strip out the id field (TODO: validate going to the right document if possible?)
     *  - dates -> timestamps
     *
     * When incoming we:
     *  - add in id
     *  - timestamps -> dates
     *  - parse schema, either failing or warning (depending on toleration)
     */
    toFirestore(document: WithFieldValue<Document>): DocumentData {
      const documentCopy = { ...document } as any;

      if (idField) {
        delete documentCopy[idField];
      }

      // mutates object to recursively convert all dates to timestamps
      convertDatesToTimestamps(documentCopy);

      return documentCopy;
    },

    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): Document {
      let data = { ...snapshot.data(options) };

      if (idField) {
        data = { ...data, [idField]: snapshot.id };
      }

      // mutates object to recursively convert all timestamps to dates, loses nanosecond precision
      convertTimestampsToDates(data);

      const parseResult = schema.safeParse(data);

      if (parseResult.success) {
        // typescript isn't recognizing this, even though we're sure it's the right type
        return parseResult.data as Document;
      } else if (converterOptions.tolerant) {
        console.groupCollapsed(
          `Failed to parse document id=${snapshot.id} from collection=${collection} (tolerating)`
        );
        console.warn(parseResult.error);
        console.groupEnd();
        return data as Document;
      } else {
        console.error(
          `Failed to parse document id=${snapshot.id} from collection=${collection}.`,
          parseResult.error
        );
        throw parseResult.error;
      }
    }
  };
}

function convertTimestampsToDates(object: Record<string, any>) {
  for (const key in object) {
    if (!object.hasOwnProperty(key)) continue;

    if (object[key] instanceof Timestamp) {
      object[key] = object[key].toDate();
    } else if (object[key] && typeof object[key] === "object") {
      convertTimestampsToDates(object[key]);
    }
  }
}

function convertDatesToTimestamps(object: Record<string, any>) {
  for (const key in object) {
    if (!object.hasOwnProperty(key)) continue;
    if (object[key] instanceof Date) {
      object[key] = Timestamp.fromDate(object[key]);
    } else if (object[key] && typeof object[key] === "object") {
      convertDatesToTimestamps(object[key]);
    }
  }
}
