import { z } from "zod";

export const BackupOperationDocument = z
  .object({
    pending: z.boolean(),
    longRunningOperationName: z.string(),
    done: z.boolean().optional(),
    backupType: z
      .literal("daily")
      .or(z.literal("weekly"))
      .or(z.literal("monthly"))
      .or(z.literal("yearly")),
    startedAt: z.date(),
    processedAt: z.date().optional(),
    deleteAt: z.date().optional()
  })
  .and(
    // failure details if failed
    z
      .object({ failed: z.literal(true), failureReason: z.string() })
      .or(z.object({ failed: z.literal(false).optional() }))
  );

export type BackupOperationDocument = z.infer<typeof BackupOperationDocument>;

export const NotificationEvent = z.object({
  target: z.literal("artist"),
  artistId: z.string(),
  notificationType: z
    .literal("order-placed")
    .or(z.literal("order-delivered")) //unused
    .or(z.literal("order-paid")),
  orderId: z.string(),
  artwork: z.array(
    z.object({
      name: z.string(),
      id: z.string(),
      price: z.string(),
      quantity: z.number()
    })
  ), //choosing to store price as a string rather than as a number because this is how it is stored in the product snapshots
  date: z.date(),
  paymentAmount: z.string().nullable(),
  sent: z.boolean(),
  notificationId: z.string()
});

export type NotificationEvent = z.infer<typeof NotificationEvent>;
