import { Box, Button, Grid, GridProps, Typography } from "@mui/material";
import Config from "../../config";

import Schmear from "../about/Schmear";

import { Link } from "react-router-dom";
import banner from "../../assets/MHA-banner-small.jpg";
import logo from "../../assets/MHA-logo-small.png";
import { useNonprofitArtwork } from "../../helpers/queries";
import FlexibleProductList from "../productViews/FlexibleProductList";

import MHABipoc from "../../assets/MHA-bipoc.jpg";
import MHAPeople from "../../assets/MHA-people.jpg";
import MHAWebinars from "../../assets/MHA-webinars.png";

export default function MentalHealthAmerica() {
  const mhaArtwork = useNonprofitArtwork("mental-health-america", {
    limit: 4
  });

  const productListColumns: GridProps = {
    xs: 6,
    sm: 4,
    md: 3,
    lg: 2
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%"
      }}
    >
      <Box
        component="img"
        src={banner}
        sx={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          border: 0.5,
          borderTop: 0,
          borderColor: "grey.400",
          aspectRatio: "1600/472",
          height: "auto"
        }}
      />
      <Box
        sx={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Grid container spacing={3} sx={{ maxWidth: "100%", mt: 2 }}>
          <Grid item xs={12} sm={9} md={8} sx={{ p: 3, pb: 0 }}>
            <Box sx={{ flexBasis: "500px" }}>
              <Typography variant="h4" sx={{ alignSelf: "flex-start" }}>
                Buy art to Support MHA
              </Typography>
              <Typography paragraph>
                The Giving Gallery sells artwork to elevate artists and raise
                money for our nonprofit partners. 30% of each artwork sale goes
                to the nonprofit of your choice, such as Mental Health America
                (MHA).
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Box
              component="img"
              src={logo}
              sx={{
                ml: "auto",
                width: "400px",
                maxWidth: "100%",
                height: "auto",
                aspectRatio: "781/241"
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: {
                xs: "none",
                md: "flex"
              },
              alignItems: "center"
            }}
          >
            <Box
              component="img"
              src={MHAPeople}
              sx={{ ml: "auto", maxWidth: "100%" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" sx={{ alignSelf: "flex-start" }}>
                About Mental Health America
              </Typography>
              <Typography paragraph>
                MHA is the nation's leading national nonprofit dedicated to the
                promotion of mental health, well-being, and illness prevention.
                Our work is informed, designed, and led by the lived experience
                of those most affected. health reports in the nation.
              </Typography>

              <Typography paragraph>
                Operating nationally and in communities across the country,
                Mental Health America advocates for closing the mental health
                equity gap while increasing nationwide awareness and
                understanding through public education, direct services, tools,
                and research. Our annual State of Mental Health in America
                report is among the most widely respected health reports in the
                nation.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "flex",
                md: "none"
              },
              justifyContent: "flex-end"
            }}
          >
            <Box
              component="img"
              src={MHAPeople}
              sx={{ ml: "auto", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>

        {mhaArtwork && (
          <>
            <Typography variant="h4" sx={{ mt: 6, ml: 3, mr: 3 }}>
              Latest Purchases That Supported MHA
            </Typography>

            <FlexibleProductList
              products={mhaArtwork}
              customCardAction={undefined}
              {...productListColumns}
              containerProps={{ justifyContent: "center", mb: 3 }}
            />

            <Link to="/artwork">
              <Button variant="contained">View more artwork</Button>
            </Link>
          </>
        )}
        <Box mt={5} />

        <Typography variant="h4" sx={{ mt: 3 }}>
          Resources
        </Typography>

        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: 3,
            mt: 3,
            justifyContent: "center"
          }}
        >
          <Link
            to="https://mhanational.org/bipoc/mental-health-month"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
              maxWidth: "100%"
            }}
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ height: "auto", width: "268px", maxWidth: "100%" }}
              src={MHABipoc}
            />

            <Typography variant="h5">BIPOC Mental Health Toolkit</Typography>
          </Link>

          <Link
            to="https://mhanational.org/mha-webinars"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,

              maxWidth: "100%"
            }}
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ height: "auto", width: "550px", maxWidth: "100%" }}
              src={MHAWebinars}
            />
            <Typography variant="h5">
              Check out MHA's educational webinars
            </Typography>
          </Link>
        </Box>

        <Box mt={3} />
        <Link to="https://www.mhanational.org/" target="_blank" rel="noopener">
          <Button variant="contained" size="large">
            Learn More: Visit MHA
          </Button>
        </Link>
      </Box>

      <Schmear
        text="Share on Social Media"
        action={{
          type: "social",
          url: "https://thegivinggallery.com/partners/mental-health-america"
        }}
      />

      <Box
        sx={{
          width: Config.ux.navBarWidth,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 3
        }}
      ></Box>
    </Box>
  );
}
