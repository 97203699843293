import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  calculateCartTotalCost,
  calculateCartTotalPrintingCost
} from "../../tools/frontend-currency-tools";
import {
  ShoppingCartItem,
  cartTotalCount,
  useCart
} from "../../zustand/cart-state";
import MarginProduct from "../productViews/ProductImage";

export default function Cart() {
  const shoppingCart = useCart();

  const location = useLocation();

  const { from } = location.state || { from: undefined };

  const cartTotalBaseCost = useMemo(
    () => calculateCartTotalCost(shoppingCart),
    [shoppingCart]
  );

  const cartTotalPrintingCost = useMemo(
    () => calculateCartTotalPrintingCost(shoppingCart),
    [shoppingCart]
  );

  const cartTotalCost = useMemo(
    () => cartTotalBaseCost.add(cartTotalPrintingCost),
    [shoppingCart]
  );

  const [recentRemovedItem, setRecentRemovedItem] = useState<
    ShoppingCartItem | undefined
  >(undefined);

  return (
    // TODO: Make this page look good on mobile

    <Box
      sx={{
        width: "800px", // "1200px",
        minHeight: "calc(100vh - 64px)",
        maxWidth: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
        justifyContent: "center",
        alignItems: "center",
        p: 3
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        {cartTotalCount(shoppingCart) === 0 && !recentRemovedItem && (
          <Box>
            <Typography variant="h4">Nothing in your cart yet</Typography>
            <Link
              sx={{ textDecoration: "none" }}
              component={RouterLink}
              to="/artwork"
            >
              <Button
                sx={{ mt: 2, width: "100%" }}
                variant="contained"
                endIcon={<NavigateNext />}
              >
                Check out the gallery
              </Button>
            </Link>
          </Box>
        )}

        <Grid container spacing={2} display={"flex"}>
          <>
            {shoppingCart.items.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Grid item xs={3}>
                    <Link
                      sx={{ textDecoration: "none" }}
                      component={RouterLink}
                      to={
                        item.product.slug
                          ? `/artwork/${item.product.slug}`
                          : `/artwork/${item.product.productId}`
                      }
                    >
                      <MarginProduct
                        product={item.product}
                        variant={item.printfulVariant}
                        outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                        width={75}
                        fit={item.fit}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={5} sx={{ display: "flex" }}>
                    <Box sx={{ marginLeft: "8px" }}>
                      <Typography fontWeight="bold">
                        {item.product.title}
                      </Typography>
                      <Typography fontWeight="bold">
                        {item.printfulVariant.name}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        id="checkout-shopping-cart-short-description"
                      >
                        {item.product.description}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography>
                      ${item.product.price} + ${item.printfulVariant.price}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <ButtonGroup
                      size="small"
                      sx={{
                        "& .MuiButton-root": {
                          p: 0,
                          minWidth: 0.8,
                          minHeight: 0.5,
                          height: 0.8
                        }
                      }}
                    >
                      <Button
                        onClick={() => {
                          if (item.count === 1) {
                            setRecentRemovedItem(item);
                          }
                          shoppingCart.removeItem({
                            printfulVariant: item.printfulVariant,
                            product: item.product,
                            fit: item.fit,
                            removeCount: 1
                          });
                        }}
                      >
                        -
                      </Button>
                      <Button>{item.count}</Button>
                      <Button
                        onClick={() =>
                          shoppingCart.addItem({
                            printfulVariant: item.printfulVariant,
                            product: item.product,
                            fit: item.fit,
                            count: 1
                          })
                        }
                      >
                        +
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Fragment>
              );
            })}
            {recentRemovedItem && (
              <>
                <Grid item xs={3}>
                  <Box sx={{ display: "flex" }}>
                    <MarginProduct
                      product={recentRemovedItem.product}
                      variant={recentRemovedItem.printfulVariant}
                      outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                      width={75}
                      fit={recentRemovedItem.fit}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5} sx={{ display: "flex" }}>
                  <Box sx={{ marginLeft: "8px" }}>
                    <Typography fontWeight="bold">
                      {recentRemovedItem.product.title} was removed from your
                      cart
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => {
                      shoppingCart.addItem({
                        printfulVariant: recentRemovedItem.printfulVariant,
                        product: recentRemovedItem.product,
                        fit: recentRemovedItem.fit,
                        count: 1
                      });
                      setRecentRemovedItem(undefined);
                    }}
                  >
                    Undo
                  </Button>
                </Grid>
              </>
            )}
          </>
          {cartTotalCount(shoppingCart) > 0 && (
            <>
              <Grid item xs={12}>
                <Link
                  sx={{ alignSelf: "flex-end", textDecoration: "none" }}
                  component={RouterLink}
                  to="/checkout"
                >
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    endIcon={<NavigateNext />}
                  >
                    {from === "checkout" ? "Return " : "Proceed "}
                    to Checkout ({cartTotalCost.format()})
                  </Button>
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 2 }} />
    </Box>
  );
}
