import {
  GoogleAuthProvider,
  User,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithPopup
} from "firebase/auth";
import { zodConverter } from "../databaseModels/firestoreHelpers";

export async function accountAlreadyExists(email: string): Promise<boolean> {
  const methods = await fetchSignInMethodsForEmail(getAuth(), email);
  const hasAnyMethods = methods.length > 0;
  return hasAnyMethods;
}

/**
 * Wrapper around signing in with google with an oauth popup
 *
 * TODO: better error handling
 *
 *
 */
export async function loginWithGoogle(
  login_hint?: string
): Promise<User | null> {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  if (login_hint) {
    // seehttps://firebase.google.com/docs/auth/web/google-signin
    provider.setCustomParameters({
      login_hint
    });
  }

  const user = await signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      return user;
    })
    .catch((error) => {
      //FIXME: Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      return null;
    });

  return user;
  // await signInWithRedirect(auth, provider);
  // return null;
}

export const converters = {
  invitesConverter: zodConverter("invites"),
  usersConverter: zodConverter("users"),
  printfulCategoriesConverter: zodConverter("printfulCategories"),
  printfulProductsConverter: zodConverter("printfulProducts"),
  configConverter: zodConverter("config"),
  nonprofitPrivateConverter: zodConverter("nonprofitPrivate")
};
