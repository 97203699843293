import { Box, Chip, Typography } from "@mui/material";

export default function TermsOfService() {
  const effectiveDate = `Jan 19, 2024`; //used for versioning, update when terms are updated
  const header = `Welcome to The Giving Gallery! These Terms of Service (“Terms”) govern your use of our platform and services. By using The Giving Gallery Marketplace (“The Giving Gallery”), you agree to abide by these Terms. Please read them carefully.`;
  const TOS = [
    {
      title: `1. Overview`,
      content: `The Giving Gallery is an art marketplace dedicated to raising awareness and proceeds for mental health charities through art sales. Here, artists can showcase and sell their artwork, while buyers can choose the nonprofit beneficiary to receive 30% of the artwork price. Artists receive 50% commission of the artwork price, and The Giving Gallery retains 20%.
      
      Printing, shipping, and tax costs are calculated after the split, as they are part of fulfillment costs.`
    },
    {
      title: `2. Compensation and Payouts`,
      content: `Payments are processed securely through Stripe. Payouts will be made to both artists and nonprofits after the return period is closed, which is seven days from when the artwork is received. Artists will be paid out on the eighth day. Nonprofits get paid on a quarterly basis from the time of sign up. Artists can choose their preferred payout method in their Stripe account settings. 
  
      The Giving Gallery will send out tax forms (such as 1099s) where required, though it is recommended for artists to consult their own tax experts on their obligations.

      The Giving Gallery offers artists the option to waive their commission in favor of directing additional funds either to the chosen nonprofit or back to The Giving Gallery. This gesture is entirely voluntary and not generally recommended, as we value the artists' contributions and financial interests. The Giving Gallery may utilize this feature to provide the artist their commission, in atypical financial scenarios (e.g., complex tax jurisdiction).

      In the event of a return or a chargeback, amounts paid to Artists and nonprofits will be adjusted accordingly, however we do not currently reverse funds sent to artists after the return window has closed. For calculating the earnings split, best effort will be used in rounding cents.`
    },
    {
      title: `3. Returns`,
      content: `Any returns or inquiries must be addressed to support@thegivinggallery.com. We only process returns due to print quality issues, and have a window of seven days from delivery of the item(s) to contact us for returns. Returns must be shipped to the return address on the printing label, and we may redirect inquiries to Printful support where needed.`
    },
    {
      title: `4. Responsibility and Liability`,
      content: `a. Artist Responsibility: Artists are solely responsible for the accuracy of their artwork listings and any related information. They are also responsible for fulfilling orders promptly and ensuring the quality of the artwork. The Giving Gallery is not liable for disputes arising from misrepresentation or issues related to artwork quality.
      
      b. Buyer Responsibility: Buyers are responsible for inspecting the artwork and communicating with Printful regarding any issues with product quality or shipping. The Giving Gallery is not liable for issues related to product quality or shipping.
      
      c. Password Management: You are responsible for the security of your account and password. Do not share your password with others, and please notify us immediately if you suspect any unauthorized access to your account.
      
      d. Indemnification: By using The Giving Gallery, you agree to indemnify and hold us harmless from any claims, damages, or liabilities arising out of your use of our platform.`
    },
    {
      title: `5. Intellectual Property Rights`,
      content: `a. Artist's Rights: Artists retain all rights to their original artwork. By listing your artwork on The Giving Gallery, it is assumed you are the rightful owner and grant us a non-exclusive, worldwide, royalty-free license to display and promote your artwork on our platform, as well as rights to sell prints of your artwork and split the proceeds as described in 1. Overview.
      
      b. Infringement: If you believe that any content on The Giving Gallery infringes your intellectual property rights, please contact us promptly at support@thegivinggallery.com with relevant details.
      
      c. Artist Requirements: Artists may only upload artwork or content that they are the sole owner of, and may not upload anything copyrighted by anyone else.`
    },
    {
      title: `6. Reporting Inappropriate Content`,
      content: `If you encounter any content on The Giving Gallery that you believe is inappropriate, violates our Community Guidelines, or goes against our mission of supporting mental health, please report it to us immediately through the reporting mechanisms provided on the platform or support@thegivinggallery.com`
    },
    {
      title: `7. Privacy and Data`,
      content: `a. Privacy: The Giving Gallery values your privacy. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal information.
      
      b. Credit Card Information: We do not store credit card information. Payments are securely processed through Stripe.
      
      c. Data Retention: If an Artist, Nonprofit, or Buyer wishes to terminate their account entirely, they must contact support@thegivinggallery.com.
      
      d. Mental Health Disclosure: The Giving Gallery is not a HIPAA-compliant platform. Any disclosure of mental health information is solely your responsibility. We encourage you to exercise caution and discretion when sharing personal information on our platform.`
    },
    {
      title: `8. Moderation and Content Management`,
      content: `We reserve the right to moderate and remove any content (such as Artwork) that violates our Community Guidelines or Terms of Service, at our sole discretion. Appeals may be made to support@thegivinggallery.com.`
    },
    {
      title: `9. Termination and Content Management`,
      content: `We reserve the right to terminate or suspend artist accounts for violating the Community Guidelines or Terms of Service. You may also choose to terminate your account at any time by contacting us at support@thegivinggallery.com. Upon termination, any funds owed to you will be paid out.`
    },
    {
      title: `10. Changes to Terms`,
      content: `We may update these Terms from time to time. You will be notified of any changes, and your continued use of The Giving Gallery after the update constitutes your acceptance of the revised Terms.`
    },
    {
      title: `11. Contact Us`,
      content: `If you have any questions or concerns about these Terms or any other matter, please contact us at support@thegivinggallery.com.`
    }
  ];
  const footer = `By using The Giving Gallery, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them. Thank you for joining us in supporting mental health organizations through art!`;

  return (
    <Box
      sx={{
        width: "900px",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        alignSelf: "center",
        gap: 3
      }}
    >
      <Typography variant="h4">Terms of Service</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          alignSelf: "center",
          gap: 1
        }}
      >
        <Box>
          <Chip label={`Effective Date: ${effectiveDate}`} />
        </Box>
        <Typography variant="body1">{header}</Typography>
      </Box>
      {TOS.map((item, index) => {
        const emailPattern = /\S+@\S+\.\S+/;
        const words = item.content.split(" ");

        const elements = [];
        let buffer = "";

        words.forEach((word, idx) => {
          if (emailPattern.test(word)) {
            if (buffer) {
              elements.push(buffer);
              buffer = "";
            }
            elements.push(
              " ",
              <a href={`mailto:${word}`} key={idx}>
                {word}
              </a>,
              " "
            );
          } else {
            buffer += (buffer ? " " : "") + word;
          }
        });

        if (buffer) {
          elements.push(buffer);
        }

        return (
          <Box key={index}>
            <Typography variant="h5" sx={{ whiteSpace: "pre-line", mb: 1 }}>
              {item.title}
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: "pre-line", ml: 2 }}>
              {elements}
            </Typography>
          </Box>
        );
      })}

      <Typography variant="body1">{footer}</Typography>
    </Box>
  );
}
