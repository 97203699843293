import { Box, Button, Typography } from "@mui/material";
import { doc, getFirestore } from "firebase/firestore";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AllCollections } from "shared/models/collections";
import AssetsResolver from "../AssetsResolver";
import { tggCheckoutSessionConverter } from "../databaseModels/DatabaseModels";
import { useDocumentDataCustom } from "../helpers/queries";
import { useCart } from "../zustand/cart-state";
import Schmear from "./about/Schmear";
import PageLoading from "./helpers/PageLoading";

export default function SuccessfulCheckoutPage() {
  const [search] = useSearchParams();
  const sid = search.get("sid");

  const cartLoading = useCart((state) => state.loading);
  const clearCart = useCart((state) => state.clearCart);

  const [checkoutSession, csLoading] = useDocumentDataCustom(
    sid
      ? doc(
          getFirestore(),
          AllCollections.checkoutSessions.name,
          sid
        ).withConverter(tggCheckoutSessionConverter)
      : null
  );

  const [nonprofit, nonprofitLoading] = useDocumentDataCustom(
    checkoutSession
      ? doc(
          getFirestore(),
          AllCollections.nonprofits.name,
          checkoutSession.selectedNonprofit.id
        )
      : null
  );

  console.log(checkoutSession);

  useEffect(() => {
    if (!cartLoading) {
      clearCart();
    }
  }, [cartLoading]);

  return !checkoutSession || !nonprofit ? (
    <PageLoading />
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "500px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <Typography mb={1} variant="h4">
        Order Placed
      </Typography>

      <Typography mb={1} variant="h6">
        Order ID: {checkoutSession.plannedOrderId}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center"
        }}
      >
        <Box maxWidth="300px">
          <Typography paragraph>
            Thank you for supporting {checkoutSession.selectedNonprofit.name},{" "}
            {checkoutSession.customer.name.first}!
          </Typography>
          <Typography paragraph>
            Your order has been sent out for printing,{" "}
            <em>and we will email you a tracking link when it ships.</em>
          </Typography>

          <Typography>
            After you receive the order, you have one week to contact us for a
            return if there is an issue with print quality.
          </Typography>
        </Box>
        <Box
          component="img"
          sx={{
            width: "400px",
            maxWidth: "100%"
          }}
          src={
            typeof nonprofit.image === "string"
              ? nonprofit.image
              : AssetsResolver.imageUrl(nonprofit.image.path, "nonprofit")
          }
        />
      </Box>

      <Link to={nonprofit.website} target="_blank" rel="noopener">
        <Button variant="contained">Visit {nonprofit.name}</Button>
      </Link>

      <Schmear text="Share The Giving Gallery" action={{ type: "social" }} />
    </Box>
  );
}
