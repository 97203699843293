import { Box, Typography } from "@mui/material";

export interface Bucket {
  img: string;
  title: string;
  content: string | React.ReactNode;
  action?: () => void;
}

interface BucketProps {
  buckets: Bucket[];
}

export default function Buckets(props: BucketProps) {
  //const navigate = useNavigate();
  return (
    <Box
      sx={{
        // outer container
        p: 3,
        mt: { xs: 6, sm: 8 },
        width: "1300px",
        maxWidth: "100%",
        justifyContent: "space-evenly",
        alignItems: {
          xs: "center",
          sm: "stretch"
        },
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: 8,

        "& img": {
          transition: "all .3s"
        },

        "& > .MuiBox-root:hover > img": {
          transform: "scale(1.1)"
        },

        // info section container
        "& .MuiBox-root": {
          width: "300px",

          display: "flex",
          flexDirection: "column",
          //justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          rowGap: 2,
          cursor: "pointer"
        },
        "& .MuiBox-root img": {
          width: "100%",
          maxWidth: "200px",
          borderRadius: "100em",
          boxShadow: "0em 0em 1.74em 0em rgb(0,0,0)"
        },
        "& .MuiBox-root p a, & .MuiBox-root p span": {
          textDecoration: "none",
          color: "rgb(51,122,183)",
          cursor: "pointer"
        },
        "& .MuiBox-root p a:hover, & .MuiBox-root p span:hover": {
          textDecoration: "none",
          color: "#6F222D",
          cursor: "pointer"
        }
      }}
    >
      {props.buckets.map((bucket) => (
        <Box
          key={bucket.title}
          onClick={() => {
            if (bucket.action) {
              bucket.action();
            }
          }}
        >
          <img alt="" src={bucket.img} />
          <Typography variant="h4" fontWeight="bold">
            {bucket.title}
          </Typography>
          {typeof bucket.content === "string" ? (
            <Typography>{bucket.content}</Typography>
          ) : (
            bucket.content
          )}
        </Box>
      ))}
    </Box>
  );
}
