import libCurrency from "currency.js";

export function sumCurrencyArray(amounts: libCurrency[]) {
  return amounts.reduce(
    (previousValue, currentValue) => previousValue.add(currentValue),
    libCurrency(0) // initial value
  );
}

export const currency = libCurrency;
export type currency = libCurrency;

/**
 * Allocation to percentages with the similar semantics to Dinero.js
 * @see https://v2.dinerojs.com/docs/api/mutations/allocate
 * @see https://github.com/scurker/currency.js/issues/410
 *
 * e.g., allocate(currency(50.05), [50,25,25])
 *
 * It tries to split it as fairly as possible, biasing towards the start of the
 * allocation list when it doesn't split perfectly.
 */
export function allocate<T extends Record<string, number>>(
  amount: libCurrency,
  percentages: T
): { [K in keyof T]: libCurrency } {
  const totalPercentage = Object.values(percentages).reduce((a, b) => a + b, 0);

  if (totalPercentage !== 100) {
    throw new Error("The sum of the percentages must equal 100");
  }
  const pendingAllocation: { [K in keyof T]?: libCurrency } = {};

  for (const [key, value] of Object.entries(percentages)) {
    const allocation = libCurrency(amount.multiply(value / 100));
    pendingAllocation[key as keyof T] = allocation;
  }

  const initialAllocation = pendingAllocation as {
    [K in keyof T]: libCurrency;
  };

  const residual = amount.subtract(
    sumCurrencyArray(Object.values(initialAllocation))
  );

  const residualCents = residual.cents();
  const residualIsNegative = residualCents < 0;

  /**
   * if we're negative, we distribute negative cents right -> left
   * if we're positive, we distribute positive cents left -> right
   *
   * This is because we're giving "priority" to the earlier
   * items in the distribution array, so for a
   * [50,30,20] artist/nonprofit/tgg split, the benefit of rounding goes
   * artist -> nonprofit -> tgg
   */

  const keys = Object.keys(pendingAllocation) as (keyof T)[];

  // Keep iterating over any cents left, and allocate them
  for (let i = 0; i < Math.abs(residualCents); i++) {
    let key: keyof T;

    // either go right -> left or left -> right with modular arithmetic
    if (residualIsNegative) {
      key = keys[keys.length - 1 - (i % keys.length)] as keyof T;
    } else {
      key = keys[i % keys.length] as keyof T;
    }

    initialAllocation[key] = residualIsNegative
      ? initialAllocation[key].subtract(libCurrency(1, { fromCents: true }))
      : initialAllocation[key].add(libCurrency(1, { fromCents: true }));
  }

  return initialAllocation;
}
