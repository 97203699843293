import ProductCard, { CustomCardAction } from "./ProductCard";

import { Add } from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  GridProps,
  Typography,
  useTheme
} from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ProductDocument } from "shared/models/product-models";
import "./FlexibleProductList.css";

interface FlexibleProductListProps extends GridProps {
  products: ProductDocument[];
  customCardAction?: CustomCardAction;
  uploadCard?:
    | {
        include: false;
      }
    | { include: true };
  options?: {
    loading: boolean;
    expected: number;
  };
  containerProps?: GridProps;
}

//HACK: poor separation of concerns with "ProductCard"s, and duplicates CSS making it fragile
export default function FlexibleProductList({
  products,
  customCardAction,
  uploadCard,
  options,
  containerProps = {},
  ...props
}: FlexibleProductListProps) {
  const productsOrPlaceholders: (ProductDocument | null)[] = useMemo(() => {
    if (options && options.loading) {
      const { expected } = options;

      return [...products, ...Array(expected - products.length).fill(null)];
    } else {
      return products;
    }
  }, [products, options]);

  return (
    <Box sx={{ width: "100%", pl: 2, pt: 2 }}>
      <Grid container spacing={2} sx={{ width: "100%" }} {...containerProps}>
        {uploadCard?.include && (
          <Grid item {...props}>
            <Card
              className={`product-card`}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                ...{
                  "&:hover": {
                    transform: "scale(1.05)", //1.35 alternatively
                    zIndex: 5,
                    boxShadow:
                      "0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06)"
                  }
                }
              }}
            >
              <Link
                to="/mystore/upload/art"
                // preventing scroll reset doesn't seem to work here
                preventScrollReset
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textDecoration: "none"
                }}
              >
                <CardMedia component={UploadCardImage} />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 1,
                    position: "relative"
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      maxWidth: "75%",
                      textAlign: "center",
                      fontSize: { xs: 16, lg: 22 }
                    }}
                  >
                    Upload Your Artwork
                  </Typography>
                </Box>
              </Link>
            </Card>
          </Grid>
        )}

        {productsOrPlaceholders.map((product, index) => (
          <Grid item {...props} key={product?.productId || index}>
            <ProductCard
              product={product}
              customCardAction={customCardAction}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function UploadCardImage() {
  const theme = useTheme();
  return (
    <Box
      className="custom-card-media"
      sx={{
        backgroundColor: theme.palette.grey[300],

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",

        aspectRatio: "1/1",
        gridArea: "1/1",
        maxHeight: "200px"
      }}
    >
      <Add
        sx={{
          color: theme.palette.grey[600],
          height: 100,
          width: 100
        }}
      />
    </Box>
  );
}
