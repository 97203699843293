/**
 * Very thin wrapper around a mailto link to support, to avoid typos.
 */
export default function SupportEmail() {
  return (
    <a href="mailto:support@thegivinggallery.com">
      support@thegivinggallery.com
    </a>
  );
}
