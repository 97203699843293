// FIXME: Need to remove all the ts ignore statements and this eslint-disable
/* eslint-disable @typescript-eslint/ban-ts-comment */
// above comments were in the original checkout, note sure if they still apply
import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import "./Checkout.scss";

import { Link as RouterLink } from "react-router-dom";
import { NonProfitDocument } from "shared/models/nonprofit-models";
import AssetsResolver from "../../AssetsResolver";
import Config from "../../config";
import { useNonprofits } from "../../helpers/queries";
import {
  calculateCartTotalCost,
  calculateCartTotalPrintingCost
} from "../../tools/frontend-currency-tools";
import { useCart } from "../../zustand/cart-state";
import DialogTitleWithClose from "../helpers/DialogTitleWithClose";

import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { usePaymentsState } from "../../zustand/payments-state";
import NotFoundPage from "../NotFoundPage";
import PageLoading from "../helpers/PageLoading";
import MarginProduct from "../productViews/ProductImage";
import Payments from "./Payments";

export default function Checkout() {
  const [stripeClient, setStripeClient] = useState<Stripe | null>();

  useEffect(() => {
    // TODO: better error handling, such as visible error for user
    loadStripe(Config.stripe.publishableKey)
      .then(setStripeClient)
      .catch((e) => console.error("Failed to load stripe client", e));
  }, []);

  const shoppingCart = useCart();

  const cartTotalCost = useMemo(
    () => calculateCartTotalCost(shoppingCart),
    [shoppingCart]
  );

  const cartTotalPrintingCost = useMemo(
    () => calculateCartTotalPrintingCost(shoppingCart),
    [shoppingCart]
  );

  const [nonProfits = [], loading, error] = useNonprofits();

  const [nonProfitDialogOpen, setNonProfitDialogOpen] = useState<
    false | NonProfitDocument
  >(false);

  const [selectedNonProfit, setSelectedNonProfit] =
    useState<null | NonProfitDocument>(null);

  useEffect(() => {
    if (nonProfits[0] && selectedNonProfit === null) {
      setSelectedNonProfit(nonProfits[0]);
    }
  }, [nonProfits]);
  //TODO: make default selection based on if they are coming from a nonprofit's page

  const amount = usePaymentsState((state) => state.amount);

  const cart = useCart();

  if (!cart.loading && cart.items.length === 0) {
    return <NotFoundPage />;
  }

  return (
    <Box
      sx={{
        mt: 2,
        width: "1200px",
        maxWidth: "100%",
        minHeight: "100%",
        display: "flex",
        ml: "auto",
        mr: "auto"
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          width: {
            xs: "100%"
          },
          p: 2,
          flex: 1
        }}
      >
        {/* mobile cart */}
        <Box
          sx={{
            p: 2,
            display: {
              xs: "flex",
              md: "none"
            },
            flexDirection: "column",
            rowGap: 2,
            width: "100%",
            position: "sticky"
          }}
        >
          {shoppingCart.loading && (
            <Skeleton sx={{ width: "294px", height: "400px" }} />
          )}
          {!shoppingCart.loading && (
            <>
              {shoppingCart.items.map((item, index) => {
                const product = item.product;
                const printfulVariant = item.printfulVariant;

                return (
                  <Box key={index} sx={{ display: "flex" }}>
                    <Box sx={{ p: 1 }}>
                      <MarginProduct
                        product={item.product}
                        variant={item.printfulVariant}
                        width={75}
                        outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                        fit={item.fit}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontWeight="bold">{product.title}</Typography>
                      <Typography variant="body2">
                        {item.printfulVariant.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Typography variant="body1">
                          ${product.price} + ${printfulVariant.price}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="body1">Qty:</Typography>
                          <Typography variant="body1">{item.count}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Link
                sx={{ width: "100%", textDecoration: "none" }}
                component={RouterLink}
                to="/cart"
                state={{ from: "checkout" }}
              >
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  endIcon={<NavigateNext />}
                >
                  Edit My Cart (
                  {cartTotalCost.add(cartTotalPrintingCost).format()})
                </Button>
              </Link>{" "}
            </>
          )}
        </Box>

        {cart.loading || !selectedNonProfit ? (
          <PageLoading hint="Loading" />
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%"
            }}
          >
            <FormLabel sx={{ mt: 2, mb: 2, fontWeight: "bold" }}>
              First Select Nonprofit to Support (30% of the profit)
            </FormLabel>
            <RadioGroup
              row={true}
              value={selectedNonProfit.id}
              onChange={(evt) => {
                const newNp = nonProfits.find(
                  (np) => np.id === evt.target.value
                );
                if (newNp) {
                  setSelectedNonProfit(newNp);
                } else {
                  console.error(
                    "Couldn't get nonprofit from radio button selection"
                  );
                }
              }}
              name="selected_nonprofit"
            >
              <Grid
                container
                spacing={0}
                rowGap={2}
                sx={{
                  maxWidth: "700px",
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                {nonProfits.map((np) => {
                  const selected = selectedNonProfit.id === np.id;

                  return (
                    <Grid item xs={6} sm={4} key={np.id}>
                      <FormControlLabel
                        value={np.id}
                        sx={{ width: "100%" }}
                        // Hide the radio button, but still use it for accessibility
                        control={
                          <Radio
                            sx={{
                              visibility: "hidden",
                              width: 0,
                              height: 0
                            }}
                          />
                        }
                        // Since the label is visual, this should improve accessibility
                        // TODO: test on screen reader
                        aria-label={np.name}
                        label={
                          <Box
                            sx={{
                              boxShadow: selected ? 2 : 0,
                              width: "100%",
                              position: "relative",
                              border: 1,
                              borderRadius: 1,
                              borderColor: selected
                                ? "primary.main"
                                : "grey.300",
                              outline: selected ? 2 : 0,
                              outlineColor: "primary.main",
                              transition: "all 30ms"
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                aspectRatio: "2/1.1",
                                objectFit: "contain",
                                cursor: "pointer",
                                mb: 2,
                                borderRadius: 1,
                                p: 0.5
                              }}
                              component="img"
                              src={
                                np
                                  ? typeof np.image === "string"
                                    ? np.image
                                    : AssetsResolver.imageUrl(
                                        np.image.path,
                                        "nonprofit"
                                      )
                                  : ""
                              }
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setNonProfitDialogOpen(np);
                                }}
                                size="small"
                              >
                                Learn more
                              </Button>
                            </Box>
                          </Box>
                        }
                      />
                    </Grid>
                  );
                })}
                {Config.ux.moreNonprofitsComingSoon && (
                  <Grid item xs={6} display="flex" alignItems="stretch">
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        alignItems: "stretch",
                        flexGrow: 1,
                        "& .MuiFormControlLabel-label": { flexGrow: 1 }
                      }}
                      disabled
                      value={0}
                      control={<Radio />}
                      label={
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            aspectRatio: "2/1",
                            display: "flex",
                            alignItems: "center",
                            border: 1,
                            borderRadius: 1,
                            borderColor: "grey.300",
                            flexGrow: 1
                          }}
                        >
                          <Typography
                            sx={{
                              flexGrow: 1,
                              textAlign: "center",
                              padding: 1,
                              typography: {
                                xs: "h5",
                                sm: "h4",
                                md: "h3",
                                lg: "h3"
                              }
                            }}
                          >
                            More coming soon!
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </RadioGroup>

            {!stripeClient && <PageLoading hint="Loading payment form" />}

            {stripeClient && (
              <Elements
                stripe={stripeClient}
                options={{
                  mode: "payment",
                  amount: amount,
                  currency: "usd",
                  paymentMethodCreation: "manual",

                  // Fully customizable with appearance API.
                  appearance: {
                    variables: {
                      colorPrimary: "rgb(112, 33, 44)"
                    },
                    rules: {
                      ".CheckboxInput": {
                        borderRadius: "3px"
                      }
                    }
                  }
                }}
              >
                <Payments selectedNonprofit={{ id: selectedNonProfit.id }} />
              </Elements>
            )}
          </Box>
        )}
      </Box>

      {/* pc cart */}
      <Box
        sx={{
          p: 2,
          display: {
            xs: "none",
            md: "flex"
          },
          flexDirection: "column",
          rowGap: 2,
          width: "400px",
          position: "sticky"
        }}
      >
        {shoppingCart.loading && (
          <Skeleton sx={{ width: "294px", height: "500px" }} />
        )}
        {!shoppingCart.loading && (
          <>
            {shoppingCart.items.map((item, index) => {
              const product = item.product;
              const printfulVariant = item.printfulVariant;

              return (
                <Box key={index} sx={{ display: "flex" }}>
                  <Box sx={{ p: 1 }}>
                    <MarginProduct
                      product={item.product}
                      variant={item.printfulVariant}
                      width={100}
                      outerAspectRatio={{ horizontal: 1, vertical: 1 }}
                      fit={item.fit}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography fontWeight="bold">{product.title}</Typography>
                    <Typography variant="body2">
                      {item.printfulVariant.name}
                    </Typography>
                    <Typography variant="body1">
                      ${product.price} + ${printfulVariant.price}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body1">Qty:</Typography>
                      <Typography variant="body1">{item.count}</Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            <Link
              sx={{ width: "100%", textDecoration: "none" }}
              component={RouterLink}
              to="/cart"
              state={{ from: "checkout" }}
            >
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                endIcon={<NavigateNext />}
              >
                Edit My Cart (
                {cartTotalCost.add(cartTotalPrintingCost).format()})
              </Button>
            </Link>{" "}
          </>
        )}
      </Box>

      {/* NonProfit info dialog */}
      <Dialog
        open={Boolean(nonProfitDialogOpen)}
        onClose={() => setNonProfitDialogOpen(false)}
      >
        <DialogTitleWithClose onClose={() => setNonProfitDialogOpen(false)}>
          {nonProfitDialogOpen ? nonProfitDialogOpen.name : "Unknown"}
        </DialogTitleWithClose>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <img
            alt={nonProfitDialogOpen ? nonProfitDialogOpen.name : "Unknown"}
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              height: "auto",
              alignSelf: "center"
            }}
            src={
              nonProfitDialogOpen
                ? typeof nonProfitDialogOpen.image === "string"
                  ? nonProfitDialogOpen.image
                  : AssetsResolver.imageUrl(
                      nonProfitDialogOpen.image.path,
                      "nonprofit"
                    )
                : undefined
            }
          />
          <Typography mt={3} whiteSpace="pre-wrap">
            {nonProfitDialogOpen
              ? nonProfitDialogOpen.shortDescription ||
                nonProfitDialogOpen.description
              : "Unknown"}
          </Typography>
          {nonProfitDialogOpen && nonProfitDialogOpen.earmarked && (
            <>
              <Divider sx={{ m: 2 }} />
              <Typography whiteSpace="pre-wrap">
                <em>{nonProfitDialogOpen.earmarked}</em>
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNonProfitDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
