import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Config from "./config";
import { Link } from "react-router-dom";
import PageLoading from "./components/helpers/PageLoading";

const JotformUrl = "https://form.jotform.com/231576627032152";

export default function Apply() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const declaredHeight = useRef<undefined | number>(undefined);
  const expectedWidth = useRef<number>(window.innerWidth);

  const receivedMessage = useRef(false);
  const [loading, setLoading] = useState(true);
  const [notLoadedFallback, setNotLoadedFallback] = useState(false);

  useEffect(() => {
    const waitForSeconds = 3;

    const timeoutId = setTimeout(() => {
      if (!receivedMessage.current) {
        console.error(
          `Could not load jotform embed in ${waitForSeconds} seconds, falling back to link`
        );
        setNotLoadedFallback(true);
        setLoading(false);
      }
    }, waitForSeconds * 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    function resizeIframe() {
      if (declaredHeight.current && iframeRef.current) {
        const clientHasChanged = expectedWidth.current !== window.innerWidth;

        let offset = 0;

        if (clientHasChanged && window.innerWidth < 752) {
          offset += 400;

          if (window.innerWidth < 550) {
            // add another buffer to account for large shift due to squishing
            offset += 550;
          }
        }

        const iframe = iframeRef.current;
        const height = `${declaredHeight.current + offset}px`;

        iframe.style.height = height;
        iframe.style.minHeight = height;
      }
    }

    const handleMessage = (e: MessageEvent) => {
      // register that we have received a message, which implies that
      // the jotform embed is working
      receivedMessage.current = true;
      setLoading(false);

      if (typeof e.data === "object") {
        return;
      }
      let iframe = document.getElementById("JotFormIFrame");
      const args = e.data.split(":");
      if (args.length > 2) {
        iframe = document.getElementById(
          `JotFormIFrame-${args[args.length - 1]}`
        );
      }

      if (!iframe) {
        return;
      }

      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight": {
          const newHeight = parseInt(args[1]);

          // we scroll to start if there's a large height change
          const needsScroll =
            declaredHeight.current &&
            newHeight < declaredHeight.current &&
            declaredHeight.current - newHeight > 600;

          declaredHeight.current = newHeight;
          // we care what width we were at when the height was calculated
          expectedWidth.current = window.innerWidth;

          resizeIframe();

          if (needsScroll) {
            // we just finished the form
            iframe.scrollIntoView({ behavior: "smooth" });
          }
          //   iframe.style.height = `${args[1]}px`;
          //   if (
          //     !isNaN(parseInt(args[1])) &&
          //     parseInt(iframe.style.minHeight) > parseInt(args[1])
          //   ) {
          //     iframe.style.minHeight = `${args[1]}px`;
          //   }
          break;
        }
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = `${window.innerHeight}px`;
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript": {
          const script = document.createElement("script");
          script.src = args[1];
          script.type = "text/javascript";
          document.body.appendChild(script);
          break;
        }
        case "exitFullscreen": {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
          // else if (document.mozCancelFullScreen) {
          //   document.mozCancelFullScreen();
          // } else if (document.webkitExitFullscreen) {
          //   document.webkitExitFullscreen();
          // } else if (document.msExitFullscreen) {
          //   document.msExitFullscreen();
          // }

          break;
        }
      }
    };

    window.addEventListener("message", handleMessage);
    window.addEventListener("resize", resizeIframe);

    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("resize", resizeIframe);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {loading && <PageLoading />}
      {notLoadedFallback ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "500px"
          }}
        >
          <Box
            sx={{
              m: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              width: "400px",
              maxWidth: "100%"
            }}
          >
            <Typography variant="h4">Artist Application</Typography>

            <Typography>
              Thanks for being interested in applying! This will temporarily
              take you off this site to our application form.
            </Typography>

            <Link
              to="https://form.jotform.com/231576627032152"
              target="_blank"
              rel="noopener"
            >
              <Button variant="contained">Click here to proceed</Button>
            </Link>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: "100%",
            width: Config.ux.navBarWidth,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <iframe
            ref={iframeRef}
            id="JotFormIFrame-231576627032152"
            title="The Giving Gallery Artist Application"
            // allowTransparency
            //allowFullScreen
            allow="geolocation; microphone; camera"
            src={JotformUrl}
            frameBorder={0}
            style={{
              width: "752px",
              maxWidth: "752px",
              maxHeight: "2700px",
              border: "none"
            }}
            scrolling="no"
          />
        </Box>
      )}
    </Box>
  );
}
